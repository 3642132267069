import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserModel } from '../../interfaces/auth/user-model';
import * as jwt_decode from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  currentUser: UserModel;
  constructor(private http: HttpClient) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
  }
  getUserScopes(): Array<string> {
    var scope = JSON.parse(localStorage.getItem("scope")) as string;
    return scope?.split(" ");
  }
  // getUserScopes(): Array<string> {
  //   var user = JSON.parse(localStorage.getItem("user")) as UserModel;
  //   var token = user.token;
  //   var decoded = jwt_decode(token);
  //   return decoded['scope'].split(" ");
  // }
  // Child Permissions
  canAddChild() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-child:ch').length > 0;
  }

  canEditChild() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'edit-child:ch').length > 0;
  }

  canDeleteChild() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'delete-child:ch').length > 0;
  }
  canViewChild() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-child:ch').length > 0;

  }
  canViewChildDetails() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'details-child:ch').length > 0;
  }

  //// Parent Permissions
  canAddParent() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-parent:par' || "add-parent-branch:parb").length > 0;
  }
  canEditParent() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'edit-parent:par').length > 0;
  }

  canDeleteParent() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'delete-parent:par').length > 0;
  }
  canViewParent() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-parent:par').length > 0;
  }
  canViewParentDetails() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'details-parent:par').length > 0;
  } 

  //// Employee Permissions
  canAddEmployee() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-employee:emp').length > 0;
  }

  canEditEmployee() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'edit-employee:emp').length > 0;
  }
  canDeleteEmployee() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'delete-employee:emp').length > 0;
  }
  canViewEmployee() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-employee:emp').length > 0;
  }
  canViewEmployeeDetails() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'details-employee:emp').length > 0;
  }

  // ThirdPartyNotice
  canAddThirdPartyNotice() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-third-party-notice:tpn').length > 0 || scopes.filter(item => item === 'add-third-party-notice-branch:tpnb').length > 0;
  }

  canEditThirdPartyNotice() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'edit-third-party-notice:tpn').length > 0 || scopes.filter(item => item === 'edit-third-party-notice-branch:tpn').length > 0;
  }

  canDeleteThirdPartyNotice() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'delete-third-party-notice:tpn').length > 0 || scopes.filter(item => item === 'delete-third-party-notice-branch:tpnb').length > 0;
  }

  canViewThirdPartyNotice() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-third-party-notice:tpn').length > 0 || scopes.filter(item => item === 'view-third-party-notice-branch:tpnb').length > 0;
  }

  canViewThirdPartyNoticeDetails() {
    let scopes = this.getUserScopes();    
    return scopes.filter(item => item === 'details-third-party-notice:tpn').length > 0;
  }
  //Contract Permissions
  //// Contract
  canAddContract() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-contract:con').length > 0;
  }
  canEditContract() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'edit-contract:con').length > 0;
  }

  canDeleteContract() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'delete-contract:con').length > 0;
  }
  //Contract Permissions
  canViewContract() {
    let scopes = this.getUserScopes();    
    return scopes.filter(item => item === 'view-contract:con').length > 0;
  }
  canViewContractDetails() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'details-contract:con').length > 0;
  }


  //// Diagnosis
  canAddDiagnosis() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-diagnosis:dia').length > 0;
  }

  canEditDiagnosis() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'edit-diagnosis:dia').length > 0;
  }

  canDeleteDiagnosis() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'delete-diagnosis:dia').length > 0;
  }

  canViewDiagnosis() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-diagnosis:dia').length > 0;
  }


  // ServiceBooking Permissions
  canAddServiceBooking() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-service-booking:sb').length > 0 || scopes.filter(item => item === 'add-service-booking-branch:sbb').length > 0;
  }

  canEditServiceBooking() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'edit-service-booking:sb').length > 0 || scopes.filter(item => item === 'delete-service-booking-branch:sbb').length > 0;
  }
  canDeleteServiceBooking() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'delete-service-booking:sb').length > 0 || scopes.filter(item => item === 'delete-service-booking-branch:sbb').length > 0;
  }
  canViewServiceBooking() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-service-booking:sb').length > 0 || scopes.filter(item => item === 'view-service-booking-branch:sbb').length > 0;
  }
  canViewServiceBookingAll() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-service-booking:sb').length > 0;
  }
  canViewServiceBookingPerBranch() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-service-booking-branch:sbb').length > 0;
  }
  canApproveDiscount() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'approve-discount-service-booking:sb').length > 0;
  }

  //FollowUp Permissions
  canViewServiceFollowUp() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-service-follow-up:fu').length > 0;
  }

  canViewServiceFollowUpDetails() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'details-service-followup:fu').length > 0;
  }

  canEditServiceFollowUp() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'edit-service-followup:fu').length > 0;
  }

  canCancelServiceBooking() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'cancel-service-followup-status:fu').length > 0;
  }

  canReplaceServiceBooking() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'replaced-service-followup-status:fu').length > 0;
  }

  canTransferServiceBooking() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'transfered-service-followup-status:fu').length > 0;
  }

  canActivateServiceBooking() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'inprogress-service-followup-status:fu').length > 0;
  }

  canHoldServiceBooking() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'onhold-service-followup-status:fu').length > 0;
  }

  canFinishServiceBooking() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'finish-service-followup-status:fu').length > 0;
  }

  canViewServiceBookingActionLog() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'service-log-service-followup-all:fua').length > 0 || scopes.filter(item => item === 'service-log-service-followup-branch:fub').length > 0;
  }

  canCancelPlan() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'cancel-plan-followup:fu').length > 0;
  }

  canChangeFollowUpSessionStatus() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'session-status-service-followup-all:fua').length > 0 || scopes.filter(item => item === 'session-status-service-followup-branch:fub').length > 0;
  }

  //Sessions Permissions
  canViewSessionsList() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-session-responsible:ssr').length > 0
      || scopes.filter(item => item === 'view-session-own:sso').length > 0
      || scopes.filter(item => item === 'view-session-all:ssa').length > 0
      || scopes.filter(item => item === 'view-session-child-responsible:ssc').length > 0
      || scopes.filter(item => item === 'view-session-branch:ssb').length > 0
      ;
  }

  canViewSessionsListAll() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-session-all:ssa').length > 0
      ;
  }

  hasViewSessionBranch()
  {
    let scopes = this.getUserScopes();
   return scopes.filter(item => item === 'view-session-branch:ssb').length > 0
  }

  hasViewAttendencBranch()
  {
    let scopes = this.getUserScopes();
   return scopes.filter(item => item === 'view-attendance-view:attb').length > 0
  }
  canViewSessionDetails() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'details-session:ss').length > 0;
  }

  canEditSession() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'edit-session-all:ssa').length > 0 ||
      scopes.filter(item => item === 'edit-session-branch:ssb').length > 0 ||
      scopes.filter(item => item === 'edit-session-own:sso').length > 0 ||
      scopes.filter(item => item === 'edit-session-child-responsible:ssc').length > 0 ||
      scopes.filter(item => item === 'edit-session-responsible:ssr').length > 0;
  }

  canDeleteSession() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'delete-session-all:ssa').length > 0 ||
      scopes.filter(item => item === 'delete-session-branch:ssb').length > 0 ||
      scopes.filter(item => item === 'delete-session-child-responsible:ssc').length > 0;
  }

  canExecuteSession() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'execute-session:ss').length > 0;
  }

  //Assignment Permissions
  canAddAssignment() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-assignment:ass').length > 0;
  }  

  //Group Permissions
  canViewGroupsList() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-group:gg').length > 0;
  }

  canViewGroupDetails() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-group-details:gg').length > 0;
  }

  canAddGroup() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-group:gg').length > 0;
  }

  canEditGroup() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'edit-group:gg').length > 0;
  }

  canDeleteGroup() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'delete-group:gg').length > 0;
  }

  //Companies Permissions
  canViewCompaniesList() {
    let scopes = this.getUserScopes();
    return true //scopes.filter(item => item === 'view-companies:gg').length > 0 ;
  }

  canViewCompaniesDetails() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-companies-details:gg').length > 0;
  }

  canAddCompanies() {
    let scopes = this.getUserScopes();
    return true //scopes.filter(item => item === 'add-companies:gg').length > 0 ;
  }

  canEditCompanies() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'edit-companies:gg').length > 0;
  }

  canDeleteCompanies() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'delete-companies:gg').length > 0;
  }
  //start suggestion guards
  canEditSuggestion() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'service-suggestion:ssg').length > 0;
  }
  //start vacation guards
  saveAndAproveVacation() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'approvevacation:vvca').length > 0;
  }
  saveVacation() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'savevacation:vvcs').length > 0;
  }
  CanAddVacation() {
    let scopes = this.getUserScopes();
    let s = scopes.filter(item => item === 'approvevacation:vvcs');
    return s.length > 0;
  }
  //start vacation TYpe guards
  saveVacationType() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-vacation-type:vt').length > 0;
  }
  //start schedule guards
  addSchdule() {
    let scopes = this.getUserScopes();
    let s = scopes.filter(item => item === 'schedule-add:sce');
    return s.length > 0;
  }
  SchduleList() {
    let scopes = this.getUserScopes();
    let s = scopes.filter(item => item === 'schedule-view:sce');
    return s.length > 0;
  }
  saveVehicle() {
    let scopes = this.getUserScopes();
    let s = scopes.filter(item => item === 'vehicle:vehs');
    return s.length > 0;
  }
  vehicleList() {
    let scopes = this.getUserScopes();
    let s = scopes.filter(item => item === 'vehicle:veh');
    return s.length > 0;
  }

  //
  CanAddChildtransition() {
    let scopes = this.getUserScopes();
    let s = scopes.filter(item => item === 'add-child-transition');
    return s.length > 0;
  }

  //
  ChildtransitionList() {
    let scopes = this.getUserScopes();
    let s = scopes.filter(item => item === 'child-transition-list');
    return s.length > 0;
  }
  ChildtransitionView() {
    let scopes = this.getUserScopes();
    let s = scopes.filter(item => item === 'view-child-transition');
    return s.length > 0;
  }

  //start branch vacation guards
  BranchVacation() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'branch-vacation:bv').length > 0;
  }

  //start branch Distribution
  DistributionList() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'distribution:dis').length > 0;
  }


  // Edit Session Area
  EditSessionArea() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-session-type-area:ss').length > 0;
  }

  //Add Session Dependecy
  AddSessionDependecy() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-session-dependency:ss').length > 0;
  }
  //Edit Session Dependecy
  EditSessionDependecy() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'edit-session-dependency:ss').length > 0;
  }

  //View roles
  ViewRoles() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-role:vr').length > 0;
  }

  //View Characteristic
  ViewCharacteristic() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-Characteristic:vc').length > 0;
  }

  //add Characteristic
  AddCharacteristic() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-Characteristic:vc').length > 0;
  }


  //Shift Permissions
  canViewShiftsList() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-shift-all:sha').length > 0
      ;
  }

  canAddShift() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-shift-all:sha').length > 0;
  }

  canViewShiftDetails() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'details-shift-all:sha').length > 0;
  }

  canEditShift() {
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'edit-shift-all:sha').length > 0;
  }

  ServicePeriodList(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'view-service-period:spa').length > 0;
  }

  ViewServicePeriodDetails(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'details-service-period:spa').length > 0;
  }

  AddServicePeriod(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-service-period:sp').length > 0;
  }

  AddTicketCallCenter(){ //add call center btn
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-ticket:add-ticket').length > 0;
  }

  AddTicketEmployee(){ //add employee btn
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-ticket-employee:add-ticket-employee').length > 0;
  }

  //Add ticket requester type
  AddTicketParentRequester(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'query-parent:add-ticket'
    || item === 'request-parent:add-ticket' || item === 'issue-parent:add-ticket'
    || item === 'suggesetion-parent:add-ticket' || item === 'complaint-parent:add-ticket').length > 0;
  }

  AddTicketEmployeeRequester(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'query-employee:add-ticket' 
    || item === 'request-employee:add-ticket' || item === 'issue-employee:add-ticket'
    || item === 'suggesetion-parent:add-ticket' || item === 'complaint-employee:add-ticket').length > 0;
  }

  AddTicketExternalRequester(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'query-external-client:add-ticket'
    || item === 'request-external-client:add-ticket' || item === 'issue-external-client:add-ticket' 
    || item === 'suggesetion-external-client:add-ticket' || item === 'complaint-parent:add-ticket').length > 0;
  }

  // Add ticket query type
  AddTicketParentQuery(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'query-parent:add-ticket').length > 0;
  }

  AddTicketEmployeeQuery(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'query-employee:add-ticket').length > 0;
  }

  AddTicketExternalQuery(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'query-external-client:add-ticket').length > 0;
  }

  // Add ticket request type
  AddTicketParentRequest(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'request-parent:add-ticket').length > 0;
  }

  AddTicketEmployeeRequest(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'request-employee:add-ticket').length > 0;
  }

  AddTicketExternalRequest(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'request-external-client:add-ticket').length > 0;
  }

  // Add ticket issue type
  AddTicketParentIssue(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'issue-parent:add-ticket').length > 0;
  }

  AddTicketEmployeeIssue(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'issue-employee:add-ticket').length > 0;
  }

  AddTicketExternalIssue(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'issue-external-client:add-ticket').length > 0;
  }

  // Add ticket suggesetion type
  AddTicketParentSuggesetion(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'suggesetion-parent:add-ticket').length > 0;
  }

  AddTicketEmployeeSuggesetion(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'suggesetion-parent:add-ticket').length > 0;
  }

  AddTicketExternalSuggesetion(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'suggesetion-external-client:add-ticket').length > 0;
  }

  // Add ticket Complain type
  AddTicketParentComplain(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'complaint-parent:add-ticket').length > 0;
  }

  AddTicketEmployeeComplain(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'complaint-employee:add-ticket').length > 0;
  }

  AddTicketExternalComplain(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'complaint-parent:add-ticket').length > 0;
  }

  //seesion type
  AddSessionType(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-session-type:st').length > 0;
  }

  // service type
  AddServiceType(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-service-type:sv').length > 0;
  }

  // Agreement 
  AddAgreement(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-agreement:a').length > 0;
  }

  // Plan structure
  AddPlanStructure(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-plan-structure:ps').length > 0;
  }

  // Plan structure
  AddPlan(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-plan:p').length > 0;
  }

  // Shifts
  AddShift(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-shift:sh').length > 0;
  }

  // Vacation type
  AddVacationType(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-vacation-type:vt').length > 0;
  }

  // third party branches
  AddThirdPartyBranch(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-third-party-branch:tpb').length > 0;
  }

  // Process
  AddProcess(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-process:ps').length > 0;
  }

  // Third party
  AddThirdParty(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-third-party:tp').length > 0;
  }

  // Vehicle
  AddVehicle(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-vehicle:veh').length > 0;
  }

   // Branch vacation
   AddBranchVacation(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-branch-vacation:bv').length > 0;
  }

  // Child vacation
  AddChildVacation(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-child-vacation:cv').length > 0;
  }

  // Distribution
  AddDistribution(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-distribution:dis').length > 0;
  }

  // Area
  AddArea(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-area:ar').length > 0;
  }

  //Attendance
  AddAttendance(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'add-attendance:att').length > 0;
  }

  ViewAttendance(){
    let scopes = this.getUserScopes();
    return scopes.filter(item => item === 'attendance-view:att').length > 0;
  }
}
