import { Component, OnInit } from '@angular/core';
import { NavBarService } from 'src/app/core/services/nav-bar.service';
import { DiagnosisService } from 'src/app/core/services/api_call/diagnosis.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-diagnosis-details',
  templateUrl: './diagnosis-details.component.html',
  styleUrls: ['./diagnosis-details.component.scss']
})
export class DiagnosisDetailsComponent implements OnInit {
  panelOpenState = false;
  diagnosis:any;
  isLoading = false;

  answers:any[]=[];
  constructor(public nav: NavBarService,private service:DiagnosisService, private activeroute: ActivatedRoute,public translate:TranslateService) { }

  ngOnInit(): void {
    
    this.loadDiagnosis();
  }

  loadDiagnosis() {
    const groupBy = (array, key) => {
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
    };

    if (this.activeroute.snapshot.paramMap.get('id') != null) {
      var id=this.activeroute.snapshot.paramMap.get('id');
      this.isLoading=true;
      this.service.GetDiagnosisDetailsView(id).subscribe(
        res=>{
          this.diagnosis=res.data;
          
          var answers=this.diagnosis?.questions;
          var groupByKey=this.translate.currentLang=='en-US'?'formGroupEn':'formGroupAr';
          this.answers=groupBy(answers,groupByKey);
          this.isLoading=false;
        },error=>{
          
          this.isLoading=false;
        }
      )
    }
  }
}
