import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DiagnosisRoutingModule } from './diagnosis-routing.module';
import { AddDiagnosisComponent } from './components/add-diagnosis/add-diagnosis.component';
import { DiagnosisViewComponent } from './components/diagnosis-view/diagnosis-view.component';
import { DiagnosisDetailsComponent } from './components/diagnosis-details/diagnosis-details.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DynamicFormBuilderModule } from 'src/app/core/_helper/dynamic-form-builder/dynamic-form-builder.module';


@NgModule({
  declarations: [AddDiagnosisComponent, DiagnosisViewComponent, DiagnosisDetailsComponent],
  imports: [
    CommonModule,
    DiagnosisRoutingModule,
    TranslateModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    DynamicFormBuilderModule
  ],
  providers:[TranslateService]
})
export class DiagnosisModule { }
