import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, filter, map, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { AlertifyService } from "../services/alertify.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private translate: TranslateService,
    private alertify: AlertifyService
  ) {
  }

  blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob) {
        observer.next("");
        observer.complete();
      } else {
        let reader = new FileReader();
        reader.onload = event => {
          observer.next((event.target as any).result);
          observer.complete();
        };
        if (blob instanceof Blob) {
          reader.readAsText(blob);
        }
      }
    });
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map(resp => {
        if (resp instanceof HttpResponse) {
          let statusCode = resp.body.error?.response?.statusCode
          let outerStatusCode = resp.body?.statusCode
          if (statusCode === 401 || statusCode === 403 || outerStatusCode === 401 || outerStatusCode === 403) {
            this.router.navigateByUrl('/notAuth/not-auth');
          }
          this.blobToText(resp.body).subscribe(res => {
            if (res) {
              let resData = JSON.parse(res)
              let statusCode = resData.error?.response?.statusCode
              let outerStatusCode = resData?.statusCode
              if (statusCode === 401 || statusCode === 403 || outerStatusCode === 401 || outerStatusCode === 403) {
                this.router.navigateByUrl('/notAuth/not-auth');
              }
            }
          })
          return resp
        }
      }),
      catchError((err) => {
        if (err.status === 401 || err.status === 403) {
          this.router.navigateByUrl('/notAuth/not-auth');
        }

        if (err.status === 0 || err.status === 400) {
          this.alertify.error(this.translate.instant("GENERIC.FAILED_OPERATION"))
        }
        const error = err.error?.message || err?.statusText;
        return throwError(error);
      })
    );
  }
}
