export enum LookupEnum {
    COUNTRIES="COUNTRIES",
    PROCESSTYPE="PROCESSTYPE",
    CITIES="CITIES",
    DISTRICTS="DISTRICTS",
    NATIONALITIES="NATIONALITIES",
    EDUCATIONALLEVELS="EDUCATIONALLEVELS",
    mutualStatus="mutualStatus",
    GENDERS="GENDERS",
    MUTUALSTATUS="MUTUALSTATUS",
    mariageStatus="mariageStatus",
    MARRIAGESTATUS="MARRIAGESTATUS",
    IDTYPE="IDTYPE",
    LANGUAGES="LANGUAGES",
    LANGUAGESLEVEL="LANGUAGESLEVEL",
    COMPUTERSKILLS="COMPUTERSKILLS",
    COMPUTERSKILLSLEVEL="COMPUTERSKILLSLEVEL",
    CONTRACTSTATUS="CONTRACTSTATUS",
    EMPLOYEESTATUS="EMPLOYEESTATUS",
    CONTRACTTYPE="CONTRACTTYPE",
    FIELDOFSTUDY="FIELDOFSTUDY",
    GRADES="GRADES",
    STATUSCONTRACT="STATUSCONTRACT",
    SPECIALIZATIONS="SPECIALIZATIONS",
    TRAININGFIELDS="TRAININGFIELDS",
    TRAININGGRADE="TRAININGGRADE",
    CHILDCATEGORY="CHILDCATEGORY",
    KINDSHIP="KINDSHIP",
    DURATIONOFSTUDY="DURATIONOFSTUDY",
     //DUMMY LOOKUPS WILL BE REMOVED
    JOBTITLES="JOBTITLES",
    PROCESSES="PROCESSES",
    PERFECTION="PERFECTION",
    PROFESSIONS="PROFESSIONS",
    BRANCHES="BRANCHES",
    HIERARCHY="HIERARCHY",
    SHIFTS="SHIFTS",
    ATTENDANCETYPE="ATTENDANCETYPE",
    STATUS ="STATUS",
    DEPARTMENTS="DEPARTMENTS",
    THIRDPARTY="THIRDPARTY",
    PARENTCATEGORY="PARENTCATEGORY",
    SERVICETYPE="SERVICETYPE",
    THIRDPARTYNOTICESTATUS="THIRDPARTYNOTICESTATUS",
    PERIODUNITS="PERIODUNITS",
    PROFESSIONSTANDARDTYPES="PROFESSIONSTANDARDTYPES",
    DEGREESPECIALIZATION="DEGREESPECIALIZATION",
    TRAININGSPECIALIZATION="TRAININGSPECIALIZATION",
    EXPERIENCESPECIALIZATION="EXPERIENCESPECIALIZATION",
    STANDARDGRADE="STANDARDGRADE",
  PARENTJOBS = "PARENTJOBS",
  PAYMENTSTATUS = "PAYMENTSTATUS",
  THIRDPARTYNOTICESERVICETYPESTATUS="THIRDPARTYNOTICESERVICETYPESTATUS",
  SERVICEBOOKINGSTATUS="SERVICEBOOKINGSTATUS",
  DISCOUNTAPPROVEDSTATUS = "DISCOUNTAPPROVEDSTATUS",
  PLACE = "PLACE",
  AREATYPE = "AREATYPE",
  AREACATEGORY = "AREACATEGORY",
  StatusOfServiceType = "StatusOfServiceType",
  ServiceSuggestionType ='ServiceSuggestionType',
  ServiceSuggestionStatus = "ServiceSuggestionStatus",
    VacationStatus = "VacationStatus"
}
