/* tslint:disable:max-line-length */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserModel } from './interfaces/auth/user-model';
import { PermissionsService } from './services/api_call/permissions.service';

@Injectable({
    providedIn: 'root'
})
export class RolesGuard  {
    constructor(private router: Router, private permissionService: PermissionsService) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const user = JSON.parse(localStorage.getItem('user')) as UserModel;
        const url = state.url;
        if (user) {
            if ((url.startsWith('/authorization/auth-view'))
                || (url.startsWith('/authorization/auth-edit'))
                || (url.startsWith('/authorization/auth-add'))
                || (url.startsWith('/authorization/auth-details'))
                &&
                this.permissionService.ViewRoles()) {
                return true;
            }
            this.router.navigateByUrl('/notAuth/not-auth');
            return false;
        }
    }
}
