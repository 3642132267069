<div class="layout d-block d-lg-none" *ngIf="( nav.visible )">
    <nav>
        <div class="side_btn">
            <button pButton type="button" (click)="visibleSidebar1 = true">
                <img src="assets/images/icons/Side_btn.svg" alt="">
            </button>

        </div>
        <div class="side-right" >
            <div class="menu languages-menu ui-rtl" dir="rtl">
                <p-menu #languagesMenu [popup]="true" [model]="languagesItems"></p-menu>
            <button type="button" class="cursor-pointer"  icon="pi pi-list" label=" " (click)="languagesMenu.toggle($event)">
                <span class="currentLang">{{translate.currentLang == 'ar-SA'?'عربى':'English'}}</span><span class="flag-icon flag-icon-sa " style="margin-right: 5px;" *ngIf="translate.currentLang == 'ar-SA' "></span>
                <span class="flag-icon flag-icon-us cursor-pointer" style="margin-right: 5px;" *ngIf="translate.currentLang == 'en-US'"></span>
                </button>
            </div>
            <div class="menu notifications-menu">
                <p-menu #notificationsMenu [popup]="true" [model]="notificationsItems"></p-menu>

                <button type="button" pButton icon="pi pi-list" label=" " (click)="notificationsMenu.toggle($event)">
                    <i class="far fa-bell"></i>
                <span class="notification-count">
                  0
                </span>
                <!-- <app-notification></app-notification> -->
              </button>
            </div>
            <div class="menu user-menu">
                <p-menu #userMenu [popup]="true" [model]="userItems"></p-menu>
                <button type="button" class="cursor-pointer" pButton icon="pi pi-list" label=" " (click)="userMenu.toggle($event)">
                <img src="assets/images/navBar/user.png" />
                <span *ngIf="translate.currentLang === 'en-US'">{{currentUser?.userFirstNameEn}} {{currentUser?.userFamilyNameEn}}</span>
                <span *ngIf="translate.currentLang === 'ar-SA'">{{currentUser?.userFirstNameAr}} {{currentUser?.userFamilyNameAr}}</span>
                <i class="cursor-pointer" [class]="userMenu.visible ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
              </button>
            </div>
        </div>
    </nav>
    <p-sidebar [(visible)]="visibleSidebar1" [baseZIndex]="10000">
        <div class="logo ">
            <img class="animate__animated animate__backInDown" src="assets/images/navBar/logo.png" alt="" [routerLink]="['/home']" style="cursor: pointer;">
        </div>
        <ul class="nav-menu">
            <li *ngFor="let item of menu" class="tap-sidebar">
                <a class="a-tap_sidebar {{item.tabIcon}}" (click)="item.open = !item.open" routerLinkActive="router-link-active">
                    <!-- <img src="{{item.tabIcon}}" alt=""> -->
                    <span class="nav-menu__title">{{translate.currentLang == 'en-US' ? item.tabNameEn : item.tabNameAr }} </span>
                    <i [class]="item.open ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
                </a>
                <ul class="sub-menu_sidebar" *ngIf="item.open">
                    <li *ngFor="let navItem of item.subMenu" (click)="visibleSidebar1 = false">
                        <a   routerLink="{{navItem.routerLink != ''? navItem.routerLink: []}}"  routerLinkActive="router-link-active" >
                            <div class="sub-menu__img">
                                <img  src="{{ navItem.icon }}" alt="">
                            </div>
                            <span class="sub-menu__title ml-3 mr-3"> {{translate.currentLang == 'en-US' ? navItem.titleEn : navItem.titleAr }}</span>
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
    </p-sidebar>
</div>
