import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TableModule } from 'primeng/table';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { PanelModule } from 'primeng/panel';
import { MatIconModule } from '@angular/material/icon';
import { CheckboxModule } from 'primeng/checkbox';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { CellEditor } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { MatExpansionModule } from '@angular/material/expansion';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { SliderModule } from 'primeng/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ToastModule } from 'primeng/toast';
import { MatRadioModule } from '@angular/material/radio';
import { SidebarModule } from 'primeng/sidebar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PaginatorModule } from 'primeng/paginator';
import { TreeTableModule } from 'primeng/treetable';
import { MatDialogModule } from '@angular/material/dialog';
import { GoogleMapsModule } from '@angular/google-maps'

// UI Imports
import { MenuModule } from 'primeng/menu';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { MatCommonModule } from '@angular/material/core';
import { NavSliderComponent } from './components/nav-slider/nav-slider.component';
import { FilterComponent } from './components/filter/filter.component';
import { NavSidemenuComponent } from './components/nav-sidemenu/nav-sidemenu.component';
import { NotificationComponent } from './components/notification/notification.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { LocalizedDateModule } from '../core/pipe/localizedDate.module';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';
import { BreadcrumbService } from '../core/services/breadcrumb.service';
import { MapComponent } from './components/map/map.component';
import { MapViewComponent } from './components/map/map-view/map-view.component';
import { MatSelectModule } from '@angular/material/select';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { TreeModule } from 'primeng/tree';
import { NodeService } from '../core/services/nodeService';
import { MatTableModule } from '@angular/material/table';
import { GenericAddressComponent } from './components/generic-address/generic-address.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';


// import { ScheduleModule } from '@syncfusion/ej2-angular-schedule';
import { SubjectService } from '../core/services/subject_service/Subject.service';
// import { LoaderComponent } from './components/loader/loader.component';
// import { SafePipe } from '../core/pipe/safe.pipe';

@NgModule({
    declarations: [
        NavbarComponent,
        SideMenuComponent,
        NavSliderComponent,
        FilterComponent,
        NavSidemenuComponent,
        NotificationComponent,
        BreadCrumbComponent,
        MapComponent,
        MapViewComponent,
        GenericAddressComponent,
    ],
    imports: [
        CommonModule, TranslateModule,
        LocalizedDateModule,
        FormsModule,
        NgbModule,
        MenuModule,
        FontAwesomeModule,
        PanelMenuModule,
        TableModule,
        PanelModule,
        MatIconModule,
        DynamicDialogModule,
        ReactiveFormsModule,
        CarouselModule,
        ToastModule,
        SidebarModule,
        DropdownModule,
        MatAutocompleteModule,
        NgSelectModule,
        TranslateModule,
        MatDialogModule,
        RadioButtonModule,
        // ScheduleModule,
        GoogleMapsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
    ],
    exports: [
        NavbarComponent,
        NgbModule,
        SideMenuComponent,
        NavSliderComponent,
        NavSidemenuComponent,
        NotificationComponent,
        CellEditor,
        TableModule,
        FilterComponent,
        // PanelModule,
        MessageModule,
        TreeTableModule,
        MessagesModule,
        DataViewModule,
        MatRadioModule,
        ButtonModule,
        MatIconModule,
        CheckboxModule,
        MatDialogModule,
        MatCheckboxModule,
        CarouselModule,
        MatBadgeModule,
        SliderModule,
        MatSlideToggleModule,
        DropdownModule,
        FormsModule,
        DialogModule,
        MatCommonModule,
        MatSlideToggleModule,
        MatBadgeModule,
        TabViewModule,
        ConfirmDialogModule,
        InputTextModule,
        RadioButtonModule,
        MultiSelectModule,
        MatExpansionModule,
        // InternationalPhoneNumberModule,
        PaginatorModule,
        MatStepperModule,
        MatFormFieldModule,
        MatInputModule,
        TriStateCheckboxModule,
        ToastModule,
        MatAutocompleteModule,
        NgSelectModule,
        BreadCrumbComponent,
        ProgressSpinnerModule,
        MapComponent,
        MapViewComponent,
        MatSelectModule,
        TreeModule,
        TranslateModule,
        MatTableModule,
        GenericAddressComponent,
        CalendarModule
    ], providers: [BreadcrumbService, TranslateService, NodeService, SubjectService]
})
export class SharedModule { }
