import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './modules/login/components/login/login.component';
import { AuthGuard } from './core/auth.guard';
import { ParentGuard } from './core/parent.guard';
import { ChildGuard } from './core/child.guard';
import { EmployeeGuard } from './core/employee.guard';
import { GroupGuard } from './core/group.guard';
import { PrivacyPoilcyComponent } from './modules/login/components/privacy-poilcy/privacy-poilcy.component';
import { SuggestionGuard } from './core/suggestion.guard';
import { ChildtransitionGuard } from './core/childtransition.guard';
import { RolesGuard } from './core/roles.guard';



const routes: Routes = [
  {
    path: '',
    component: LoginComponent,

  },

  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    data: {
      title: '',
      breadcrumb: [
        {
          label: '',
          url: ''
        }
      ]
    }, canActivate: [AuthGuard]
  },

  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),

  },

  {
    path: 'signup',
    loadChildren: () => import('./modules/signup/signup.module').then((m) => m.SignupModule),

  },

  {
    path: 'child',
    loadChildren: () => import('./modules/child/child.module').then((m) => m.ChildModule),
    data: {
      title: 'Child',
      breadcrumb: [
        {
          label: 'child',
          url: ''
        }
      ]
    }, canActivate: [ChildGuard]
  },

  {
    path: 'parent',
    loadChildren: () => import('./modules/parent/parent.module').then((m) => m.ParentModule),
    data: {
      title: 'parent',
      breadcrumb: [
        {
          label: 'parent',
          url: ''
        }
      ]
    }, canActivate: [ParentGuard]
  },

  {
    path: 'contract',
    loadChildren: () => import('./modules/contract/contract.module').then((m) => m.ContractModule),
    data: {
      title: 'contract',
      breadcrumb: [
        {
          label: 'contract',
          url: ''
        }
      ]
    }
  },
  {
    path: 'service',
    loadChildren: () => import('./modules/services/services.module').then((m) => m.ServicesModule),
    data: {
      title: 'service',
      breadcrumb: [
        {
          label: 'service',
          url: ''
        }
      ]
    }, canActivate: [AuthGuard]

  },
  {
    path: 'employee',
    loadChildren: () => import('./modules/employee/employee.module').then((m) => m.EmployeeModule),
    data: {
      title: 'Employee',
      breadcrumb: [
        {
          label: 'Employee',
          url: ''
        }
      ]
    }, canActivate: [EmployeeGuard]

  },
  {
    path: 'third-party-notice',
    loadChildren: () => import('./modules/third-party-notice/third-party-notice.module').then((m) => m.ThirdPartyNoticeModule),
    data: {
      title: 'Third-Party-Notice',
      breadcrumb: [
        {
          label: 'Third-Party-Notice',
          url: ''
        }
      ]

    }


  },
  {
    path: 'service-booking',
    loadChildren: () => import('./modules/service-booking/service-booking.module').then((m) => m.ServiceBookingModule),
    data: {
      title: 'Service-Booking',
      breadcrumb: [
        {
          label: 'Service-Booking',
          url: ''
        }
      ]
    }

  },
  { path: 'diagnosis', loadChildren: () => import('./modules/diagnosis/diagnosis.module').then(m => m.DiagnosisModule), canActivate: [AuthGuard] }
  ,

  {
    path: 'process',
    loadChildren: () => import('./modules/process/process.module').then((m) => m.ProcessModule),
    data: {
      title: 'Process',
      breadcrumb: [
        {
          label: 'Process',
          url: ''
        }
      ]
    }, canActivate: [AuthGuard]
  },
  {
    path: 'respon',
    loadChildren: () => import('./modules/responsability/responsability.module').then((m) => m.ResponsabilityModule),
    data: {
      title: 'Responsibility',
      breadcrumb: [
        {
          label: 'Responsibility',
          url: ''
        }
      ]
    }, canActivate: [AuthGuard]
  },
  {
    path: 'appointment',
    loadChildren: () => import('./modules/appointment/appointment.module').then((m) => m.AppointmentModule),
    data: {
      title: 'Appointment',
      breadcrumb: [
        {
          label: 'Appointment',
          url: ''
        }
      ]
    }, canActivate: [AuthGuard]
  },
  {
    path: 'profession',
    loadChildren: () => import('./modules/profession/profession.module').then((m) => m.ProfessionModule),
    data: {
      title: 'profession',
      breadcrumb: [
        {
          label: 'profession',
          url: ''
        }
      ]
    }, canActivate: [AuthGuard]
  },

  {
    path: 'roles',
    loadChildren: () => import('./modules/roles/roles.module').then((m) => m.RolesModule),
    data: {
      title: 'Roles',
      breadcrumb: [
        {
          label: 'Roles',
          url: ''
        }
      ]
    }, canActivate: [AuthGuard]
  },
  {
    path: 'notAuth',
    loadChildren: () => import('./modules/not-authorized/not-authorized.module').then((m) => m.NotAuthorizedModule),
    data: {
      title: 'NoTAuth',
      breadcrumb: [
        {
          label: 'NoTAuth',
          url: ''
        }
      ]
    }, canActivate: [AuthGuard]
  },

  {
    path: 'servicefollowup',
    loadChildren: () => import('./modules/servicefollowup/servicefollowup.module').then((m) => m.ServicefollowupModule),
    data: {
      title: 'servicefollowup',
      breadcrumb: [
        {
          label: 'servicefollowup',
          url: ''
        }
      ]
    }
  },

  {
    path: 'session-assignment',
    loadChildren: () => import('./modules/session-assignment/session-assignment.module').then((m) => m.SessionAssignmentModule),
    data: {
      title: 'session-assignment',
      breadcrumb: [
        {
          label: 'session-assignment',
          url: ''
        }
      ]
    }
  },
  {
    path: 'sessions',
    loadChildren: () => import('./modules/sessions/sessions.module').then((m) => m.SessionsModule),
    data: {
      title: 'sessions',
      breadcrumb: [
        {
          label: 'sessions',
          url: ''
        }
      ]
    }
  },
  {
    path: 'Characteristics',
    loadChildren: () => import('./modules/characteristics/characteristics.module').then((m) => m.CharacteristicsModule),
    data: {
      title: 'Characteristics',
      breadcrumb: [
        {
          label: 'Characteristics',
          url: ''
        }
      ]
    }
  }, {
    path: 'beneficiary-groups',
    loadChildren: () => import('./modules/beneficiary-groups/beneficiary-groups.module').then((m) => m.BeneficiaryGroupsModule),
    data: {
      title: 'Group',
      breadcrumb: [
        {
          label: 'Group',
          url: ''
        }
      ]
    }, canActivate: [GroupGuard]

  },
  {
    path: 'companies',
    loadChildren: () => import('./modules/companies/companies.module').then((m) => m.CompaniesModule),
    data: {
      title: 'Companies',
      breadcrumb: [
        {
          label: 'Companies',
          url: ''
        }
      ]
    }

  },
  {
    path: 'areas',
    loadChildren: () => import('./modules/areas/areas.module').then(m => m.AreasModule),
    data: {
      title: 'Areas',
      breadcrumb: [
        {
          label: 'Areas',
          url: ''
        }
      ]
    }
  },
  {
    path: 'plans',
    loadChildren: () => import('./modules/plan/plan.module').then(m => m.PlanModule),
    data: {
      title: 'Plans',
      breadcrumb: [
        {
          label: 'Plans',
          url: ''
        }
      ]
    }
  },
  {
    path: 'plan-structure',
    loadChildren: () => import('./modules/plan-structure/plan-structure.module').then(m => m.PlanStructureModule),
    data: {
      title: 'Plan-structure',
      breadcrumb: [
        {
          label: 'Plan-structure',
          url: ''
        }
      ]
    }
  },
  {
    path: 'session-type',
    loadChildren: () => import('./modules/session-type/session-type.module').then(m => m.SessionTypeModule),
    data: {
      title: 'Session type',
      breadcrumb: [
        {
          label: 'Session type',
          url: ''
        }
      ]
    }
  },
  {
    path: 'service-type',
    loadChildren: () => import('./modules/service-type/service-type.module').then(m => m.ServiceTypeModule),
    data: {
      title: 'Service type',
      breadcrumb: [
        {
          label: 'Service type',
          url: ''
        }
      ]
    }
  },
  {
    path: 'agreement',
    loadChildren: () => import('./modules/agreement/agreement.module').then(m => m.AgreementModule),
    data: {
      title: 'agreement',
      breadcrumb: [
        {
          label: 'agreement',
          url: ''
        }
      ]
    }
  },
  {
    path: 'description',
    loadChildren: () => import('./modules/decription/decription.module').then(m => m.DecriptionModule),
    data: {
      title: 'description',
      breadcrumb: [
        {
          label: 'description',
          url: ''
        }
      ]
    }
  },
  {
    path: 'service-suggestion',
    loadChildren: () => import('./modules/service-suggestion/service-suggestion.module').then(m => m.ServiceSuggestionModule),
    data: {
      title: 'Suggestion',
      breadcrumb: [
        {
          label: 'Suggestion',
          url: ''
        }
      ]
    }, canActivate: [SuggestionGuard]
  },
  {
    path: 'attendance',
    loadChildren: () => import('./modules/attendance/attendance.module').then(m => m.AttendanceModule),
    data: {
      title: 'Attendance',
      breadcrumb: [
        {
          label: 'Attendance',
          url: ''
        }
      ]
    }
  },
  {
    path: 'vacation',
    loadChildren: () => import('./modules/vacation/vacation/vacation.module').then(m => m.VacationModule),
    data: {
      title: 'vacation',
      breadcrumb: [
        {
          label: 'vacation',
          url: ''
        }
      ]
    }
  },
  {
    path: 'schedule',
    loadChildren: () => import('./modules/tripSchedule/trip-schedule.module').then(m => m.TripScheduleModule),
    data: {
      title: 'schedule',
      breadcrumb: [
        {
          label: 'schedule',
          url: ''
        }
      ]
    }
  },
  {
    path: 'vehicle',
    loadChildren: () => import('./modules/vehicle/vehicle.module').then(m => m.VehicleModule),
    data: {
      title: 'vehicle',
      breadcrumb: [
        {
          label: 'vehicle',
          url: ''
        }
      ]
    }
  },
  {
    path: 'vacationType',
    loadChildren: () => import('./modules/vacationType/vacationType.module').then(m => m.VacationTypeModule),
    data: {
      title: 'vacationType',
      breadcrumb: [
        {
          label: 'vacationType',
          url: ''
        }
      ]
    }
  },
  {
    path: 'service-period',
    loadChildren: () => import('./modules/service-period/service-period.module').then(m => m.ServicePeriodModule),
    data: {
      title: 'service-period',
      breadcrumb: [
        {
          label: 'service-period',
          url: ''
        }
      ]
    }, canActivate: []
  },
  { path: 'privacyPolicy', component: PrivacyPoilcyComponent }
  ,
  {
    path: 'childtransition',
    loadChildren: () => import('./modules/child-transition/child-transition.module').then(m => m.ChildTransitionModule),
    data: {
      title: 'childtransition',
      breadcrumb: [
        {
          label: 'childtransition',
          url: ''
        }
      ]
    }, canActivate: [ChildtransitionGuard]
  },
  {
    path: 'distribution',
    loadChildren: () => import('./modules/distribution/distribution.module').then(m => m.DistributionModule),
    data: {
      title: 'distribution',
      breadcrumb: [
        {
          label: 'distribution',
          url: ''
        }
      ]
    }, canActivate: []
  },
  {
    path: 'branchvacation',
    loadChildren: () => import('./modules/branch-vacation/branch-vacation.module').then(m => m.BranchVacationModule),
    data: {
      title: 'branchvacation',
      breadcrumb: [
        {
          label: 'branchvacation',
          url: ''
        }
      ]
    }
  },
  {
    path: 'authorization',
    loadChildren: () => import('./modules/authorization/authorization.module').then(m => m.AuthorizationModule),
    data: {
      title: 'Authorization',
      breadcrumb: [
        {
          label: 'Authorization',
          url: ''
        }
      ]
    }, canActivate: [RolesGuard]

  },
  {
    path: 'reports',
    loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
    data: {
      title: 'test-report',
      breadcrumb: [
        {
          label: 'Session-report',
          url: ''
        }
      ]
    }

  },

  {
    path: 'characteristic-module',
    loadChildren: () => import('./modules/characteristic-module/characteristic-module.module').then(m => m.CharacteristicModuleModule),
    data: {
      title: 'characteristic-module',
      breadcrumb: [
        {
          label: 'characteristic-module',
          url: ''
        }
      ]
    }

  },

  {
    path: 'shift-module',
    loadChildren: () => import('./modules/Shift/shift.module').then(m => m.ShiftModule),
    data: {
      title: 'shift-module',
      breadcrumb: [
        {
          label: 'shift-module',
          url: ''
        }
      ]
    }
  },

  {
    path: 'report-admin',
    loadChildren: () => import('./modules/report-admin/report-admin.module').then(m => m.ReportAdminModule),
    data: {
      title: 'report-admin',
      breadcrumb: [
        {
          label: 'report-admin',
          url: ''
        }
      ]
    }
  },

  {
    path: 'tickets',
    loadChildren: () => import('./modules/tickets/tickets.module').then(m => m.TicketsModule),
    data: {
      title: 'tickets',
      breadcrumb: [
        {
          label: 'tickets',
          url: ''
        }
      ]
    }
  },
  {
    path: 'behavior',
    loadChildren: () => import('./modules/behavior/behavior.module').then(m => m.BehaviorModule),
    data: {
      title: 'behavior',
      breadcrumb: [
        {
          label: 'behavior',
          url: ''
        }
      ]
    }
  },
  {
    path: 'branches',
    loadChildren: () => import('./modules/branches/branches.module').then(m => m.BranchesModule),
    data: {
      title: 'branches'
    }
  },
  {
    path: 'alerts',
    loadChildren: () => import('./modules/alerts/alerts.module').then(m => m.AlertsModule),
    data: {
      title: 'alerts'
    }
  }
];

@NgModule({

  imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule]

})

export class AppRoutingModule { }
