<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
  <span>{{'GENERIC.LOCATIONONMAP'|translate}}</span>
</div>
<div class="modal-body">
  <form>
    <!-- <agm-map [latitude]="selectedLocation?.lat" [longitude]="selectedLocation?.lng" id="map" [zoom]="16">
      <agm-marker [latitude]="selectedLocation?.lat" [longitude]="selectedLocation?.lng">
      </agm-marker>
    </agm-map> -->
    <google-map height="400px" width="750px" [center]="selectedLocation" [zoom]="16" (mapClick)="moveMap($event)"
      (mapMousemove)="move($event)">
    </google-map>
  </form>
</div>