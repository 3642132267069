
<div class="container p-0" [ngClass]="{ 'display-none  ': isLoading }">
    <div class="wrapper">
        <ul class="breadcrumb">
            <li class="breadcrumb-item">
                <a [routerLink]="['/home']">{{ "BREADCRUMB.HOME" | translate }} </a>
            </li>
            <li><i class="fas fa-chevron-right"></i></li>
            <li class="breadcrumb-item">
                <a>
                    {{ "BREADCRUMB.REGISTRATION" | translate }}
                </a>
            </li>
            <li><i class="fas fa-chevron-right"></i></li>
            <li class="breadcrumb-item">
                <a [routerLink]="['/diagnosis/diagnosis-view']">
                    {{ "BREADCRUMB.DIAGNOSIS" | translate }}
                </a>
            </li>
            <li><i class="fas fa-chevron-right"></i></li>
            <li class="breadcrumb-item" *ngIf="!DiagnosisId">
                <a>
                    {{ "BREADCRUMB.ADDDIAGNOSIS" | translate }}
                </a>
            </li>
            <li class="breadcrumb-item" *ngIf="DiagnosisId">
                <a>
                    {{ "BREADCRUMB.EDITDIAGNOSIS" | translate }}
                </a>
            </li>
        </ul>
    </div>
    <div class="wrapper header-box">
        <div class="parent">
            <div class="child">
                <span class="title">{{ "ADD_DIAGNOSIS.DIAGNOSIS" | translate }}</span>
            </div>
        </div>
    </div>
    <div class="wrapper mt-4 box">
        <form class="form-group" [formGroup]="addDiagnosisForm" (submit)="onSubmit()">
            <div class="row mb-3">
                <div class="col-12">
                    <div class="parent">
                        <div>
                            <span class="color-blue">{{
                "ADD_DIAGNOSIS.BASIC_INFO" | translate
              }}</span>&nbsp;&nbsp;
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div class="row">
                    <div class="col-lg-3 input-danger">
                        <div class="form-group input-danger">
                            <label class="col-md-12 control-label p-0" for="Branch ">
                                {{'CHILD.BRANCH'|translate}}
                                </label>
                            <ng-select [ngClass]="{'dropdown-red-border': f.BranchId.errors && ( f.BranchId.touched || isSubmit)}" [items]="Branches" bindLabel="{{valueLangBranch}}" [searchable]="true"
                                    bindValue="id" formControlName="BranchId"  *ngIf="diagnosis==null" (change)="ChangeBranch()"
                                    placeholder="{{ 'JOB_INFO.BRANCH' | translate }} *"></ng-select>
                                    
                                    <span class="form-control" *ngIf="diagnosis!=null">{{translate.currentLang=='en-US'? diagnosis?.branchNameEn:diagnosis?.branchNameAr}}</span>
                                <div *ngIf="f.BranchId.errors && (f.BranchId.touched||isSubmit)"
                                    class="alert alert-danger mt-2 mb-2">
                                    <div *ngIf="f.BranchId.errors.required">
                                        {{ 'ADD_DIAGNOSIS.REQUIRED' | translate }}
                                    </div>
                                </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 diagnoses-container input-danger">
                        <div class="form-group">
                            <label class="col-md-12 control-label p-0" for="Child">{{ "ADD_DIAGNOSIS.CHILD" | translate }}*</label>
                            <ng-select [ngClass]="{'dropdown-red-border': f.ChildId.errors && ( f.ChildId.touched || isSubmit)}" [items]="Children" bindLabel="name1" [searchable]="true" bindValue="id"
                                (search)="loadChildren($event)" class="custom" formControlName="ChildId"
                                placeholder="{{ 'ADD_DIAGNOSIS.CHILD' | translate }}*" *ngIf="diagnosis==null"></ng-select>
                            <span class="form-control" *ngIf="diagnosis!=null">{{translate.currentLang=='en-US'? diagnosis?.childName1:diagnosis?.childName2}}</span>
                            <div *ngIf="f.ChildId.errors && (f.ChildId.touched || isSubmit)"
                                class="alert alert-danger mt-2 mb-2">
                                <div *ngIf="f.ChildId.errors.required">
                                    {{ "ADD_DIAGNOSIS.REQUIRED" | translate }}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 input-danger">
                        <div class="form-group">
                            <label class="col-md-12 control-label p-0" for="date">{{ "ADD_DIAGNOSIS.DATE" | translate }} *</label>
                            <input [ngClass]="{'red-border-class': f.Date.errors && ( f.Date.touched || isSubmit)}"
                            id="date" name="date" type="date" max="{{currentdate | date:'yyyy-MM-dd'}}" formControlName="Date" placeholder="{{ 'ADD_DIAGNOSIS.DATE' | translate }}*"
                                class="form-control input-md" />
                            <div *ngIf="f.Date.errors && (f.Date.touched || isSubmit)"
                                class="alert alert-danger mt-2 mb-2">
                                <div *ngIf="f.Date.errors.required">
                                    {{ "ADD_DIAGNOSIS.REQUIRED" | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-3 input-danger">
                        <div class="form-group">
                            <label class="col-md-12 control-label p-0" for="date">{{ 'ADD_DIAGNOSIS.ATTACHMENT' | translate }}</label>
                            <div class="custom-file">
                                <input type="file" #file style="height: 48px;" class="custom-file-input" multiple
                                    formControlName="importFile" id="importFile"
                                    (change)="onFileChange(file.files)" />
                                <label class="custom-file-label" #labelImport for="importFile"><i
                                        class="fas fa-search"></i>{{ 'ADD_DIAGNOSIS.SELECTFILE' | translate }} </label>
                            </div>

                            <div *ngIf="isUploading" class="alert alert-info">
                                {{ 'ADD_DIAGNOSIS.UPLOADING' | translate }} 
                                </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-md-3 input-danger">
                        <div class="form-group">
                            <label class="col-md-12 control-label p-0" for="diagnosisTemplate">
                                {{ 'ADD_DIAGNOSIS.TEMPLATE' | translate }} *</label>
                            <ng-select [ngClass]="{'red-border-class': f.diagnosisTemplate.errors && ( f.diagnosisTemplate.touched || isSubmit)}" [ngClass]="{'dropdown-red-border': f.diagnosisTemplate.errors && ( f.diagnosisTemplate.touched || isSubmit)}" [items]="DiagnosisTemplateList" bindLabel="{{valueLang}}" bindValue="id"
                                formControlName="diagnosisTemplate" placeholder="{{ 'ADD_DIAGNOSIS.TEMPLATE' | translate }} "
                                (change)="BuildTemplate($event.id,true)"></ng-select>
                                <div *ngIf="f.diagnosisTemplate.errors && ( f.diagnosisTemplate.touched || isSubmit)"
                                class="alert alert-danger mt-2 mb-2">
                                <div *ngIf="f.diagnosisTemplate.errors.required">
                                    {{ 'JOB_INFO.REQUIRED' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8">
                        
                        <ul class="files-conainer">
                            <li *ngFor="let item of fileList;let i=index">
                                <a type="button" style="color: #FFF;float:right;margin-left: -10px; position: relative;" (click)="DeleteAttachment(i)" >
                                    <i class="fa fa-times" style="    position: absolute;
                                    right: 10px;
                                    left: -21px;
                                    z-index: 1;
                                    top: 5px;
                                    color: #EA5455;"></i>
                                </a>
                                <a class="card" href="{{item.filePath}}"  target="_blank">
                                    <div class="pdf">
                                        <i class="fa fa-file"></i>
                                    </div>
                                    <div class="text-center fileName">
                                        <p
                                            style="text-overflow: ellipsis;overflow: hidden; white-space: nowrap;margin-left: 10px;margin-right: 10px;">
                                            {{item.name}}</p>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
            <hr />
            <section class="child-details__container" *ngIf="SelectedTemplate!=null">
                <div class="row mb-3">
                    <div class="col-12">
                        <div class="parent">
                            <div>
                                <span class="color-blue">{{translate.currentLang=='en-US'? SelectedTemplate?.templateName1: SelectedTemplate?.templateName2}}</span>&nbsp;&nbsp;
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row">
                    <div class="col-12">
                        <div class="card-body">
                            <dynamic-form-builder [fields]="getFields()" #templateForm></dynamic-form-builder>
                        </div>
                    </div>
                </div>
                <hr />
            </section>
            <section class="mb-30">
                <div class="text-right direction-rtl">
                    <ul class="list-buttons text-center-s mt-30">
                        <li class="ml-2">
                            <button class="button" type="submit" style="width: 186px;" [disabled]="isLoading||isUploading">
                                {{ "ADD_DIAGNOSIS.SAVE" | translate }}
                            </button>
                        </li>
                        <li>
                            <button class="backButton" type="button" [routerLink]="['/diagnosis/diagnosis-view']"
                                style="width: 186px;">
                                {{ "ADD_DIAGNOSIS.BACK" | translate }} 
                            </button>
                        </li>
                    </ul>
                </div>
            </section>
        </form>
    </div>
</div>