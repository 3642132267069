import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { marker } from '../map.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-map-view',
  templateUrl: './map-view.component.html',
  styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent {
  @Input() selectedLocation: marker = {};

  constructor(public activeModal: NgbActiveModal) { }
  moveMap(event: google.maps.MapMouseEvent) {
    if (event.latLng != null) this.selectedLocation = (event.latLng.toJSON());
  }
  move(event: google.maps.MapMouseEvent) {
    // if (event.latLng != null) this.display = event.latLng.toJSON();
  }
}
