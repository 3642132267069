import {
  Component,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  AgendaService,
  DayService,
  DragAndDropService,
  EventSettingsModel,
  MonthService,
  PopupOpenEventArgs,
  ScheduleComponent,
  TimelineMonthService,
  TimelineViewsService,
  View,
} from '@syncfusion/ej2-angular-schedule';
import { DialogService } from 'primeng/dynamicdialog';
import {
  AssignmentRepetitionDaysModel,
  AssignmentRepetitionModel,
  AssignmentServiceProxy,
} from 'src/Nswag/RMSBackEnd/SwaggerGenerated';
import { ConfirmationService, Message } from 'primeng/api';
import { SessionAssignmentModule } from '../../session-assignment.module';
import { loadCldr, L10n } from "@syncfusion/ej2-base";
import { DatePipe, formatDate } from '@angular/common';

declare var require: any;
export const ZoneAddedHours = new InjectionToken<number>('ZoneAddedHours');

loadCldr(
  require("../../../../../../node_modules/cldr-data/main/ar-SA/numbers.json"),
  require("../../../../../../node_modules/cldr-data/main/ar-SA/ca-gregorian.json"),
  require("../../../../../../node_modules/cldr-data/supplemental/numberingSystems.json"),
  require("../../../../../../node_modules/cldr-data/main/ar-SA/timeZoneNames.json"),
);
L10n.load({
  'ar-SA': {
    schedule: {
      day: 'يوم',
      week: 'إسبوع',
      month: 'شهر',
      year: 'سنة',
      today: 'اليوم',
    },
    datepicker: {
      today: 'اليوم',
    },
  },
});
@Component({
  selector: 'app-assignment-calender',
  templateUrl: './assignment-calender.component.html',
  styleUrls: ['./assignment-calender.component.scss'],
  providers: [
    DayService,
    TimelineViewsService,
    TimelineMonthService,
    MonthService,
    AgendaService,
    DragAndDropService,
    DialogService,
    ConfirmationService
  ],
})
export class AssignmentCalenderComponent implements OnInit, OnChanges {

  @ViewChild('scheduleObj') public scheduleObj: ScheduleComponent;
  @Input() sessions: any[] = [];
  @Input() assignment: any = {};
  @Input() viewMode: boolean = false;
  @Input() sessionTypeIndex: number;
  @Input() EditMode: boolean;
  @Output() createSession = new EventEmitter<{
    assignmentRepetition: AssignmentRepetitionModel;
    sessionTypeIndex: number;
  }>();
  @Output() getSessions = new EventEmitter<number>();
  public currentView: View = 'Week';
  views: Array<string> = ['Day', 'Week', 'Month'];
  public selectedDate: Date = new Date();
  eventSettings: EventSettingsModel = {
    enableTooltip: true,
    dataSource: [],
  };
  msgs: Message[] = [];
  calendarForm: FormGroup;
  dialogHeader: string;
  repetitionTypes: any[];
  isOnce = false;
  displayPopup = false;
  assignmentRepetition = new AssignmentRepetitionModel();
  currentDayId: number;
  showDeleteConfirmation: boolean;
  zoneAddedHours: number;
  constructor(
    public assignmentService: AssignmentServiceProxy,
    public dialogService: DialogService,
    public translateService: TranslateService,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    @Inject(ZoneAddedHours) zoneAddedHours?: number
  ) {
    this.zoneAddedHours = zoneAddedHours;
  }

  ngOnInit(): void {
    if (localStorage.getItem("selectedDate")) {
      if (this.EditMode)
        this.selectedDate = new Date(localStorage.getItem("selectedDate"))
      else if (localStorage.getItem("selectedDateChanged") && localStorage.getItem("selectedDateChanged") == 'true') {
        this.selectedDate = new Date(localStorage.getItem("selectedDate"))
        localStorage.removeItem("selectedDateChanged")
      } else {
        this.selectedDate = new Date();
      }

      localStorage.removeItem("selectedDate")
    }
    this.buildForm();
    this.updateCalendarSessions();
    this.getRepetitionTypes();
    if (window.innerWidth <= 480) {
      this.currentView = 'Day';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.sessions) {
      this.sessions = changes.sessions.currentValue
      this.updateCalendarSessions();
    }

    if (changes.assignment) {
      this.assignment = changes.assignment.currentValue
    }

    if (changes.sessionTypeIndex) {
      this.sessionTypeIndex = changes.sessionTypeIndex.currentValue
    }
  }

  buildForm() {
    this.calendarForm = this.fb.group({
      sessionType: new FormControl(),
      repeated: new FormControl(),
      timeFrom: new FormControl(),
      date: new FormControl(),
      dateTo: new FormControl(),
      days: new FormGroup({
        sunday: new FormControl(),
        monday: new FormControl(),
        tuesday: new FormControl(),
        wednesday: new FormControl(),
        thursday: new FormControl()
      })
    });
  }
  updateCalendarSessions() {
    if (this.sessions && this.sessions.length) {
      let i: number = 0;
      this.sessions.forEach((item: any) => {
        item.EventID = ++i;
        item.Id = ++i;
        item.StartTime = item.from;
        item.EndTime = item.to;
        item.Subject = item.rmsidSessionType;
        item.Color = '#D4D4EA';
        if (!this.viewMode && item.isResponsible) item.Color = '#A6CA3E';
        if (this.assignment.id == item.assignmentId) item.Color = '#34A8BE';
        if (
          !this.viewMode &&
          this.assignment.assignmentSessionTypes.length > 0 &&
          this.assignment.assignmentSessionTypes[this.sessionTypeIndex].id ==
          item.assignmentSessionTypeId
        )
          item.Color = '#FF9F43';
      });
      if (this.EditMode)
        this.selectedDate = this.sessions[0].StartTime;
      this.eventSettings.dataSource = this.sessions;
    }
  }
  getRepetitionTypes() {
    this.assignmentService.getRepetitionTypes().subscribe((res) => {
      this.repetitionTypes = res.data;
    });
  }
  sessionsToolTip(): void {
    let tooltipObj = (this.scheduleObj.element as any).ej2_instances[2];
    tooltipObj.mouseTrail = false;
    tooltipObj.openDelay = 500;
  }
  setSessionsColors(args): void {
    args.element.style.backgroundColor = args.data.Color;
  }
  createSessions(args: PopupOpenEventArgs): void {
    args.cancel = true;
    if ((args.type == 'QuickInfo' || (args.type == 'Editor' && args.duration)) && !args.data.Id && this.assignment.isDraft)
      this.quickAdd(args.data);
    else if ((args.type == 'Editor' || args.type == "ViewEventInfo") && args.data.Id && args.data.assignmentId == this.assignment.id)
      this.openPopup(args.data);
  }
  moveSessions(args) {
    if (args.changedRecords && args.changedRecords.length) {
      if (this.assignment.id != args.changedRecords[0].assignmentId)
        return;
      this.showSpinner();
      this.assignmentService.getRepetitionBySession(args.changedRecords[0].sessionId).subscribe((res) => {
        if (res.data) this.assignmentRepetition = res.data;
        this.assignmentRepetition.toDate.setHours(this.assignmentRepetition.toDate.getHours() + this.zoneAddedHours);
        this.assignmentRepetition.fromDate.setHours(this.assignmentRepetition.fromDate.getHours() + this.zoneAddedHours);
        localStorage.setItem("selectedDate", this.assignmentRepetition.fromDate.toString())

        if (this.assignmentRepetition.repeatitionTypeId == this.repetitionTypes.find((rt) => rt.statusEn == 'Once').id) {
          this.quickAdd(args.data, this.assignmentRepetition.id);
        }
        else if (this.assignmentRepetition.repeatitionTypeId == this.repetitionTypes.find((rt) => rt.statusEn == 'Weekly').id) {
          this.moveAllRepetedDays(args)
        }
        else {
          if (new Date(args.data.date).setHours(0, 0, 0, 0) != new Date(args.data.StartTime).setHours(0, 0, 0, 0))
            return this.confirmationService.confirm({
              key: 'confirm',
              message: this.translateService.currentLang == 'en-US' ? "You cann't move daily sessions" : "لا يمكنك تحريك جلسات يومية",
              header: this.translateService.currentLang == 'en-US' ? 'Alert' : 'تنبيه',
              rejectVisible: false,
              acceptLabel: this.translateService.currentLang == 'en-US' ? 'Ok' : 'موافق',
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                this.getSessions.emit(this.sessionTypeIndex);
              }
            });
          else
            this.moveAllRepetedDays(args)
        }
      });
    }
  }
  selectedDateChange(args) {
    localStorage.setItem("selectedDate", args);
    localStorage.setItem("selectedDateChanged", 'true');
    this.EditMode = true;
  }
  moveAllRepetedDays(args) {
    this.confirmationService.confirm({
      key: 'confirm',
      message:
        this.translateService.currentLang == 'en-US'
          ? 'All sessions that exist in this point will move'
          : 'سيتم نقل كل الجلسات الموجودة في هذا الموعد',
      header:
        this.translateService.currentLang == 'en-US' ? 'Confirmation' : 'تأكيد',
      rejectLabel: this.translateService.currentLang == 'en-US' ? 'No' : 'لا',
      acceptLabel: this.translateService.currentLang == 'en-US' ? 'Yes' : 'نعم',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        // let assRep = this.assignmentRepetition.assignmentRepetitionDays.find(a=>a.dayId == args.data.date.getDay())
        // assRep.dayId = args.data.StartTime.getDay();
        // assRep.timeFromCalender = args.data.StartTime;
        // assRep.timeFromCalender.setHours(assRep.timeFromCalender.getHours() + this.zoneAddedHours)

        this.assignmentRepetition.assignmentRepetitionDays.forEach(assRep => {
          if (assRep.dayId == args.data.date.getDay()) {
            assRep.dayId = args.data.StartTime.getDay();
            assRep.timeFromCalender = args.data.StartTime;
          }
          assRep.timeFromCalender.setHours(assRep.timeFromCalender.getHours() + this.zoneAddedHours)
        });
        this.createSession.emit({
          assignmentRepetition: this.assignmentRepetition,
          sessionTypeIndex: this.sessionTypeIndex,
        });
        this.msgs = [
          {
            severity: 'info',
            summary: 'Confirmed',
            detail: 'You have accepted',
          },
        ];
      },
      reject: () => {
        this.msgs = [
          {
            severity: 'info',
            summary: 'Rejected',
            detail: 'You have rejected',
          },
        ];
      },
    });

  }
  quickAdd(assignmentRepetition, repId = undefined) {
    localStorage.setItem("selectedDate", assignmentRepetition.StartTime.toString())
    if (assignmentRepetition.StartTime.getDay() > 4)
      return this.confirmationService.confirm({
        key: 'confirm',
        message: this.translateService.currentLang == 'en-US' ? "You cann't add sessions at this day" : "لا يمكنك إضافة جلسات لهذا اليوم",
        header: this.translateService.currentLang == 'en-US' ? 'Alert' : 'تنبيه',
        rejectVisible: false,
        acceptLabel: this.translateService.currentLang == 'en-US' ? 'Ok' : 'موافق',
        icon: 'pi pi-exclamation-triangle',
      });
    let h = assignmentRepetition.StartTime.getHours();
    let m = assignmentRepetition.StartTime.getMinutes();
    let time = (h > 9 ? h : '0' + h) + ':' + (m > 9 ? m : '0' + m);

    this.calendarForm.reset();
    let repeated = this.assignment.assignmentTypeId.toLowerCase() != 'a9d3c838-eca0-413a-b8ca-8a3aea0ad5cd' ? this.repetitionTypes.find((rt) => rt.statusEn == 'Once').id : this.repetitionTypes.find((rt) => rt.statusEn == 'Weekly').id
    this.calendarForm.patchValue({
      repeated: repeated,
      timeFrom: time,
      date: assignmentRepetition.StartTime,
      dateTo: assignmentRepetition.EndTime,
    });
    (this.calendarForm.controls.days as any).controls[DayOfWeek[assignmentRepetition.StartTime.getDay()]].patchValue(time)
    this.assignmentRepetition.id = repId;
    this.saveRepetation();
  }
  openPopup(item?) {
    let format = 'L';
    let locale = 'en-US';
    this.currentDayId = item.StartTime.getDay();
    this.showSpinner();
    this.assignmentService
      .getRepetitionBySession(item.sessionId)
      .subscribe((res) => {
        if (res.data) this.assignmentRepetition = res.data;
        let h = this.assignmentRepetition.assignmentRepetitionDays[0].timeFromCalender.getHours();
        let m = this.assignmentRepetition.assignmentRepetitionDays[0].timeFromCalender.getMinutes();
        let time = (h > 9 ? h : '0' + h) + ':' + (m > 9 ? m : '0' + m);
        this.calendarForm.reset();
        this.calendarForm.patchValue({
          sessionType: this.assignmentRepetition.sessionTypeNameEn,
          repeated: this.assignmentRepetition.repeatitionTypeId,
          timeFrom: time,

          date: this.formatDate(
            this.assignmentRepetition.fromDate),
          dateTo: this.formatDate(this.assignmentRepetition.toDate),
        });
        this.changeRepetationType();
        for (let d = 0; d < this.assignmentRepetition.assignmentRepetitionDays.length; d++) {
          let h = this.assignmentRepetition.assignmentRepetitionDays[d].timeFromCalender.getHours();
          let m = this.assignmentRepetition.assignmentRepetitionDays[d].timeFromCalender.getMinutes();
          let time = (h > 9 ? h : '0' + h) + ':' + (m > 9 ? m : '0' + m);
          (this.calendarForm.controls.days as any).controls[DayOfWeek[this.assignmentRepetition.assignmentRepetitionDays[d].dayId]].patchValue(time)
        }
        if (
          this.assignmentRepetition.repeatitionTypeId ==
          this.repetitionTypes.find((rt) => rt.statusEn == 'Once').id
        )
          this.dialogHeader =
            this.translateService.currentLang == 'en-US'
              ? 'Create Sessions'
              : 'إضافة جلسات';
        this.displayPopup = true;
        this.hideSpinner();
      });
  }
  changeRepetationType() {
    if (
      this.repetitionTypes.find((rt) => rt.statusEn == 'Once').id ==
      this.calendarForm.controls['repeated'].value
    )
      this.isOnce = true;
    else this.isOnce = false;

    if (this.assignmentRepetition.repeatitionTypeId == this.repetitionTypes.find((rt) => rt.statusEn == 'Once').id &&
      this.repetitionTypes.find((rt) => rt.statusEn == 'Daily').id == this.calendarForm.controls['repeated'].value) {
      Object.keys((this.calendarForm.controls.days as any).controls).forEach(key => {
        (this.calendarForm.controls.days as any).controls[key].patchValue((this.calendarForm.controls.days as any).controls[DayOfWeek[this.currentDayId]].value);
      })
    }
  }
  saveRepetation(isDeleteMode = false) {
    this.assignmentRepetition.assignmentId = this.assignment.id;
    this.assignmentRepetition.assignmentSessionTypeId = this.assignment.sessionTypeId;
    this.assignmentRepetition.fromDate = new Date(this.calendarForm.controls['date'].value);
    this.assignmentRepetition.toDate = new Date(this.calendarForm.controls['dateTo'].value);
    if (isDeleteMode == false && this.assignmentRepetition.toDate <= this.assignmentRepetition.fromDate && this.repetitionTypes.find((rt) => rt.statusEn == 'Once').id !=
      this.calendarForm.controls['repeated'].value) {
      return this.confirmationService.confirm({
        key: 'confirm',
        message: this.translateService.currentLang == 'en-US' ? "You cann't add Date to less than or equal to date from" : "لا يمكنك إضافة تاريخ إلى أقل من أو يساوي تاريخ من",
        header: this.translateService.currentLang == 'en-US' ? 'Alert' : 'تنبيه',
        rejectVisible: false,
        acceptLabel: this.translateService.currentLang == 'en-US' ? 'Ok' : 'موافق',
        icon: 'pi pi-exclamation-triangle',
      });
    }

    this.assignmentRepetition.assignmentRepetitionDays = [];

    if (this.repetitionTypes.find((rt) => rt.statusEn == 'Once').id ==
      this.calendarForm.controls['repeated'].value && this.assignment.assignmentTypeId.toLowerCase() != 'a9d3c838-eca0-413a-b8ca-8a3aea0ad5cd') {
      if (isDeleteMode == false && this.assignmentRepetition.fromDate.getDay() > 4)
        return this.confirmationService.confirm({
          key: 'confirm',
          message: this.translateService.currentLang == 'en-US' ? "You cann't add sessions at this day" : "لا يمكنك إضافة جلسات لهذا اليوم",
          header: this.translateService.currentLang == 'en-US' ? 'Alert' : 'تنبيه',
          rejectVisible: false,
          acceptLabel: this.translateService.currentLang == 'en-US' ? 'Ok' : 'موافق',
          icon: 'pi pi-exclamation-triangle',
        });
      (this.calendarForm.controls.days as any).controls[DayOfWeek[this.assignmentRepetition.fromDate.getDay()]].patchValue(this.calendarForm.controls['timeFrom'].value)
    }

    Object.keys((this.calendarForm.controls.days as any).controls).forEach(key => {
      if ((this.calendarForm.controls.days as any).controls[key].value) {
        let repetitionDay = new AssignmentRepetitionDaysModel();
        repetitionDay.dayId = DayOfWeek[key];
        repetitionDay.timeFromCalender = new Date(Date.parse("2001-01-01T" + (this.calendarForm.controls.days as any).controls[key].value));
        repetitionDay.timeFromCalender.setHours(repetitionDay.timeFromCalender.getHours() + this.zoneAddedHours)
        this.assignmentRepetition.assignmentRepetitionDays.push(repetitionDay);
      }
    });

    if (this.repetitionTypes.find((rt) => rt.statusEn == 'Once').id ==
      this.calendarForm.controls['repeated'].value) {
      this.assignmentRepetition.repeatitionTypeId = this.calendarForm.controls['repeated'].value;
    }
    else {
      if (this.assignmentRepetition.assignmentRepetitionDays.length == 5)
        this.assignmentRepetition.repeatitionTypeId = this.repetitionTypes.find(
          (rt) => rt.statusEn == 'Daily'
        ).id;
      else
        this.assignmentRepetition.repeatitionTypeId = this.repetitionTypes.find(
          (rt) => rt.statusEn == 'Weekly'
        ).id;
    }
    this.createSession.emit({
      assignmentRepetition: this.assignmentRepetition,
      sessionTypeIndex: this.sessionTypeIndex,
    });
    this.displayPopup = false;
  }
  deleteRepetation() {
    if (this.repetitionTypes.find((rt) => rt.statusEn == 'Once').id ==
      this.calendarForm.controls['repeated'].value) {
      this.confirmationService.confirm({
        key: 'confirm',
        message:
          this.translateService.currentLang == 'en-US'
            ? 'Are you sure that you want to delete this session?'
            : 'هل انت متأكد انك تريد حذف هذه الجلسة',
        header:
          this.translateService.currentLang == 'en-US' ? 'Confirmation' : 'تأكيد',
        rejectLabel: this.translateService.currentLang == 'en-US' ? 'No' : 'لا',
        acceptLabel: this.translateService.currentLang == 'en-US' ? 'Yes' : 'نعم',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.showSpinner();
          this.assignmentService.deleteAssignmentSetting(this.assignmentRepetition.id).subscribe(res => {
            this.getSessions.emit(this.sessionTypeIndex);
            this.displayPopup = false;
          },
            (error) => { }
          );
          this.msgs = [
            {
              severity: 'info',
              summary: 'Confirmed',
              detail: 'You have accepted',
            },
          ];
        },
        reject: () => {
          this.msgs = [
            {
              severity: 'info',
              summary: 'Rejected',
              detail: 'You have rejected',
            },
          ];
        },
      });
    }
    else {
      this.showDeleteConfirmation = true;
      this.confirmationService.confirm({
        key: 'confirmDelete',
        message:
          this.translateService.currentLang == 'en-US'
            ? 'Are you want to delete all session under this Assignment?'
            : 'هل انت تريد حذف كل الجلسات في هذا التخصيص؟',
        header:
          this.translateService.currentLang == 'en-US' ? 'Confirmation' : 'تأكيد',

        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.msgs = [
            {
              severity: 'info',
              summary: 'Confirmed',
              detail: 'You have accepted',
            },
          ];
        },
        reject: () => {
          this.msgs = [
            {
              severity: 'info',
              summary: 'Rejected',
              detail: 'You have rejected',
            },
          ];
        },
      });
    }

  }
  deleteAll() {
    this.displayPopup = false;
    this.showDeleteConfirmation = false;
    this.showSpinner();
    this.assignmentService.deleteAssignmentSetting(this.assignmentRepetition.id).subscribe(res => {
      this.getSessions.emit(this.sessionTypeIndex);
      this.displayPopup = false;
    },
      (error) => { }
    );
  }
  deleteOne() {
    this.displayPopup = false;
    this.showDeleteConfirmation = false;
    (this.calendarForm.controls.days as any).controls[DayOfWeek[this.currentDayId]].patchValue(null)
    this.saveRepetation(true);
  }
  cancelDelete() {
    this.displayPopup = false;
    this.showDeleteConfirmation = false;
  }
  cancelRepetation() {
    this.displayPopup = false;
  }
  showSpinner() {
    this.scheduleObj.showSpinner();
  }
  hideSpinner() {
    this.scheduleObj.hideSpinner();
  }
  private formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');
  }
}
export enum DayOfWeek {
  sunday = <any>0,
  monday = <any>1,
  tuesday = <any>2,
  wednesday = <any>3,
  thursday = <any>4,
}
