import { Component, NgZone, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavBarService } from './core/services/nav-bar.service';
import { BnNgIdleService } from 'bn-ng-idle';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { UserModel } from './core/interfaces/auth/user-model';
declare const gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent {


  currentWindowWidth: number;

  constructor(public nav: NavBarService, private bnIdle: BnNgIdleService, private router: Router, private zone: NgZone,
    private translate: TranslateService) {
    this.translate.setDefaultLang('en-US');
    this.translate.use('en-US');
    this.onResize()
    // Start watching for user inactivity.
    this.zone.run(() => {
      this.bnIdle.startWatching(36000).subscribe((res) => {

        if (res) {
          localStorage.removeItem('user');
          this.router.navigateByUrl('/login');

        }
      });
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      /** START : Code to Track Page View  */
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      })
      /** END */
    })

    this.addGAScript();
  }
  /** Add Google Analytics Script Dynamically */
  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    // gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
  }
  breadcrumbConfig: object = {
    bgColor: '#f6f7ff',
    fontSize: '16px',
    fontColor: '#7674A1',
    lastLinkColor: '#34A8BE',
    symbol: '> ',
  };

  @HostListener('window:resize')
  onResize() {
    this.currentWindowWidth = window.innerWidth;
  }
}
