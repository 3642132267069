import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { ChildService } from 'src/app/core/services/api_call/child.service';
import { NavBarService } from 'src/app/core/services/nav-bar.service';
import { Lookups } from 'src/app/core/interfaces/Lookups/Lookups';
import { DiagnosisService } from 'src/app/core/services/api_call/diagnosis.service';
import { DiagnosisTemplate, diagnosisTemplateFieldItems, diagnosisTemplateAnswersModel, DiagnosisAttachmentsModel } from 'src/app/core/interfaces/diagnosis/diagnosisTemplate';
import { TemplateEnum } from 'src/app/core/enum/templateEnum.enum';
import { DynamicFormBuilderComponent } from 'src/app/core/_helper/dynamic-form-builder/dynamic-form-builder.component';
import { ActivatedRoute, Router } from '@angular/router';
import { EmployeeService } from 'src/app/core/services/api_call/Employee.service';
import { ChildDropDownFilter } from 'src/app/core/interfaces/child/childDropDownFilter';

@Component({
  selector: 'app-add-diagnosis',
  templateUrl: './add-diagnosis.component.html',
  styleUrls: ['./add-diagnosis.component.scss']
})
export class AddDiagnosisComponent implements OnInit  {
  isLoading = false;
  addDiagnosisForm: FormGroup;
  Children: SelectItem[] = [];
  childernModelList: any[] = [];
  DiagnosisTemplateList: DiagnosisTemplate[] = [];
  errorMessage;
  successMessage;
  isSubmit = false;
  DiagnosisId: string;
  @ViewChild('labelImport')
  labelImport: ElementRef;
  formImport: FormGroup;

  Branches:any[]=[];
  fileToUpload: File = null;
  valueLang="templateName1";
  currentdate=new Date().toISOString().slice(0, 10);

  public form: FormGroup;
  unsubcribe: any
  SelectedTemplate:DiagnosisTemplate;
  
  fileList:DiagnosisAttachmentsModel[]=[];
  isUploading=false;
  valueLangBranch="nameEn";

  @ViewChild('templateForm') templateForm: DynamicFormBuilderComponent=new DynamicFormBuilderComponent();

  
  constructor(public translate: TranslateService, private childService: ChildService, private fb: FormBuilder, 
    public nav: NavBarService,private diagnosisService:DiagnosisService, private activeroute: ActivatedRoute,
    private router:Router,private employeeService:EmployeeService) {
    this.formImport = new FormGroup({
      importFile: new FormControl('', Validators.required)
    });
   }
  ngOnInit(): void {
    this.valueLang= this.translate.currentLang=='en-US'?'templateName1':'templateName2';
    this.valueLangBranch= this.translate.currentLang=='en-US'?'nameEn':'nameAr';
    this.loadBranches();
    this.loadTemplates();
    this.formInit();
    
    
  }
  loadBranches() {
    this.employeeService.GetAllRMSBranches().subscribe(res=>{
      this.Branches=res.data;
    },error=>{
      
    })
  }
  formInit(){
    this.addDiagnosisForm = this.fb.group(
      {
        ChildId: [null, Validators.required],
        Date: ['', Validators.required],
        diagnosisTemplate: [null, Validators.required],
        importFile: [''],
        BranchId:[null,Validators.required]
      });
      
    if (this.activeroute.snapshot.paramMap.get('id') != null) {
      this.f.ChildId.setValidators(Validators.nullValidator);
      this.f.BranchId.setValidators(Validators.nullValidator);
    }
  }
  ChangeBranch(){
    this.f.ChildId.setValue(null);
    this.Children=[];
  }
  diagnosis:any;
  loadDiagnosis() {
    if (this.activeroute.snapshot.paramMap.get('id') != null) {
      var id=this.activeroute.snapshot.paramMap.get('id');
      this.DiagnosisId=id;
      this.isLoading=true;
      this.diagnosisService.GetDiagnosisDetailsView(id).subscribe(
        res=>{         
          this.diagnosis=res.data;
          var date = new Date(this.diagnosis.diagnosisDate.toString());
          this.f.Date.setValue( new Date(date.setDate(date.getDate() + 1))
          .toISOString()
          .slice(0, 10));
          this.f.diagnosisTemplate.setValue(this.diagnosis.templateId);
          if(this.diagnosis.attachments.length>0){
            this.diagnosis.attachments.forEach(element => {
              this.fileList.push({
                diagnosisAttachmentId:element.id,
                filePath:element.filePath,
                name:element.fileName
              })
            });
          }
          
          this.BuildTemplate(this.diagnosis?.templateId);
          this.isLoading=false;
        },error=>{
          
          this.isLoading=false;
        }
      )
    }
  }
  DeleteAttachment(index){
    this.fileList.splice(index, 1);
  }
  loadTemplates() {
    this.diagnosisService.GetAllDiagnosisTemplates().subscribe(
      res=>{
        this.DiagnosisTemplateList=res.data;
        this.loadDiagnosis();
      },error=>{
        
      }
    )
  }
  onFileChange(files: FileList) {
    this.labelImport.nativeElement.innerText = Array.from(files)
      .map(f => f.name)
      .join(', ');
    this.fileToUpload = files.item(0);
    Array.from(files).forEach(element => {
      this.UploadFiles(element);
    });
  }
  UploadFiles(files) {
    if (files.length === 0) {
      return;
    }
    const formData=new FormData();
      formData.append('file',files);
      this.isUploading=true;
      this.diagnosisService.UploadFiles(formData).subscribe(
        (res:any)=>{
          this.fileList.push({
            diagnosisAttachmentId:res.data.id,
            filePath:res.data.filePath,
            name:res.data.name
          });
          this.isUploading=false;
        },error=>{
          
          this.isUploading=false;
        }
      )
  }
  import(): void {
  }


  get f() {
    return this.addDiagnosisForm.controls;
  }

  public fields: any[] = [];
  BuildTemplate(templateId,isChanged?:boolean){
    var questions;
    if(this.diagnosis!=null){
      if(this.diagnosis?.templateId==templateId){
        questions=this.diagnosis?.questions;
      }else{
        questions=undefined;
      }
    }

    this.fields.splice(0,this.fields.length); 
    this.SelectedTemplate=this.DiagnosisTemplateList?.find(e=>e.id==templateId);
    this.SelectedTemplate?.diagnosisTemplateFields.forEach(element => {
      switch(element.fieldType){
        case TemplateEnum.TextBox:
          this.fields.push({
            type: 'text',
            name: element.id,
            label:this.translate.currentLang=='en-US'?element.fieldName1:element.fieldName2,
            value: questions!=undefined?questions.find(e=>e.id==element.id).answer:'',
            required: element.isRequired,
            formGroup:this.translate.currentLang=='en-US'?element.formGroupEn:element.formGroupAr
          });
          break;
        case TemplateEnum.DropdownList:
          this.fields.push({
            type: 'dropdown',
            name: element.id,
            label:this.translate.currentLang=='en-US'?element.fieldName1:element.fieldName2,
            value: questions!=undefined?questions.find(e=>e.id==element.id).answer:'',
            required: element.isRequired,
            options: this.getMultiFieldValue(element.diagnosisTemplateFieldItems),
            formGroup:this.translate.currentLang=='en-US'?element.formGroupEn:element.formGroupAr
          });
          break;
        case TemplateEnum.CheckBoxList:
          this.fields.push({
            type: 'checkbox',
            name: element.id,
            label:this.translate.currentLang=='en-US'?element.fieldName1:element.fieldName2,
            value: '',
            required: element.isRequired,
            options: this.getMultiFieldValue(element.diagnosisTemplateFieldItems),
            formGroup:this.translate.currentLang=='en-US'?element.formGroupEn:element.formGroupAr
          });
          break;
        case TemplateEnum.CheckBox:
          this.fields.push({
            type: 'checkbox',
            name: element.id,
            label:this.translate.currentLang=='en-US'?element.fieldName1:element.fieldName2,
            value: '',
            required: element.isRequired,
            options: this.getMultiFieldValue(element.diagnosisTemplateFieldItems),
            formGroup:this.translate.currentLang=='en-US'?element.formGroupEn:element.formGroupAr
          });
          break;
        case TemplateEnum.RadioButton:
          this.fields.push({
            type: 'radio',
            name: element.id,
            label:this.translate.currentLang=='en-US'?element.fieldName1:element.fieldName2,
            value: '',
            required: element.isRequired,
            options: this.getMultiFieldValue(element.diagnosisTemplateFieldItems),
            formGroup:this.translate.currentLang=='en-US'?element.formGroupEn:element.formGroupAr
          });
          break;
        case TemplateEnum.RadioButtonList:
          this.fields.push({
            type: 'radio',
            name: element.id,
            label:this.translate.currentLang=='en-US'?element.fieldName1:element.fieldName2,
            value: '',
            required: element.isRequired,
            options: this.getMultiFieldValue(element.diagnosisTemplateFieldItems),
            formGroup:this.translate.currentLang=='en-US'? element.formGroupEn : element.formGroupAr
          });
          break;
        default:
          break;
      }
    });
    if(isChanged){
      this.templateForm.LoadFields();
      this.form = new FormGroup({
        fields: new FormControl(JSON.stringify(this.fields))
      })
      this.unsubcribe = this.form.valueChanges.subscribe((update) => {
        this.fields = JSON.parse(update.fields);
      })
    }
    
    
  }
  getMultiFieldValue(fields:diagnosisTemplateFieldItems[]) {
    var items:any[]=[];
    fields.forEach(element => {
      items.push({key:this.translate.currentLang=='en-US'? element.itemValue1:element.itemValue2,
       label: this.translate.currentLang=='en-US'?element.itemText1:element.itemText2})
    });
    return items;
  }

  onUpload(e) {
  }

  getFields() {
    return this.fields;
  }

  ngDistroy() {
    this.unsubcribe();
  }

  loadChildren($event) {
    if ($event.term.length==0){
      this.Children=[];
    }
    if ($event.term.length > 0) {     
      var model:ChildDropDownFilter={
        rmsBranchId:this.f.BranchId.value,
        searchTerm:$event.term
      } 
    this.childService.getChildrenDropDown(model).subscribe(
      res => {
        if (res.isSuccess) {
          this.Children =  res.data.map(c => {            
            return { "name1": c.identity  + '-'+(this.translate.currentLang=='en-US'? c.userFirstNameEn: c.userFirstNameAr)  + ' ' +
            (this.translate.currentLang=='en-US'? c.userMiddelNameEn: c.userMiddelNameAr) , 
             "id": c.id };
          });
          this.errorMessage = '';
        }
        else {

          this.errorMessage = res.message;
        }
     
      }, error => {
        
      })
    }

  }

  onSubmit(){
    this.isSubmit=true;
    if(this.addDiagnosisForm.valid&&this.templateForm.form.valid){
      if (this.activeroute.snapshot.paramMap.get('id') == null) {
        var model={
          childId:this.f.ChildId.value,
          diagnosisDate:this.f.Date.value,
          diagnosisTemplateId:this.f.diagnosisTemplate.value,
          diagnosisTemplateAnswers:this.mapFields(this.templateForm.form.value),
          diagnosisAttachments:this.fileList
        };
        this.isLoading=true;
        this.diagnosisService.AddDiagnosis(model).subscribe(
          (res:any)=>{
            if (res.isSuccess) {
              this.errorMessage="";
              this.successMessage = res.message;
              this.router.navigateByUrl('/diagnosis/diagnosis-details/'+res.data.id);
            }else{
              this.successMessage="";
              this.errorMessage = res.message;
            }
            this.isSubmit=false;
            this.isLoading=false;
          },error=>{
            
            this.isSubmit=false;
            this.isLoading=false;
          }
        )
      }
      else{
        //Update
        var updatemodel={
          id:this.diagnosis.id,
          childId:this.diagnosis.childId,
          diagnosisDate:this.f.Date.value,
          diagnosisTemplateId:this.f.diagnosisTemplate.value,
          diagnosisTemplateAnswers:this.mapFields(this.templateForm.form.value),
          diagnosisAttachments:this.fileList
        };
        this.isLoading=true;
        this.diagnosisService.UpdateDiagnosis(updatemodel).subscribe(
          (res:any)=>{
            if (res.isSuccess) {
              
              this.errorMessage="";
              this.successMessage = res.message;
              this.router.navigateByUrl('/diagnosis/diagnosis-details/'+res.data.id);
            }else{
              this.successMessage="";
              this.errorMessage = res.message;
            }
            this.isSubmit=false;
            this.isLoading=false;
          },error=>{
            
            this.isSubmit=false;
            this.isLoading=false;
          }
        )
      }
    }else{
      this.successMessage="";
      this.errorMessage=this.translate.instant('GENERIC.FILLREQUIREDFIELDS');
      this.isLoading=false;
    }
  }
  mapFields(value: any) {
    var answers:diagnosisTemplateAnswersModel[]=[];
    this.SelectedTemplate.diagnosisTemplateFields.forEach(element => {
      var fieldId=element.id;
      var answer= value[fieldId];
      answers.push({
        fieldId:fieldId,
        answer:answer
      })
      
    });
    return answers;
  }

}
