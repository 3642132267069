import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { UserModel } from '../interfaces/auth/user-model';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  currentUser: UserModel;
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.currentUser = JSON.parse(localStorage.getItem("user")) as UserModel;

    if (this.currentUser) {
      request = request.clone({
        headers: request.headers.set("Authorization", "Bearer " + this.currentUser.token),
      });
    }

    request = request.clone({
      headers: request.headers.set("Accept", "application/json"),
    });

    request = request.clone({
      withCredentials: true
    });

    return next.handle(request);
  }
}
