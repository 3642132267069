
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserModel } from './interfaces/auth/user-model';
import { PermissionsService } from './services/api_call/permissions.service';

@Injectable({
    providedIn: 'root'
})
export class ChildGuard  {
    constructor(private router: Router, private permissionService: PermissionsService) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        var user = JSON.parse(localStorage.getItem("user")) as UserModel;
        let url = state.url;
        if (user) {
            if (url.startsWith('/child/child-stakeholder') && this.permissionService.canViewChild()) {
                return true;
            }
            else if (url.startsWith('/child/child-details')&& this.permissionService.canViewChildDetails()) {
                return true;
            }
            else if (url.startsWith('/child/add-child') && this.permissionService.canAddChild()) {
                return true;
            }
            else if (url.startsWith('/child/edit-child') && this.permissionService.canEditChild()) {
                return true;
            }

            this.router.navigateByUrl('/notAuth/not-auth');
            return false;
        } else {
            this.router.navigateByUrl('/notAuth/not-auth');
            return false;
        }
    }

}
