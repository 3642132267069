<div class="modal-header">
  <h4 class="modal-title">{{'GENERIC.SELECT_LOCATION' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form>
    <google-map height="400px" width="465px" [center]="center" [zoom]="zoom">
      <ng-container *ngFor="let m of markers; let i = index">
        <map-marker [position]="m" *ngIf="m.isShown" [options]="m"></map-marker>
      </ng-container>
    </google-map>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn button" (click)="confirmLocation()">{{'GENERIC.CONFIRM_LOCATION' |
    translate}}</button>
</div>