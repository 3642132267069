import { Component, DoCheck, OnChanges, OnInit, AfterViewInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NavBarService } from 'src/app/core/services/nav-bar.service';

import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
import { AuthService } from 'src/app/core/services/api_call/auth.service';
import { UserModel } from 'src/app/core/interfaces/auth/user-model';
import { DynamicMenuResponseModel, DynamicMenuServiceProxy } from '../../../../Nswag/RMSBackEnd/SwaggerGenerated';
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-sidemenu',
  templateUrl: './nav-sidemenu.component.html',
  styleUrls: ['./nav-sidemenu.component.scss'],
  providers: [DynamicMenuServiceProxy],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          height: 'auto',
          opacity: 1,
        })
      ),
      state(
        'close',
        style({
          height: 0,
          opacity: 0,
        })
      ),
      transition('* => close', [animate('1s')]),
      transition('* => open', [animate('.5s')]),
    ]),
  ],
})
export class NavSidemenuComponent implements OnInit {
  visibleSidebar1: any;

  // Icons
  faChevronDown = faChevronDown;
  userItems: MenuItem[];
  languagesItems: MenuItem[];
  notificationsItems: MenuItem[];
  sideMenu: any;
  currentUser: UserModel;
  menu: DynamicMenuResponseModel[] = [];
  CallMenu: boolean = false;

  constructor(
    public translate: TranslateService,
    public nav: NavBarService,
    private router: Router,
    private dynamicMenuServiceProxy: DynamicMenuServiceProxy,
    private authService: AuthService) {

    const previousLang = localStorage.getItem('language');
    translate.addLangs(['en-US', 'ar-SA']);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/ar|ar-SA/) ? 'ar-SA' : 'en-US');
  }

  ngOnInit(): void {
    if(localStorage.getItem("menu")){
      this.menu = JSON.parse(localStorage.getItem("menu")) as DynamicMenuResponseModel[]
    } else if(this.ChecKLoggedInUser()) {
      this.getMenu()
    }

    this.languageHandler();

    this.currentUser = JSON.parse(localStorage.getItem('user')) as UserModel;
    this.authService.currentUserEvent.subscribe(
      (userSingal: UserModel) => {
        this.currentUser = userSingal
      }
    )
    this.userItems = [
      // {
      //   label: this.translate.currentLang == 'en-US' ? 'Profile' : 'الملف الشخصي',
      //   url: '/login',
      //   icon: 'pi pi-fw pi-user',
      //   command: () => this.languageSwitcher('ar-SA'),
      // },
      {
        label: this.translate.currentLang == 'en-US' ? 'logout' : 'تسجيل الخروج',

        icon: 'pi pi-fw pi-sign-out',
        command: () => this.logout()
      },

    ];
    this.languagesItems = [
      {
        label: 'Arabic',
        icon: 'flag-icon flag-icon-sa',
        command: () => this.languageSwitcher('ar-SA'),
        styleClass: this.translate.currentLang === 'ar-SA' ? 'active' : '',
      },
      {
        label: 'English',
        icon: 'flag-icon flag-icon-us',
        command: () => this.languageSwitcher('en-US'),
        styleClass: this.translate.currentLang === 'en-US' ? 'active' : '',
      },
    ];

    this.notificationsItems = [
    ];

    this.sideMenu = [
      {
        tabName: 'NAV_SLIDER.REGISTER',
        tabIcon: 'ico-menu-registration',
        open: false,

        subMenu: [{
          title: 'NAV_SLIDER.CONTRACT',
          icon: 'assets/images/menu/ico-menu-contract.png',
          class: "",
          routerLink: '/contract/contract-view',
        },
        ],
      },


    ];
  }
  getMenu() {
    this.dynamicMenuServiceProxy.getMenu().subscribe(res => {
      if (res.isSuccess) {
        localStorage.setItem("menu", JSON.stringify(res.data))
        this.menu = JSON.parse(localStorage.getItem("menu")) as DynamicMenuResponseModel[]
      }
    });
  }
  logout(): void {
    this.authService.logout();
  }
  languageSwitcher(language): void {
    this.translate.use(language);
    localStorage.setItem('language', language);
    this.authService.switchLanguage(language).subscribe((res) => { });
  }

  languageHandler(): void {
    let localLang = localStorage.getItem('language');
    if (localLang) {
      this.translate.use(localLang);
      this.translate.currentLang = localLang;
      this.authService.switchLanguage(localLang).subscribe(res => { });
    }
    else {
      this.translate.use('ar-SA');
      this.authService.switchLanguage('ar-SA').subscribe(res => { });
    }
  }

  ChecKLoggedInUser(): boolean {
    return localStorage.getItem('user') == null ? false : true;
  }
}
