
<div class="container p-0" [ngClass]="{ 'display-none  ': isLoading }">
    <div class="wrapper">
        <ul class="breadcrumb">
            <li class="breadcrumb-item">
                <a [routerLink]="['/home']">{{'BREADCRUMB.HOME' | translate }}
                </a>
            </li>
            <li><i class="fas fa-chevron-right"></i></li>
            <li class="breadcrumb-item">
                <a>
                    {{'BREADCRUMB.REGISTRATION' | translate }}
                </a>

            </li>
            <li><i class="fas fa-chevron-right"></i></li>
            <li class="breadcrumb-item">
                <a [routerLink]="['/diagnosis/diagnosis-view']">
                    {{'BREADCRUMB.DIAGNOSIS' | translate }}
                </a>
            </li>
            <li><i class="fas fa-chevron-right"></i></li>
            <li class="breadcrumb-item">
                <a>
                    {{'BREADCRUMB.DIAGNOSISDETAILS' | translate }}
                </a>
            </li>


        </ul>
    </div>
    <div class="wrapper header-box">
        <div class="parent">
            <div class="child">
                <span class="title">{{ 'ADD_DIAGNOSIS.DETAILS' | translate }}</span>
            </div>
            <div class="child">
                <span class="color-blue cursor-pointer" [routerLink]="['/diagnosis/edit-diagnosis',diagnosis?.id]">
                    <span>
                        <img src="assets/images/icons/editBlue.svg" alt="">
                    </span>&nbsp;
                    <span>{{ 'ADD_DIAGNOSIS.EDIT' | translate }}</span>
                </span>
            </div>
        </div>
    </div>
    <div class="wrapper mt-10 child-details__container">

        <div class="box">
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="color-blue">
                            {{ 'ADD_DIAGNOSIS.BASIC_INFO' | translate }}

                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row border-top ">
                        <div class="col-lg-12 mt-30">
                            <div class="parent2">
                                <div class="child">
                                    <p class="child-detail__header">{{ 'ADD_DIAGNOSIS.CHILD' | translate }}
                                    </p>
                                    <p class="child-detail__Data">{{translate.currentLang=='en-US'? diagnosis?.childName1:diagnosis?.childName2}}</p>
                                    <p class="child-detail__header mt-30">
                                          {{ 'ADD_DIAGNOSIS.DIAGNOSIS' | translate }}</p>
                                    <p class="child-detail__Data "> {{ 'ADD_DIAGNOSIS.CLINICALDIAGNOSIS' | translate }} </p>
                                    
                                    <p class="child-detail__header mt-30">{{ 'ADD_DIAGNOSIS.ATTACHMENT' | translate }}</p>
                                    <ul class="files-conainer">
                                        <li *ngFor="let item of diagnosis?.attachments">
                                            <a class="card" href="{{item.filePath}}" target="_blank">
                                                <div class="pdf">{{item.extension}}</div>
                                                <div class="text-center fileName">
                                                    <p
                                                        style="text-overflow: ellipsis;overflow: hidden; white-space: nowrap;">
                                                        {{item.fileName}}</p>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="child">
                                    <p class="child-detail__header ">{{ 'ADD_DIAGNOSIS.CHILDNO' | translate }}
                                    </p>
                                    <p class="child-detail__Data ">#{{diagnosis?.childNumber}}</p>

                                    <p class="child-detail__header mt-30">
                                         {{ 'ADD_DIAGNOSIS.SUGGESTEDSERVICETYPE' | translate }} </p>
                                    <p class="child-detail__Data "> {{ 'ADD_DIAGNOSIS.MEDICALSEVICE' | translate }}</p>

                                </div>
                                <div class="child">
                                    <p class="child-detail__header ">{{ 'ADD_DIAGNOSIS.DATE' | translate }} 
                                    </p>
                                    <p class="child-detail__Data ">{{diagnosis?.diagnosisDate | date}}</p>
                                    <p class="child-detail__header mt-30">{{ 'ADD_DIAGNOSIS.BRANCH' | translate }} </p>
                                    <p class="child-detail__Data">{{translate.currentLang=='en-US'? diagnosis?.branchNameEn: diagnosis?.branchNameAr}}</p>
                                </div>
                                <div class="child">
                                    <p class="child-detail__header "> </p>
                                    <div class="child-detail__Data ">
                                        
                                        
                                        <input type="radio" [checked]="diagnosis?.isExternal" disabled class="form-check-input" id="exernalDiagnosis"
                                            value="exernalDiagnosis">
                                            <label id="externalDiagnosisLabel"class="form-check-label" for="exernalDiagnosis">
                                        
                                                {{ 'ADD_DIAGNOSIS.EXTERNAL' | translate }} 
                                            </label>
                                    </div>
                                    <p class="child-detail__header mt-30">{{ 'ADD_DIAGNOSIS.DIAGNOSIS_ID' | translate }} </p>
                                    <p class="child-detail__Data">#{{diagnosis?.diagnosisID}}</p>
                                </div>
                            </div>

                        </div>
                        <div class="col-12">

                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <div class="box mt-30 mb-30">
            <mat-accordion>
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                    [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title class="color-blue">
                            "{{translate.currentLang=='en-US'? diagnosis?.templateName1: diagnosis?.templateName2}}"

                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row border-top ">
                        <div class="col-lg-12 mt-30" *ngFor="let item of answers| keyvalue">
                            <div class="row">
                                <div class="col-3">
                                    <p class="child-detail__header">{{'ADD_DIAGNOSIS.SET_NAME'|translate}}</p>
                                    <p class="child-detail__Data">{{item.key}}</p>
                                </div>
                            </div>
                            <hr>
                            <div *ngFor="let field of item.value">
                                <div class="parent2">
                                    <div class="child">
                                        <p class="child-detail__header">{{translate.currentLang=='en-US'? field.questionEn:field.questionAr}} <span *ngIf="field.isRequired">*</span>
                                        </p>
                                        <p class="child-detail__Data">{{field.answer}} </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
        <section class="mb-30">
            <div class="col-12 direction-rtl">
                <ul class="list-buttons text-center-s mt-30 mb-30">
                    <li>
                        <button class="backButton" [routerLink]="['/diagnosis/diagnosis-view']" type="submit"
                            style="width: 186px;">{{ 'SERVICEBOOKINGDETAILS.BACK' | translate }}</button>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</div>