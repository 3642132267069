import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChildFilter } from '../../interfaces/child/childFilter';
import { Observable } from 'rxjs';
import { ChildGrid } from '../../interfaces/child/childGrid';
import { Child } from '../../interfaces/child/child.interface';
import { BaseResponse } from 'src/app/shared/components/ng-models/base-response-model';
import { ChildDropDownFilter } from '../../interfaces/child/childDropDownFilter';
import { ServiceBookingFilterModel } from '../../interfaces/service-booking/service-booking-filter-model';
import { API_BASE_URL } from 'src/Nswag/RMSBackEnd/SwaggerGenerated';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ChildService {
  baseUrl: string;
  AddChildURL: string;
  UpdateChildURL: string;
  GetChildDetailsURL: string;
  GetAllChildsURL: string;
  GetChildsFilterURL: string;
  DeleteChildURL: string;
  UploadImageURL: string;
  GetChildrenDropDownURL: string;
  GetChildThirdPartiesURL: string;
  GetChildThirdPartyBranchesURL: string;
  GetAllChildServiceTypesURL: string;
  GetRelativesDropDownURL: string;
  IsFinancialNumExistUrl: string;
  CheckDuplicateBeneficiaryNumberURL: string;
  exportChildrenGridToPDFURL: string;
  exportChildrenGridToExcelURL: string;
  getFilteredServiceBookingUrl: string;
  getChildAttachmentTypesUrl: string;
  getChildAttachmentsUrl: string;
  Child: Child;
  ChildGrid: ChildGrid;

  constructor(private http: HttpClient, public translate: TranslateService, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "https://localhost:44352";
    this.AddChildURL = this.baseUrl + '/Child/add-child/';
    this.UpdateChildURL = this.baseUrl + '/Child/update-child/';
    this.GetChildDetailsURL = this.baseUrl + '/Child/get-child-details/';
    this.GetAllChildsURL = this.baseUrl + '/Child/get-all-children/';
    this.GetChildsFilterURL = this.baseUrl + '/Child/get-children-filter/';
    this.DeleteChildURL = this.baseUrl + '/Child/delete-child/';
    this.UploadImageURL = this.baseUrl + '/Attachment/save-child-profile-image/';
    this.GetChildrenDropDownURL = this.baseUrl + '/Child/get-children-dropdown/';
    this.GetChildThirdPartiesURL = this.baseUrl + '/Child/get-child-third-parties/';
    this.GetChildThirdPartyBranchesURL = this.baseUrl + '/Child/get-child-third-party-branches/';
    this.GetAllChildServiceTypesURL = this.baseUrl + '/ServiceBooking/get-service-type-lookups?';
    this.GetRelativesDropDownURL = this.baseUrl + '/Child/get-relatives-dropdown/';
    this.IsFinancialNumExistUrl = this.baseUrl + '/Child/is-financial-num-exist/'
    this.CheckDuplicateBeneficiaryNumberURL = this.baseUrl + '/Child/beneficiary-number-duplicate-check/';
    this.exportChildrenGridToPDFURL = this.baseUrl + '/Child/children-grid-export-pdf';
    this.exportChildrenGridToExcelURL = this.baseUrl + '/Child/children-grid-export-excel';
    this.getFilteredServiceBookingUrl = this.baseUrl + '/Child/get-filtered-service-bookings/';
    this.getChildAttachmentTypesUrl = this.baseUrl + "/Child/get-child-attachement-types/";
    this.getChildAttachmentsUrl = this.baseUrl + "/Child/get-child-attachements/"
  }

  getChildDetails(id): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.GetChildDetailsURL + id)
  }
  getChildsFilter(filterRequest: ChildFilter): Observable<any> {
    return this.http.post<any>(this.GetChildsFilterURL, filterRequest)
  }
  uploadImage(file) {
    return this.http.post(this.UploadImageURL, file);
  }

  addChild(model): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(this.AddChildURL, model);
  }
  updateChild(model): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(this.UpdateChildURL, model);
  }

  deleteChild(id): Observable<BaseResponse> {
    return this.http.delete<BaseResponse>(this.DeleteChildURL + id);
  }
  getChildrenDropDown(model: ChildDropDownFilter): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(this.GetChildrenDropDownURL, model);
  }
  getChildThirdPartiesDropDwon(childId): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.GetChildThirdPartiesURL + childId);
  }
  getChildThirdPartyBranches(model): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(this.GetChildThirdPartyBranchesURL, model);
  }
  getAllChildServiceTypes(rmsBranchId): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.GetAllChildServiceTypesURL + 'brid=' + `${rmsBranchId}` + '&' + 'currentLung=' + `${this.translate.currentLang}`);
  }

  GetRelativesDropDown(searchTerm) {
    return this.http.get(this.GetRelativesDropDownURL + searchTerm);
  }

  isFinancialNumExist(number, id): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.IsFinancialNumExistUrl + `${number}` + '/' + `${id}`);
  }
  checkDuplicateBeneficiaryNumber(number): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.CheckDuplicateBeneficiaryNumberURL + `${number}`);
  }
  ExportChildrenGridPDF(filterRequest: ChildFilter): Observable<any> {
    return this.http.post<any>(this.exportChildrenGridToPDFURL, filterRequest)
  }
  exportChildrenGridExcel(filterRequest: ChildFilter): Observable<any> {
    return this.http.post<any>(this.exportChildrenGridToExcelURL, filterRequest)
  }

  getFilteredServiceBooking(model: ServiceBookingFilterModel): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(this.getFilteredServiceBookingUrl, model);
  }

  getChildAttachementTypes(): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.getChildAttachmentTypesUrl);
  }

  getChildAttachments(childId: string): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(`${this.getChildAttachmentsUrl}${childId}`);
  }
}
