import { Injectable, EventEmitter, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthenticationModel } from '../../interfaces/auth/authentication-model';
import { UserModel } from '../../interfaces/auth/user-model';
import { Observable, ReplaySubject } from 'rxjs';
import { ForgotPasswordModel } from '../../interfaces/auth/forgot-password-model';
import { ResetPasswordModel } from '../../interfaces/auth/reset-password-model';
import { Router } from '@angular/router';
import * as jwt_decode from 'jwt-decode';
import { Languages } from '../../enum/language.emum';
import { BaseResponse } from 'src/app/shared/components/ng-models/base-response-model';
import { API_BASE_URL, UsersServiceProxy } from 'src/Nswag/RMSBackEnd/SwaggerGenerated';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl: string;
  private UserAuthenticationUrl: string;
  private ForgotPasswordUrl: string;
  private ResetPasswordUrl: string;
  private switchLanguageUrl: string;
  private ValidateUserIdentificationsUrl: string;
  private decompressScopeUrl:string;

  constructor(private http: HttpClient,  private usersServiceProxy: UsersServiceProxy, private router: Router, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "https://localhost:44352";

    this.UserAuthenticationUrl = this.baseUrl + '/Users/user-authentication';
    this.ForgotPasswordUrl = this.baseUrl + '/Users/forgot-password';
    this.ResetPasswordUrl = this.baseUrl + '/Users/reset-password';
    this.switchLanguageUrl = this.baseUrl + '/Language/set-lang';
    this.ValidateUserIdentificationsUrl = this.baseUrl + '/Users/validate-user-identifications/'
    this.decompressScopeUrl = this.baseUrl + '/api/Authorization/decompress-scope/'
  }


  currentUserEvent = new EventEmitter<UserModel>();
  currentLanguageEvent = new EventEmitter<Languages>();

  UserAuthentication(model: AuthenticationModel): Observable<UserModel> {
    return this.http.post<UserModel>(this.UserAuthenticationUrl, model);
  }

  ForgotPassword(model: ForgotPasswordModel): Observable<any> {
    return this.http.post<any>(this.ForgotPasswordUrl, model);
  }

  ResetPassword(model: ResetPasswordModel): Observable<any> {
    return this.http.post<any>(this.ResetPasswordUrl, model);
  }

  switchLanguage(lang) {
    this.currentLanguageEvent.emit()
    return this.http.put(this.switchLanguageUrl + '/' + lang, null);
  }

  isLoggedIn() {
    var user = JSON.parse(localStorage.getItem("user")) as UserModel;
    return !!user;
  }

  logout() {
    var user = JSON.parse(localStorage.getItem("user")) as UserModel;
    //if (user != null) {
    localStorage.removeItem("user");
    localStorage.removeItem("menu");
    this.router.navigateByUrl('');
    //}
  }

  ValidateUserIdentifications(model): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(this.ValidateUserIdentificationsUrl, model)
  }

  decodeToken() {
    var user = JSON.parse(localStorage.getItem("user")) as UserModel;
    var token = user.token;
    var decoded = jwt_decode(token);
    return decoded
  }
  decompressScope() {

    return this.http.get(this.decompressScopeUrl);
  }

  refreshToken() {
    return this.usersServiceProxy.refreshToken().pipe(tap((res) => {
      var user = JSON.parse(localStorage.getItem("user")) as UserModel;
      user.token = res.data.token
      localStorage.setItem("user", JSON.stringify(user))
    }))
  }
}
