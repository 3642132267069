<nav class="d-lg-flex justify-content-between" *ngIf="nav.visible">
  <div class="container">
    <div class="col-12 text-center">
      <div class="slider-container">
        <div class="wrapper-slider">
          <owl-carousel-o [options]="customOptions">

            <ng-template carouselSlide [width]="267" *ngFor="let item of menu">
              <div ngbDropdown container="body">
                <button class="btn btn-outline-primary " [ngClass]="item.tabIcon" id="dropdownMenuButton"
                  ngbDropdownToggle> {{translate.currentLang == 'en-US' ? item.tabNameEn : item.tabNameAr }} </button>
                <div ngbDropdownMenu [ngClass]="{'rtl':translate.currentLang == 'ar-SA'}"
                  aria-labelledby="dropdownMenuButton">

                  <button *ngFor="let navItem of item.subMenu" class="{{navItem.class}}" ngbDropdownItem
                    [routerLink]="navItem.routerLink">
                    <img [src]="navItem.icon" style="cursor: pointer;" />
                    {{translate.currentLang == 'en-US' ? navItem.titleEn : navItem.titleAr }} </button>




                </div>
              </div>
            </ng-template>


            <!-- <ng-template carouselSlide>
              <div ngbDropdown container="body">
                <button class="btn btn-outline-primary ico-menu-session" id="dropdownMenuButton"
                  ngbDropdownToggle>{{ 'NAV_SLIDER.SESSION' | translate }} </button>
                <div [ngClass]="{'rtl':translate.currentLang == 'ar-SA'}" ngbDropdownMenu
                  aria-labelledby="dropdownMenuButton">
                  <button ngbDropdownItem [routerLink]="['/sessions/sessions-list']">
                    <img src="assets/images/menu/ico-menu-session-details.png"
                      style="cursor: pointer;" />{{ 'NAV_SLIDER.SESSIONS' | translate }}
                  </button>
                  <button ngbDropdownItem [routerLink]="['/session-type/session-type-list']">
                    <img src="assets/images/menu/ico-menu-session-types.png"
                      style="cursor: pointer;" />{{ 'NAV_SLIDER.SESSIONTYPES' | translate }}
                  </button>
                  <button ngbDropdownItem [routerLink]="['/session-assignment/assignment-list']">
                    <img src="assets/images/menu/ico-menu-ession-assignment.png"
                      style="cursor: pointer;" />{{ 'NAV_SLIDER.SESSIONASSIGNMENT' | translate }}
                  </button>
                                                    <button ngbDropdownItem class="dimmed" [routerLink]="['/recurring/recurring-view']">-->
            <!--                                    <img src="assets/images/menu/ico-menu-recurring-assignment.png"-->
            <!--                                         style="cursor: pointer;" />{{ 'NAV_SLIDER.RECURRINGASSIGNMENT' | translate }}-->
            <!--                                  </button>-->
            <!--      </div>
              </div>
            </ng-template> -->


            <!--                        <ng-template carouselSlide>-->
            <!--                            <div ngbDropdown container="body" class="dimmed">-->
            <!--                                <button class="btn btn-outline-primary ico-menu-crm dimmed" id="dropdownMenuButton"-->
            <!--                                    ngbDropdownToggle>{{ 'NAV_SLIDER.CRM' | translate }} </button>-->
            <!--                                <div [ngClass]="{'rtl':translate.currentLang == 'ar-SA'}" ngbDropdownMenu-->
            <!--                                    aria-labelledby="dropdownMenuButton">-->
            <!--                                    <button ngbDropdownItem class="dimmed"> <img-->
            <!--                                            src="assets/images/menu/ico-menu-appointment.png"-->
            <!--                                            style="cursor: pointer;" />{{ 'NAV_SLIDER.APPOINTMENT' | translate }}-->
            <!--                                    </button>-->
            <!--                                    <button ngbDropdownItem [routerLink]="['/appointment/view-appointment']"> <img-->
            <!--                                            src="assets/images/menu/ico-menu-appointment-tripSchedule.png"-->
            <!--                                            style="cursor: pointer;" />{{ 'NAV_SLIDER.APPOINTMENTSCHEDULE' | translate }}-->
            <!--                                    </button>-->

            <!--                                </div>-->
            <!--                            </div>-->
            <!--                        </ng-template>-->

            <!-- <ng-template carouselSlide>
              <div ngbDropdown container="body">
                <button class="btn btn-outline-primary ico-menu-settings" id="dropdownMenuButton" ngbDropdownToggle>{{
                  'NAV_SLIDER.SETTINGS' | translate }} </button>
                <div [ngClass]="{'rtl':translate.currentLang == 'ar-SA'}" ngbDropdownMenu
                  aria-labelledby="dropdownMenuButton">
                  <button ngbDropdownItem [routerLink]="['/plans/plans-list']"> <img
                      src="assets/images/menu/ico-menu-plan.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.PLANS' | translate }} </button>
                  <button ngbDropdownItem [routerLink]="['/plan-structure/plan-structure-list']"> <img
                      src="assets/images/menu/ico-menu-plan-structure.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.PLAN_STRUCTURE' | translate }} </button>
                  <button ngbDropdownItem [routerLink]="['/agreement/list-agreement']"> <img
                      src="assets/images/menu/ico-menu-plan-structure.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.AGREEMENT' | translate }} </button>
                  <button ngbDropdownItem [routerLink]="['/description/list-description']"> <img
                      src="assets/images/menu/ico-menu-plan-structure.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.DESCRIPTION' | translate }} </button>
                  <button ngbDropdownItem [routerLink]="['/areas/areas-list']"> <img
                      src="assets/images/menu/ico-menu-area-sub.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.AREAS' | translate }} </button>
                  <button ngbDropdownItem [routerLink]="['/companies/thired-parties-list']"> <img
                      src="assets/images/menu/ico-menu-third-party-not.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.THIRED_PARTIES' | translate }} </button>
                  <button ngbDropdownItem [routerLink]="['/companies/branches-list']"> <img
                      src="assets/images/menu/ico-menu-third-party-not.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.BRANCHES' | translate }} </button>
                  <button ngbDropdownItem [routerLink]="['/vehicle/vehicle-list']"> <img
                      src="assets/images/menu/ico-menu-plan.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.VEHICLE' | translate }} </button>

                  <button ngbDropdownItem [routerLink]="['/authorization/auth-view']"> <img
                      src="assets/images/menu/ico-menu-plan.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.Roles' | translate }} </button>

                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div ngbDropdown container="body">
                <button class="btn btn-outline-primary ico-menu-settings" id="dropdownMenuButton" ngbDropdownToggle>{{
                  'NAV_SLIDER.ATTENDANCE' | translate }} </button>
                <div [ngClass]="{'rtl':translate.currentLang == 'ar-SA'}" ngbDropdownMenu
                  aria-labelledby="dropdownMenuButton">
                  <button ngbDropdownItem [routerLink]="['/attendance/attendance-list']"> <img
                      src="assets/images/menu/ico-menu-plan.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.CHILD_ATTENDANCE' | translate }} </button>
                  <button ngbDropdownItem [routerLink]="['/vacation/vacation-list']"> <img
                      src="assets/images/menu/ico-menu-plan.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.VACATION' | translate }} </button>
                  <button ngbDropdownItem [routerLink]="['/childtransition/child-transition-list']"> <img
                      src="assets/images/menu/ico-menu-plan.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.CHILDTRANSITION' | translate }} </button>

                  <button ngbDropdownItem [routerLink]="['/branchvacation/branch-vacation-list']"> <img
                      src="assets/images/menu/ico-menu-plan.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.BRANCHVACATION' | translate }} </button>
                  <button ngbDropdownItem [routerLink]="['/distribution/list-distribution']">
                    <img src="assets/images/menu/ico-menu-plan.png" style="cursor: pointer;" />
                    {{'NAV_SLIDER.DISTRIBUTION' | translate }} </button>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div ngbDropdown container="body">
                <button class="btn btn-outline-primary ico-menu-settings" id="dropdownMenuButton" ngbDropdownToggle>{{
                  'NAV_SLIDER.TRANSPORTATION' | translate }} </button>
                <div [ngClass]="{'rtl':translate.currentLang == 'ar-SA'}" ngbDropdownMenu
                  aria-labelledby="dropdownMenuButton">
                  <button ngbDropdownItem [routerLink]="['/schedule/tripSchedule-list']"> <img
                      src="assets/images/menu/ico-menu-plan.png" style="cursor: pointer;" />{{
                    'NAV_SLIDER.SCHEDULE' | translate }} </button>

                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div ngbDropdown container="body">
                <button class="btn btn-outline-primary ico-menu-settings" id="dropdownMenuButton" ngbDropdownToggle>{{
                  'NAV_SLIDER.REPORTS' | translate }} </button>
                <div [ngClass]="{'rtl':translate.currentLang == 'ar-SA'}" ngbDropdownMenu
                  aria-labelledby="dropdownMenuButton">
                  <button ngbDropdownItem [routerLink]="['/reports/followup-report']">
                    <img src="assets/images/menu/ico-menu-plan.png" style="cursor: pointer;" />
                    {{
                        'NAV_SLIDER.SessionREPORT' | translate }}
                  </button>

                </div>
              </div>
            </ng-template> -->
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</nav>
