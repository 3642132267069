<div class="triangle"></div>

<div class="subject">
    Notifications
    <span class="link">View All</span>
</div>

<div class="container">
    <div class="icon">
        <div class="txt">KM</div>
    </div>
    <div class="content"><b>Kate: Hello, I was wondering if you...</b>
        <div class="time">5 hours ago</div>
    </div>
</div>

<div class="container">
    <div class="icon">
        <div class="txt">SH</div>
    </div>
    <div class="content">Sina: Thanks
        <div class="time">10 hours ago</div>
    </div>
</div>

<div class="container">
    <div class="content">Component Ready for Review
        <div class="time">15 hours ago</div>
    </div>
</div>

<!-- should only have top right and left borders -->

<div class="container last">
    <div class="icon">
        <div class="txt">SD</div>
    </div>
    <div class="content">Sanjay: Okay
        <div class="time">2 days ago</div>
    </div>
</div>