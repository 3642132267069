import { BrowserModule } from '@angular/platform-browser';
import { InjectionToken, NgModule, Output } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { BnNgIdleService } from 'bn-ng-idle'; // import bn-ng-idle service
// Ngx Translate Imports
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { DiagnosisModule } from './modules/diagnosis/diagnosis.module';
import { ConfirmationService } from 'primeng/api';
import { MatDialogModule } from '@angular/material/dialog';
import { AppConfigService, getApiBaseUrl , getRefreshTokenTime, getReportViewrUrl, getZoneAddedHours } from '../assets/configs/AppConfigService';
import { API_BASE_URL, UsersServiceProxy } from 'src/Nswag/RMSBackEnd/SwaggerGenerated';
import { ZoneAddedHours } from './modules/session-assignment/components/assignment-calender/assignment-calender.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderService } from './core/services/loader.service';
import { LoaderInterceptor } from './core/interceptors/loader.interceptor';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

export const RefreshTokenTime = new InjectionToken<number>('RefreshTokenTime');


@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent
  ],
  imports: [

    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    DiagnosisModule,
    NgbModule
  ],
  providers: [ConfirmationService,
    { provide: API_BASE_URL, useFactory: getApiBaseUrl },
    { provide: ZoneAddedHours, useFactory: getZoneAddedHours },
    { provide: RefreshTokenTime, useFactory: getRefreshTokenTime },
    
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    BnNgIdleService,
    LoaderService,
    UsersServiceProxy
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


