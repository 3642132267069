import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserModel } from './interfaces/auth/user-model';
import { PermissionsService } from './services/api_call/permissions.service';

@Injectable({
    providedIn: 'root'
})
export class EmployeeGuard  {
    constructor(private router: Router, private permissionService: PermissionsService) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        var user = JSON.parse(localStorage.getItem("user")) as UserModel;
        let url = state.url;
        if (user) {
            if (url.startsWith('/employee/employee-view') &&
                this.permissionService.canViewEmployee()) {
                return true;
            }
            else if (url.startsWith('/employee/employee-details') &&
                this.permissionService.canViewEmployeeDetails()) {
                return true;
            }
            else if (url.startsWith('/employee/add-employee') &&
                this.permissionService.canAddEmployee()) {
                return true;
            }
            else if (url.startsWith('/employee/edit-employee') &&
                this.permissionService.canEditEmployee()) {
                return true;
            }
            this.router.navigateByUrl('/notAuth/not-auth');
            return false;
        } else {
            this.router.navigateByUrl('/notAuth/not-auth');
            return false;
        }
    }

}
