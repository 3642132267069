import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserModel } from './interfaces/auth/user-model';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private router:Router){}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      var user= JSON.parse(localStorage.getItem("user")) as UserModel;
    if (user && !user.mustResetPassword){
        return true;
    }
    else if (user && user.mustResetPassword) {
      this.router.navigateByUrl('/login/reset-password');
        return false;
    }
    else {
        this.router.navigateByUrl('/login');
        return false;
      }
      
  }
  
}
