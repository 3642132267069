/* tslint:disable:max-line-length */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserModel } from './interfaces/auth/user-model';
import { PermissionsService } from './services/api_call/permissions.service';

@Injectable({
    providedIn: 'root'
})
export class GroupGuard  {
    constructor(private router: Router, private permissionService: PermissionsService) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const user = JSON.parse(localStorage.getItem('user')) as UserModel;
        const url = state.url;
        if (user) {
            if ((url.startsWith('/beneficiary-groups/groups-list')) &&
                (this.permissionService.canViewGroupsList() || this.permissionService.canDeleteGroup())) {
                return true;
            } else if ((url.startsWith('/beneficiary-groups/groups-details')) &&
                this.permissionService.canViewGroupDetails()) {
                return true;
            } else if ((url.startsWith('/beneficiary-groups/group')) &&
                this.permissionService.canAddGroup()) {
                return true;
            } else if ((url.startsWith('/beneficiary-groups/group')) &&
                this.permissionService.canEditGroup()) {
                return true;
            }
            this.router.navigateByUrl('/notAuth/not-auth');
            return false;
        }
    }
}
