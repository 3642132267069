import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseResponse } from 'src/app/shared/components/ng-models/base-response-model';
import { Observable } from 'rxjs';
import { API_BASE_URL } from 'src/Nswag/RMSBackEnd/SwaggerGenerated';

@Injectable({
  providedIn: 'root'
})
export class DiagnosisService {
  baseUrl:string;
  GetAllDiagnosisTemplatesURL:string;
  AddDiagnosisURL:string;
  GetDiagnosisURL:string;
  GetDiagnosisDetailsURL:string;
  GetDiagnosisDetailsViewURL:string;
  UpdateDiagnosisURL:string;
  UploadFilesURL:string;
  DeleteDiagnosisURL:string;
  
  GetRMSBranchesURL:string;

constructor(private http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) { 
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "https://localhost:44352";
    this.GetAllDiagnosisTemplatesURL= this.baseUrl +'/Diagnosis/get-all-diagnosis-templates/';
    this.AddDiagnosisURL= this.baseUrl +'/Diagnosis/add-diagnosis/';
    this.GetDiagnosisURL= this.baseUrl +'/Diagnosis/get-all-diagnosis/';
    this.GetDiagnosisDetailsURL= this.baseUrl +'/Diagnosis/get-diagnosis-details/';
    this.GetDiagnosisDetailsViewURL= this.baseUrl +'/Diagnosis/get-diagnosis-details-view/';
    this.UpdateDiagnosisURL= this.baseUrl +'/Diagnosis/update-diagnosis/';
    this.UploadFilesURL= this.baseUrl +'/Attachment/save-document/';
    this.DeleteDiagnosisURL= this.baseUrl +'/Diagnosis/delete-diagnosis/';
  
    this.GetRMSBranchesURL= this.baseUrl +'/ServiceBooking/get-rms-branches/';
  }

  
  GetAllDiagnosisTemplates():Observable<BaseResponse>{
    return this.http.get<BaseResponse>(this.GetAllDiagnosisTemplatesURL)
  }

  AddDiagnosis(model){
    return this.http.post(this.AddDiagnosisURL,model);
  }

  UpdateDiagnosis(model){
    return this.http.put(this.UpdateDiagnosisURL,model);
  }
  GetDiagnosis(filterModel):Observable<BaseResponse>{
    return this.http.post<BaseResponse>(this.GetDiagnosisURL,filterModel)
  }

  GetDiagnosisDetails(id):Observable<BaseResponse>{
    return this.http.get<BaseResponse>(this.GetDiagnosisDetailsURL+id)
  }
  GetDiagnosisDetailsView(id):Observable<BaseResponse>{
    return this.http.get<BaseResponse>(this.GetDiagnosisDetailsViewURL+id)
  }

  DeleteDiagnosis(id):Observable<BaseResponse>{
    return this.http.delete<BaseResponse>(this.DeleteDiagnosisURL+id)
  }
  UploadFiles(model){
    return this.http.post(this.UploadFilesURL,model);
  }
  
  GetRMSBranches():Observable<BaseResponse>{
    return this.http.get<BaseResponse>(this.GetRMSBranchesURL)
  }
}
