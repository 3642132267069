import { ModuleWithProviders, NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import { LocalizedDatePipe } from './localized-date.pipe';
import { SafePipe } from './safe.pipe';

@NgModule({
  declarations:[	LocalizedDatePipe,
      SafePipe
   ],
  imports:[CommonModule],
  exports:[LocalizedDatePipe, SafePipe]
})

export class LocalizedDateModule{
    static forRoot(): ModuleWithProviders<LocalizedDateModule> {
    return {
        ngModule: LocalizedDateModule,
        providers: [],
    };
}
}