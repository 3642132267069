// import { MapsAPILoader } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { CityDropDown, CountriesServiceProxy, CountryDropDown, DistrictDropDown, RMSBranchesServiceProxy } from 'src/Nswag/RMSBackEnd/SwaggerGenerated';
import { MapViewComponent } from '../map/map-view/map-view.component';
import { MapComponent, marker } from '../map/map.component';
import { AddressInfoModel } from './AddressInfoModel';

@Component({
  selector: 'app-generic-address',
  templateUrl: './generic-address.component.html',
  styleUrls: ['./generic-address.component.scss'],
  providers: [CountriesServiceProxy]
})
export class GenericAddressComponent implements OnInit {
  @Output() outAddressList = new EventEmitter();
  @Output() saveNewAddress = new EventEmitter();
  @Input() inAddressList: AddressInfoModel[];
  @Input() isMultiAddress: boolean = true;
  @Input() showSave:boolean = true;
  @Input() isRequired: boolean = true

  addressForm: FormGroup;
  isSubmit = false;
  address: AddressInfoModel
  addressList: AddressInfoModel[] = [];
  countries: CountryDropDown[] = [];
  cities: CityDropDown[] = [];
  distracts: DistrictDropDown[] = [];
  SelectedLocation: marker;
  selectedAddressId: any;
  showEdit= false ;
  constructor(
    private fb: FormBuilder, private countriesServices: CountriesServiceProxy, private rMSBranchesServiceProxy: RMSBranchesServiceProxy,
    // private mapsAPILoader: MapsAPILoader, 
    private modalService: NgbModal,
    public translate: TranslateService) {

  }

  get f() {
    if (this.addressForm)
      return this.addressForm.controls;
  }

  ngOnInit(): void {
    this.buildForm();
    this.loadCountries();
    this.SelectMainAddress();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.addressList = this.inAddressList;
    this.SelectMainAddress();
  }

  SelectMainAddress() {
    if (this.addressList.length > 0 && this.addressForm) {
      let isMain = this.addressList.findIndex(s => s.isMain == true);
      this.f.mainAddress.setValue(isMain == -1 ? 0 : isMain);
    }
  }

  buildForm() {
    this.addressForm = this.fb.group({
      country: [this.address?.countryId, [Validators.required]],
      city: [this.address?.cityId, [Validators.required]],
      distract: [this.address?.districtId,],
      details: [this.address?.details, [Validators.required]],
      latlng: [''],
      mainAddress: [0]
    });
  }

  loadCountries() {
    this.countriesServices.getCountriesDropdown().subscribe(
      res => {
        this.countries = res.data;
      }
    );
  }

  LoadCitiesByCountryId(countryId, cityId = null) {
    this.cities = [];
    this.f.city.setValue(null);
    this.distracts = [];
    this.f.distract.setValue(null);
    if (countryId) {
      this.countriesServices.getCitiesDropdown(countryId).subscribe(
        res => {
          this.cities = res.data;
          if (cityId != null) {
            this.f.city.setValue(cityId);
          }
        }
      );
    }
  }

  LoadDistractByCityId(cityId, districtId = null) {
    this.distracts = [];
    this.f.distract.setValue(null);
    if (cityId) {
      this.countriesServices.getDistrictsDropdown(cityId).subscribe(
        res => {
          this.distracts = res.data;
          if (districtId != null) {
            this.f.distract.setValue(districtId);
          }
        }
      );
    }
  }

  openMap() {
    const modalRef = this.modalService.open(MapComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: false });
    modalRef.result.then((result) => {
      this.SelectedLocation = result;
      this.f.latlng.setValue(this.SelectedLocation.address)
    }, (reason) => {
    });
    modalRef.componentInstance.selectedLocation = this.SelectedLocation != null ? this.SelectedLocation : {};
  }

  ShowMap(item: AddressInfoModel) {
    var location: marker = { lat: item.latitude, lng: item.longitude };
    const modalRef = this.modalService.open(MapViewComponent, { ariaLabelledBy: 'modal-basic-title', backdrop: false });
    modalRef.result.then((result) => {

    }, (reason) => {

    });
    modalRef.componentInstance.selectedLocation = location;
  }

  AddAddress() {
    if (!this.f.country.value || !this.f.city.value) {
      this.isSubmit = true;
    }
    else {
      var address: AddressInfoModel = {
        countryId: this.f.country.value,
        countryNameEn: this.countries.find(e => e.id == this.f.country.value)?.nameEn,
        countryNameAr: this.countries.find(e => e.id == this.f.country.value)?.nameAr,
        //
        cityId: this.f.city.value,
        cityNameEn: this.cities.find(e => e.id == this.f.city.value)?.nameEn,
        cityNameAr: this.cities.find(e => e.id == this.f.city.value)?.nameAr,
        //
        districtId: this.f.distract.value,
        districtNameEn: this.distracts.find(e => e.id == this.f.distract.value)?.nameEn,
        districtNameAr: this.distracts.find(e => e.id == this.f.distract.value)?.nameAr,
        //
        details: this.f.details.value,
        latitude: this.SelectedLocation != null ? this.SelectedLocation.lat : 0,
        longitude: this.SelectedLocation != null ? this.SelectedLocation.lng : 0,
        isMain: false,
      };

      this.addressList.push(address);
      if (this.addressList.length == 1) {
        this.addressList[0].isMain = true;
      }
      this.CancelEdit();
      this.SelectMainAddress();
      this.sendAddressList();
    }
  }

  EditAddress() {
    
    var address: AddressInfoModel = this.addressList[this.selectedAddressId];
    address.countryId = this.f.country.value;
    address.countryNameEn = this.countries.find(e => e.id == this.f.country.value)?.nameEn,
      address.countryNameAr = this.countries.find(e => e.id == this.f.country.value)?.nameAr,
      //
      address.cityId = this.f.city.value;
    address.cityNameEn = this.cities.find(e => e.id == this.f.city.value)?.nameEn,
      address.cityNameAr = this.cities.find(e => e.id == this.f.city.value)?.nameAr,
      //
      address.districtId = this.f.distract.value;
    address.districtNameEn = this.distracts.find(e => e.id == this.f.distract.value)?.nameEn,
      address.districtNameAr = this.distracts.find(e => e.id == this.f.distract.value)?.nameAr,
      //
      address.details = this.f.details.value;
    address.latitude = this.SelectedLocation.lat;
    address.longitude = this.SelectedLocation.lng;
    this.addressList[this.selectedAddressId] = address;
    this.CancelEdit();
    this.SelectMainAddress();
    this.sendAddressList();
    this.showEdit=false;
  }

  OnDeleteAddress(index) {
    this.addressList.splice(index, 1);
    this.sendAddressList();
  }

  CancelEdit() {
    this.buildForm(); // to clear form and make all not touched
    this.SelectedLocation = null;
    this.selectedAddressId = null;
  }

  SetAddress(i) {
    
    var address: AddressInfoModel = this.addressList[i];;
    this.cities = [];
    this.f.city.setValue(null);
    this.distracts = [];
    this.f.distract.setValue(null);
    if (address.countryId) {
      this.countriesServices.getCitiesDropdown(address.countryId).subscribe(
        res => {
          this.cities = res.data;
          this.distracts = [];
          this.f.distract.setValue(null);
          if (address.cityId) {
            this.countriesServices.getDistrictsDropdown(address.cityId).subscribe(
              res => {
                this.distracts = res.data;
                this.f.country.setValue(address.countryId);
                this.f.city.setValue(address.cityId);
                this.f.distract.setValue(address.districtId);
                this.f.details.setValue(address.details);
                this.SelectMainAddress();
                // this.getAddress(address.latitude, address.longitude);
                this.SelectedLocation = { lat: address.latitude, lng: address.longitude };
                this.selectedAddressId = i;
                this.EditAddress();
              }
            );
          }
        }
      );
    }
  }


  changeMainAddress = (index) => {
    let control = this.f.mainAddress;
    control.valueChanges.subscribe(e => {
      control.setValue(e, {emitEvent: false});
    });
    this.f.mainAddress.setValue(index);
    this.addressList.map((element, ind) => {
      if (ind == index)
        element.isMain = true;
      else
        element.isMain = false;
    });
    this.sendAddressList();
  }

  LoadEditAddress(i) {
    this.showEdit=true;
    var address: AddressInfoModel = this.addressList[i];
    this.selectedAddressId = i;
    this.f.country.setValue(address.countryId);
    this.LoadCitiesByCountryId(address.countryId);
    this.f.city.setValue(address.cityId);
    this.LoadDistractByCityId(address.cityId);
    this.f.distract.setValue(address.districtId);
    this.f.details.setValue(address.details);
    this.SelectMainAddress();
    // this.getAddress(address.latitude, address.longitude);

    this.SelectedLocation = { lat: address.latitude, lng: address.longitude };
  }

  getAddress(lat, lng) {
    // this.mapsAPILoader.load().then(() => {
    //   let geocoder = new google.maps.Geocoder;
    //   let latlng = {
    //     lat: lat,
    //     lng: lng
    //   };
    //   geocoder.geocode({
    //     'location': latlng
    //   }, function (results) {
    //     if (results && results[0]) {
    //       var address = results[0].formatted_address;
    //       this.f.latlng.setValue(address);
    //     }
    //   });
    // })
  }

  sendAddressList() {
    this.outAddressList.emit(this.addressList);
  }
  saveAddress(){
    if (!this.f.country.value || !this.f.city.value ) {
      this.isSubmit = true;
    }
    else {
      var address: AddressInfoModel = {
        countryId: this.f.country.value,
        countryNameEn:this.countries.find(e => e.id == this.f.country.value)?.nameEn,
        countryNameAr:this.countries.find(e => e.id == this.f.country.value)?.nameAr,
        //
        cityId: this.f.city.value,
        cityNameEn:this.cities.find(e => e.id == this.f.city.value)?.nameEn,
        cityNameAr:this.cities.find(e => e.id == this.f.city.value)?.nameAr,
        //
        districtId: this.f.distract.value,
        districtNameEn:this.distracts.find(e => e.id == this.f.distract.value)?.nameEn,
        districtNameAr:this.distracts.find(e => e.id == this.f.distract.value)?.nameAr,
        //
        details: this.f.details.value,
        latitude: this.SelectedLocation != null ? this.SelectedLocation.lat : 0,
        longitude: this.SelectedLocation != null ? this.SelectedLocation.lng : 0,
        isMain: false,
      };
    this.saveNewAddress.emit(address)

    }
  }
}
