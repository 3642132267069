import { Component, OnInit } from '@angular/core';
import { NavBarService } from 'src/app/core/services/nav-bar.service';
import { SelectItem, Message, ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { ExportationService } from 'src/app/core/services/api_call/exportation.service';
import { ThirdPartyNoticeGrid } from 'src/app/core/interfaces/third-party-notice/thirdPartyNoticeGrid';
import { DiagnosisService } from 'src/app/core/services/api_call/diagnosis.service';
import { DiagnosisTemplate } from 'src/app/core/interfaces/diagnosis/diagnosisTemplate';
import { Lookups } from 'src/app/core/interfaces/Lookups/Lookups';
import { TranslateService } from '@ngx-translate/core';
import { LookupService } from 'src/app/core/services/api_call/Lookup.service';
import { LookupEnum } from 'src/app/core/enum/LookupEnum.enum';
import { LookupByKeysModel } from 'src/app/core/interfaces/Lookups/lookup-by-keys-model';

@Component({
  selector: 'app-diagnosis-view',
  templateUrl: './diagnosis-view.component.html',
  styleUrls: ['./diagnosis-view.component.scss'],
  providers: [ConfirmationService]
})
export class DiagnosisViewComponent implements OnInit {
  filter = true;
  dumPar= [{}];
  sortOptions: SelectItem[];
  columns: SelectItem[];
  Export: SelectItem[];
  sortKey: string;
  selectedColumns: any[]
  sortField: string;
  msgs: Message[] = [];
  status: SelectItem[];
  sortOrder: number;
  first = 0;
  branches: SelectItem[] = [];
  rows = 10;
  ChildCategories: Lookups[] = [];
  ChildStatus:Lookups[]=[];
  Diagnosis: any[] = [];
  totalRec=0;
  from
  to

  isLoading = false;
  DiagnosisTemplateList: DiagnosisTemplate[] = [];
  selectedTemplates:any;
  RMSBranches:[]=[];
  baseLookups : any[];
  filterModel={
    DiagnosisId:'',
    ChildName:'',
    ChildNo:'',
    DiagnosisDate:'',
    TemplateName:[],
    Categories:[],
    Branches:[],
    Status:[],
    PageSize:10,
    PageNumber:0
  }
  valueLang="nameEn";
  valueTempLang="templateName1";

  constructor(public nav: NavBarService,private confirmationService: ConfirmationService,
    private exportationService: ExportationService,private service:DiagnosisService,public translate:TranslateService,
    private lookupsService: LookupService) {
      this.columns = [
        { label: translate.instant('STAKEHOLDER_CHILD.BRANCH') , value: 5 },
        { label: translate.instant('STAKEHOLDER_CHILD.STATUS'), value: 6 },
      ];
      let checkColumns = [

      ]
      // Select all items
      this.selectedColumns = [];
      checkColumns.map((item) => this.selectedColumns.push(item.value));
    }

    loadLookups(){
      let lookupKeys: LookupByKeysModel;
      lookupKeys = {
        key : [LookupEnum.CHILDCATEGORY,LookupEnum.STATUS],
        entity : null
      }
      this.lookupsService.getLookupListByKey(lookupKeys).subscribe( res => {
        if (res.isSuccess){
          this.baseLookups = res.data as any[];
          this.ChildCategories = this.baseLookups.filter(e => e.key == LookupEnum.CHILDCATEGORY)[0].data;
          this.ChildStatus = this.baseLookups.filter(e => e.key == LookupEnum.STATUS)[0].data;
        }
      })
    }
  ngOnInit(): void {
    this.valueLang= this.translate.currentLang=='en-US'?'nameEn':'nameAr';
    this.valueTempLang= this.translate.currentLang=='en-US'?'templateName1':'templateName2';
    this.isLoading = true;
    
    this.loadDiagnosis(false);
    this.loadTemplates();
    this.loadBranches();
    this.loadLookups();
  }
  loadDiagnosis(isLazyLoading=false) {
    //reset skip on filter
    if(!isLazyLoading){
      this.filterModel.PageNumber = 0;
    }
    this.service.GetDiagnosis(this.filterModel).subscribe(
      res=>{
        this.Diagnosis=res.data;
        this.totalRec=res.count;

        this.from = this.filterModel.PageNumber + 1
        this.to = this.filterModel.PageNumber + this.filterModel.PageSize
        if (this.to > this.totalRec){
          this.to = this.totalRec;
        }
        this.isLoading = false;
      },error=>{
        
      }
    )
  }
  loadParentsLazy(event: LazyLoadEvent) {
    this.filterModel.PageNumber = event.first;
    this.filterModel.PageSize = event.rows;
    this.loadDiagnosis(true);
  }
  loadTemplates() {
    this.service.GetAllDiagnosisTemplates().subscribe(
      res=>{
        this.DiagnosisTemplateList=res.data;
      },error=>{
        
      }
    )
  }

  loadBranches() {
    this.service.GetRMSBranches().subscribe(
      res=>{
        this.RMSBranches=res.data;
      },error=>{
        
      }
    )
  }


  closeFilter() {
    this.filter = true;

  }

  openFiltration() {
    this.filter = false;
  }

  exportCSV() {
    this.exportationService.exportCSV(this.Diagnosis, 'Diagnosis-csv',
    ['diagnosisID','childName1','childName2','childNumber','branchNameAr','branchNameEn', 'diagnosisDate', 'templateName1', 'templateName2','childCategoryEn','childCategoryAr'],[]);
  }

  exportExcel() {
    let toExport = this.Diagnosis;
    let headerLabels = this.translate.currentLang == 'en-US' ?
    ['DiagnosisID', 'English Full Name', 'Branch Name', 'Diagnosis Date', 'Template Name', 'Child Category'] :
    ['رقم الطفل', 'الاسم', 'الفرع', 'التاريخ', 'القالب', 'الفئة '];

    for (const item of toExport) {
        item['attachments'].length ==0?delete item['attachments']:'';
        delete item['childId']
        delete item['childNo']
        delete item['childStatusId']
        delete item['diagnosisID']
        delete item['templateId']
        delete item['id']
        delete item['childCategoryId']
        delete item['childBranch']
        item['questions'].length==0?delete item['questions']:''
    }
    this.exportationService.exportExcel(toExport  , "Diagnosis-excel",headerLabels);
  }

  exportPdf(){

  }

  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.first === 0;
  }

  isFirstPage(): boolean {
    return this.first === 0;
  }

  confirm(id) {
    this.confirmationService.confirm({
      key: 'account',
      message: this.translate.currentLang=='en-US'? 'Are you sure that you want to delete this diagnosis?':'هل انت متأكد انك تريد حذف هذا التشخيص؟',
      header: this.translate.currentLang=='en-US'?'Confirmation':'تأكيد',
      rejectLabel:this.translate.currentLang=='en-US'?'No':'لا',
      acceptLabel:this.translate.currentLang=='en-US'?'Yes':'نعم',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.service.DeleteDiagnosis(id).subscribe(
          res=>{
            
            this.loadDiagnosis();
          },error=>{
            
          }
        )
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'You have accepted' }];
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }

  isColumnShortlisted(value){
    return this.selectedColumns.filter(i => i == value).length > 0 ? true: false;
  }

  clearFilter(){
    this.filterModel={
      Branches:null,
      Categories:null,
      ChildName:null,
      ChildNo:null,
      DiagnosisDate:null,
      DiagnosisId:null,
      PageNumber:0,
      PageSize:10,
      Status:null,
      TemplateName:null
    }
    this.loadDiagnosis(true);
  }

}
