<div class="container p-0">
    <div class="wrapper">
        <ul class="breadcrumb">
            <li class="breadcrumb-item">
                <a [routerLink]="['/home']">{{'BREADCRUMB.HOME' | translate }}
                </a>
            </li>
            <li><i class="fas fa-chevron-right"></i></li>
            <li class="breadcrumb-item">
                <a>
                    {{'BREADCRUMB.REGISTRATION' | translate }}
                </a>

            </li>
            <li><i class="fas fa-chevron-right"></i></li>
            <li class="breadcrumb-item">
                <a>
                    {{'BREADCRUMB.DIAGNOSIS' | translate }}
                </a>
            </li>

        </ul>
    </div>
    <div class="wrapper header-box">
        <div class="parent">
            <div class="child">
                <span class="title">{{ 'VIEW_DIAGNOSIS.DIAGNOSIS' | translate }}</span>
            </div>

            <div class="child">
                <button class="button-2" [routerLink]="['/diagnosis/add-diagnosis']">
                    <span>
                        <mat-icon>add</mat-icon>
                    </span>
                    <span>{{ 'VIEW_DIAGNOSIS.ADD_DIAGNOSIS' | translate }}</span>
                </button>
            </div>
        </div>
    </div>
    <div class="filter" *ngIf="filter">
        <span class="filter-sapn" (click)="openFiltration()">{{ 'ThirdPartyNotice.FILTER' | translate }}</span>
        <span class="filter-img" (click)="openFiltration()"><img src="assets/images/icons/filter.svg" alt=""></span>
    </div>
    <div class="filter-transparent" *ngIf="!filter">
        <span class="filter-sapn" (click)="openFiltration()"></span>
        <span class="filter-img" (click)="openFiltration()"></span>
    </div>
    <div class="wrapper-tabel row" style="margin-top: -5rem; padding-right: 15px;
    padding-left: 15px;">
        <div class="col-lg-3 mt-3" *ngIf="!filter">
            <div class="box ">
                <div class="col-12 p-0 text-right">
                    <img src="assets/images/icons/x.svg" alt="" style="cursor: pointer;" (click)="closeFilter()">
                </div>

                <p>
                    <label for="float-input">{{ 'VIEW_DIAGNOSIS.DIAGNOSISID' | translate }}</label>
                    <input id="float-input" type="number" min="1" size="30" [(ngModel)]="filterModel.DiagnosisId">
                </p>
                <p>
                    <label for="float-input">{{ 'ThirdPartyNotice.CHILDNAME' | translate }}</label>
                    <input id="float-input" type="text" size="30" [(ngModel)]="filterModel.ChildName">
                </p>
                <p>
                    <label for="float-input">{{ 'ThirdPartyNotice.CHILDNO' | translate }}</label>
                    <input id="float-input" type="text" size="30" [(ngModel)]="filterModel.ChildNo">
                </p>
                <p>
                    <label for="float-input">{{ "STAKEHOLDER_CHILD.BRANCHES" | translate }}
                    </label>
                    <ng-select [items]="RMSBranches" [multiple]="true" bindLabel="{{valueLang}}" [closeOnSelect]="false"
                        bindValue="id" [(ngModel)]="filterModel.Branches">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
                            {{translate.currentLang=='en-US'? item.nameEn:item.nameAr}}
                        </ng-template>
                    </ng-select>
                </p>

                <p>
                    <label for="float-input">{{ "STAKEHOLDER_CHILD.CATEGORY" | translate }}
                    </label>
                    <ng-select [items]="ChildCategories" [multiple]="true" bindLabel="{{valueLang}}"
                        [closeOnSelect]="false" bindValue="id" [(ngModel)]="filterModel.Categories">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
                            {{translate.currentLang=='en-US'? item.nameEn:item.nameAr}}
                        </ng-template>
                    </ng-select>
                </p>
                <p>
                    <label for="float-input">{{ "STAKEHOLDER_CHILD.STATUS" | translate }}
                    </label>

                    <ng-select [items]="ChildStatus" [multiple]="true" bindLabel="{{valueLang}}" [closeOnSelect]="false"
                        bindValue="id" [(ngModel)]="filterModel.Status">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
                            {{translate.currentLang=='en-US'? item.nameEn:item.nameAr}}
                        </ng-template>
                    </ng-select>
                </p>
                <p>
                    <label for="float-input">{{ 'VIEW_DIAGNOSIS.DIAGNOSISDATE' | translate }}</label>
                    <input id="date" name="date" type="date" placeholder="Third-Party-Notice Date"
                        class="form-control input-md" [(ngModel)]="filterModel.DiagnosisDate" />
                </p>

                <p>
                    <label for="float-input">{{ "VIEW_DIAGNOSIS.DIAGNOSISTEMPLATES" | translate }}
                    </label>
                    <ng-select [items]="DiagnosisTemplateList" [multiple]="true" bindLabel="{{valueTempLang}}"
                        [closeOnSelect]="false" bindValue="id" [(ngModel)]="filterModel.TemplateName">
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
                            {{translate.currentLang=='en-US'? item.templateName1:item.templateName2}}
                        </ng-template>
                    </ng-select>

                </p>

                <div class="col-12 p-0">
                    <button class="button" style="width: 100%;" type="button" (click)="loadDiagnosis(false)">{{
                        'ThirdPartyNotice.APPLY' | translate }}</button>
                </div>
                <div class="col-12 p-0 mt-2">
                    <button class="btn btn-danger" style="width: 100%;" (click)="clearFilter()">
                        {{ "STAKEHOLDER_CHILD.CLEAR" | translate }}
                    </button>
                </div>

            </div>
        </div>
        <div class="col-md-12 grid" [ngClass]="{ 'col-lg-9  ': !filter }">
            <div class="col-12  pr-0" [ngClass]=" { ' pl-3  pr-0': !filter} ">
                <div class="content-section implementation ">
                    <p-dataView #dv [value]="dumPar" [paginator]="false" [rowsPerPageOptions]="[10,20,30]" [rows]="20"
                        [rows]="rows" [showCurrentPageReport]="true" [(first)]="first"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        paginatorPosition="bottom" [showCurrentPageReport]="true" filterBy="brand"
                        [sortField]="sortField" [sortOrder]="sortOrder">
                        <p-header>
                            <div class="ui-helper-clearfix">
                                <div class="row  ">
                                    <div class="col-12 text-right direction-rtl">
                                        <ul class="toolbar-grid custome-pos-toolbar-grid">

                                            <li>
                                                <div class="child mt-2 mb-2 direction-ltr">
                                                    <p-multiSelect [options]="columns"
                                                        [style]="{'max-width':'120px','min-width':'180px'}"
                                                        defaultLabel="{{ 'GENERIC.COLUMNS' | translate }}"
                                                        maxSelectedLabels="3" [(ngModel)]="selectedColumns"
                                                        [filter]="false"
                                                        dropdownIcon="pi pi-caret-down"></p-multiSelect>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="child mt-2 mb-2">
                                                    <div ngbDropdown class="d-inline-block">
                                                        <button class="btn exportBTN" id="dropdownMenuButton"
                                                            ngbDropdownToggle>
                                                            {{'GENERIC.EXPORT' | translate}} </button>
                                                        <div ngbDropdownMenu aria-labelledby="dropdownMenuButton">
                                                            <button ngbDropdownItem (click)="exportExcel()">
                                                                {{'GENERIC.EXPORTEXCEL' | translate}}</button>
                                                            <button ngbDropdownItem (click)="exportPdf()">
                                                                {{'GENERIC.EXPORTPDF' | translate}}</button>
                                                            <button ngbDropdownItem
                                                                (click)="exportCSV()">{{'GENERIC.EXPORTEXCELLCSV' |
                                                                translate}} </button>


                                                        </div>
                                                    </div>

                                                </div>
                                            </li>
                                            <li>
                                            </li>
                                            <li>
                                                <span class="grid-count"></span>
                                            </li>

                                            <li>
                                                <div class="direction-ltr custom-grid-count">
                                                    <span class="grid-count"> {{from}} - {{to}}</span>
                                                    <span class="grid-count"> of {{totalRec}} </span>
                                                </div>
                                            </li>

                                        </ul>


                                    </div>
                                    <div class="col-6 text-left direction-ltr" style="position: relative;
                                    top: -45px;">
                                        <ul class="toolbar-grid">
                                            <li>{{'GENERIC.SHOW' | translate}}</li>
                                            <li class="ml-2" style="width: 70px;"></li>
                                            <li>{{'GENERIC.ITEMS' | translate}}</li>
                                        </ul>
                                    </div>

                                </div>

                            </div>
                        </p-header>

                        <ng-template let-child pTemplate="listItem">

                            <p-table [value]="Diagnosis" [resizableColumns]="true" *ngIf="Diagnosis?.length > 0"
                                [lazy]="true" [paginator]="true" [showCurrentPageReport]="true"
                                [(rows)]="filterModel.PageSize" (onLazyLoad)="loadParentsLazy($event)"
                                [lazyLoadOnInit]="false" [(totalRecords)]="totalRec"
                                [rowsPerPageOptions]="[10,20,30,50]">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th>
                                            {{ 'VIEW_DIAGNOSIS.ID' | translate }}</th>
                                        <th>
                                            {{ 'VIEW_DIAGNOSIS.CHILDNAME' | translate }}</th>
                                        <th>
                                            {{ 'VIEW_DIAGNOSIS.DIAGNOSISDATE' | translate }}</th>
                                        <th>
                                            {{ 'VIEW_DIAGNOSIS.DIAGNOSISTEMPLATES' | translate }}</th>
                                        <th>{{ 'VIEW_DIAGNOSIS.CHILDCATEGORY' | translate }} </th>

                                        <th *ngIf="selectedColumns && isColumnShortlisted(5)">
                                            {{ 'VIEW_DIAGNOSIS.DIAGNOSISBRANCH' | translate }}</th>
                                        <th *ngIf="selectedColumns && isColumnShortlisted(6)">
                                            {{ 'CHILD.STATUS' | translate }}</th>

                                        <th style="width:8em;">{{ 'STAKEHOLDER_PARENT.ACTIONS' | translate }}</th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-Diagnosis let-ri="rowIndex">
                                    <tr [pEditableRow]="Diagnosis">
                                        <td>#{{Diagnosis?.diagnosisID}}</td>
                                        <td>{{translate.currentLang=='en-US'? Diagnosis?.childName1 :
                                            Diagnosis?.childName2}}</td>
                                        <td>
                                            {{Diagnosis?.diagnosisDate | date}}
                                        </td>
                                        <td>
                                            {{translate.currentLang=='en-US'?Diagnosis?.templateName1:Diagnosis?.templateName2}}
                                        </td>
                                        <td>{{translate.currentLang=='en-US'?
                                            Diagnosis?.childCategoryEn:Diagnosis?.childCategoryAr}}</td>
                                        <td *ngIf="selectedColumns && isColumnShortlisted(5)">
                                            {{translate.currentLang=='en-US'?Diagnosis?.branchNameEn:Diagnosis?.branchNameAr}}
                                        </td>
                                        <td *ngIf="selectedColumns && isColumnShortlisted(6)">
                                            <span> {{ this.translate.currentLang === 'en-US' ? Diagnosis?.statusNameEn :
                                                Diagnosis?.statusNameAr }}</span>
                                        </td>
                                        <td class="actions text-right-s">
                                            <a [routerLink]="['/diagnosis/diagnosis-details',Diagnosis?.id]">
                                                <img src="assets/images/child/eye.svg" alt="">
                                            </a>
                                            <a [routerLink]="['/diagnosis/edit-diagnosis',Diagnosis?.id]" class="ml-2">
                                                <img src="assets/images/child/edit.svg" alt="">
                                            </a>
                                            <a (click)="confirm(Diagnosis?.id)" class="ml-2">
                                                <img src="assets/images/child/trash.svg" alt="">
                                            </a>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>

                            <div class="row mt-30 width-100" *ngIf="Diagnosis==null">
                                <div class="col-12  table-responsive  child-detail__table">
                                    <table class="table table-striped">
                                        <tr>
                                            <th>
                                                {{ 'VIEW_DIAGNOSIS.ID' | translate }}</th>
                                            <th>
                                                {{ 'VIEW_DIAGNOSIS.CHILDNAME' | translate }}</th>
                                            <th>
                                                {{ 'VIEW_DIAGNOSIS.DIAGNOSISDATE' | translate }}</th>
                                            <th>
                                                {{ 'VIEW_DIAGNOSIS.DIAGNOSISTEMPLATES' | translate }}</th>
                                            <th>{{ 'VIEW_DIAGNOSIS.CHILDCATEGORY' | translate }} </th>



                                            <th style="width:8em;">{{ 'STAKEHOLDER_PARENT.ACTIONS' | translate }}</th>

                                        </tr>
                                    </table>
                                </div>
                                <div class="col-12 text-center mt-30">{{ 'VIEW_DIAGNOSIS.NODIAGNOSIS' | translate }}
                                </div>
                            </div>
                        </ng-template>
                    </p-dataView>
                    <p-confirmDialog [style]="{width: '50vw'}" key="account"></p-confirmDialog>
                </div>
            </div>
        </div>

    </div>
</div>