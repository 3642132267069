import { Component, OnInit, ViewChild, NgZone, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { MapsAPILoader, AgmMarker } from '@agm/core';
import { TranslateService } from '@ngx-translate/core';

export interface marker {
  lat?: number;
  lng?: number;
  label?: string;
  draggable?: boolean;
  content?: string;
  isShown?: boolean;
  address?: any;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @Input() selectedLocation: marker = {};
  zoom: number;
  address: string;
  private geoCoder;

  // Radius
  radius = 50000;
  radiusLat = 0;
  radiusLong = 0;
  center
  currentLocation: any;
  markers: marker[] = []

  constructor(
    // private mapsAPILoader: MapsAPILoader, 
    public activeModal: NgbActiveModal, public translate: TranslateService) {
  }

  ngOnInit() {
    //load Map
    // this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
    // });
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.selectedLocation.lat = this.selectedLocation.lat != null ? this.selectedLocation.lat : position.coords.latitude;
        this.selectedLocation.lng = this.selectedLocation.lng != null ? this.selectedLocation.lng : position.coords.longitude;
        this.center = new google.maps.LatLng({
          lat: this.selectedLocation.lat,
          lng: this.selectedLocation.lng
        })
        this.zoom = 16;
        this.markers.push({
          lat: this.selectedLocation.lat,
          lng: this.selectedLocation.lng,
          label: `current Location`,
          draggable: true,
          content: `Content no`,
          isShown: true,
          //icon:''
        })
      }, (error) => {
        var allowMessage = this.translate.currentLang == 'en-US' ? 'Enable Location Service for this Site.':
        ' برجاء تمكين خدمة الموقع. '
        alert(allowMessage);
      });
    }
  }
  confirmLocation() {
    var lat = this.center.lat;
    var lng = this.center.lng;
    var model = this.activeModal;
    // this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder;
      let latlng = {
        lat: lat,
        lng: lng
      };
      // geocoder.geocode({
      //   'location': latlng
      // }, function (results) {
      //   if (results[0]) {
      //     var currentLocation = results[0].formatted_address;
      //     var selectedLocation = { lat: lat, lng: lng, address: currentLocation };
      //     model.close(selectedLocation);
      //   } else {
          
      //     var selectedLocation = { lat: lat, lng: lng, address: 'Unknown' };
      //   }
      // });
    // })
  }
  clickedMarker(m: marker, index: number) {
  }

  getAddress(lat, lng) {
    // this.mapsAPILoader.load().then(() => {
      let geocoder = new google.maps.Geocoder;
      let latlng = {
        lat: lat,
        lng: lng
      };
    //   geocoder.geocode({
    //     'location': latlng
    //   }, function (results) {
    //     if (results[0]) {
    //       this.currentLocation = results[0].formatted_address;
    //     } else {
          
    //     }
    //   });
    // })
  }
  markerDragEnd($event: any) {
    this.selectedLocation.lat = $event.latLng.lat();
    this.selectedLocation.lng = $event.latLng.lng();
  }

  event(type, $event) {
    this.radius = $event;
    this.showHideMarkers();
  }

  showHideMarkers() {
    // Object.values(this.markers).forEach(value => {
    //   value.isShown = this.getDistanceBetween(value.lat, value.lng, this.radiusLat, this.radiusLong);
    // });
  }

  getDistanceBetween(lat1, long1, lat2, long2) {
    // var from = new google.maps.LatLng(lat1, long1);
    // var to = new google.maps.LatLng(lat2, long2);

    // if (google.maps.geometry.spherical.computeDistanceBetween(from, to) <= this.radius) {
    //   return true;
    // } else {
    //   return false;
    // }
  }
}
