import { Injectable } from '@angular/core';

export interface AppConfig {
  DefaultLan: string;
  ENVIRONMENTS_URLS: {};
  REPORTS_URLS:{};
  REPORTS_VIEWR:{};
  BASE_ENVIRONMENT:string;
  ZoneAddedHours: number;
  RefreshTokenTime: number
}
@Injectable({ providedIn: 'root' })
export class AppConfigService<T extends AppConfig = AppConfig> {
  static appConfig: AppConfig;

  constructor() { }

  static loadConfig(): Promise<void> {
    return new Promise((resolve, reject) => {
      const oReq = new XMLHttpRequest();
      oReq.addEventListener('load', (resp) => {
        if (oReq.status === 200) {
          try {
            AppConfigService.appConfig = JSON.parse(oReq.responseText);
          } catch (e) {
            reject(e);
          }
          resolve();
        } else {
          reject(oReq.statusText);
        }
      });

      oReq.open('GET', './assets/configs/config.json');
      oReq.send();
    });
  }

  getConfig(): T {
    return AppConfigService.appConfig as T;
  }
  
}
export function getApiBaseUrl(): string {
  let url = AppConfigService.appConfig.ENVIRONMENTS_URLS[AppConfigService.appConfig.BASE_ENVIRONMENT];
  return url;
}
export function getZoneAddedHours() : number{
  return AppConfigService.appConfig.ZoneAddedHours;
}

export function getRefreshTokenTime() : number{
  return AppConfigService.appConfig.RefreshTokenTime;
}

export function getReportBaseUrl(): string {
  let url = AppConfigService.appConfig.REPORTS_URLS[AppConfigService.appConfig.BASE_ENVIRONMENT];
  return url;
}

export function getReportViewrUrl(): string {
  let url = AppConfigService.appConfig.REPORTS_VIEWR[AppConfigService.appConfig.BASE_ENVIRONMENT];
  return url;
}