import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NavBarService } from 'src/app/core/services/nav-bar.service';
import { UserModel } from 'src/app/core/interfaces/auth/user-model';
import { AuthService } from 'src/app/core/services/api_call/auth.service';
declare var jQuery: any;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  // Icons
  faChevronDown = faChevronDown;

  userItems: MenuItem[];
  languagesItems: MenuItem[];
  notificationsItems: MenuItem[];
  currentUser: UserModel;

  constructor(
    public translate: TranslateService,
    public nav: NavBarService,
    private authService: AuthService
  ) {
    this.currentUser = JSON.parse(localStorage.getItem('user')) as UserModel;
    translate.addLangs(['en-US', 'ar-SA']);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/ar|ar-SA/) ? 'ar-SA' : 'en-US');
  }

  ngOnInit(): void {
    this.languageHandler();

    this.currentUser = JSON.parse(localStorage.getItem('user')) as UserModel;

    this.userItems = [
      // {
      //   label:
      //     this.translate.currentLang == 'en-US' ? 'Profile' : 'الملف الشخصي',
      //   url: '/login',
      //   icon: 'pi pi-fw pi-user',
      //   command: () => this.languageSwitcher('ar-SA'),
      // },
      {
        label:
          this.translate.currentLang == 'en-US' ? 'logout' : 'تسجيل الخروج',

        icon: 'pi pi-fw pi-sign-out',
        command: () => this.logout(),
      },
    ];

    this.languagesItems = [
      {
        label: 'عربى',
        icon: 'flag-icon flag-icon-sa',
        command: () => {
          this.languageSwitcher('ar-SA')
        },
        styleClass: this.translate.currentLang === 'ar-SA' ? 'active' : '',
      },
      {
        label: 'English',
        icon: 'flag-icon flag-icon-us',
        command: () => this.languageSwitcher('en-US'),
        styleClass: this.translate.currentLang === 'en-US' ? 'active' : '',
      },
    ];

    this.notificationsItems = [];

    this.authService.currentUserEvent.subscribe((userSingal: UserModel) => {
      this.currentUser = userSingal;
    });
  }
  logout(): void {
    this.authService.logout();
    localStorage.removeItem("menu")
  }

  languageSwitcher(language): void {
    // this.translate.use(language);
    this.userItems = [];
    this.userItems = [
      // {
      //   label:
      //     this.translate.currentLang == 'en-US' ? 'Profile' : 'الملف الشخصي',
      //   url: '/login',
      //   icon: 'pi pi-fw pi-user',
      // },
      {
        label:
          this.translate.currentLang == 'en-US' ? 'logout' : 'تسجيل الخروج',

        icon: 'pi pi-fw pi-sign-out',
        command: () => this.logout(),
      },
    ];
    if(language != localStorage.getItem('language')){
      this.translate.use(language).subscribe(lang => {
        localStorage.setItem('language', language);
        this.authService.switchLanguage(language)
        .subscribe((res) => {
          this.authService.currentLanguageEvent.emit(language);
        });
      });
    }
  }

  languageHandler(): void {
    let localLang = localStorage.getItem('language');
    if (localLang) {
      this.translate.use(localLang);
      this.translate.currentLang = localLang;
      this.authService?.switchLanguage(localLang)
      .subscribe((res) => {});
    } else {
      this.translate.use('ar-SA');
      this.authService.switchLanguage('ar-SA')
      .subscribe((res) => {});
    }
  }

  userMenuToggler(event) { }
}
