import { BaseResponse } from './base-response-model';

export class Mapper {
    static responseMapper(res: any): BaseResponse {
        var response: BaseResponse;
        response = {
            data : res.data,
            error : res.error,
            message : res.message,
            isSuccess : res.isSuccess,
            count: res.count,
            statusCode: res.statusCode
        }    
        return response;
    }
}