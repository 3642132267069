
<nav class="policyNav justify-content-center align-items-center  d-lg-flex">
  <div class="text-center">
    <div class="logo ">
      <img class="animate__animated animate__backInDown" src="assets/images/navBar/logo.png" alt="LOGO" style="cursor: pointer;">
    </div>
  </div>

</nav>

<div class="wrapper Contract mt-30">
  <div class="container p-0">

    <div class="col-12 p-0 header-box">
      <div class="parent">
        <div class="child">
          <span class="title" >Privacy Policy</span>
        </div>
      </div>
    </div>
    <div class="box">
      <mat-accordion>
        <mat-expansion-panel (opened)="true" (closed)="false"
                             [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="color-blue">
              1.	Introduction about the App

            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row company ">
            Rehadox app is a mobile application that offers its services for parents how only were registered in the Rehadox management system
            The Parent Portal is designed to allow parents easy access to their children’s records. The app gathers information directly from the Rehadox management system,

          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="box">
      <mat-accordion>
        <mat-expansion-panel (opened)="true" (closed)="false"
                             [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="color-blue">
              2.	Types of Information We Collect

            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row company ">
            Rehadox app does not collect any type of information, as our application currently offer services for users already registered through our website
            <a href="www.rehadox.com">www.rehadox.com</a>
            Users of the application are required to activate their account by providing the identity and mobile number that match an active profile, then verify the identity of the registrar.

          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="box">
      <mat-accordion>
        <mat-expansion-panel (opened)="true" (closed)="false"
                             [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="color-blue">
              3.	How We Collect and Use Information

            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row company ">
            As mentioned in the previous section, no actual collection of information is taking place, as the collected
            information is only used to active the account or authenticate the user.
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="box">
      <mat-accordion>
        <mat-expansion-panel (opened)="true" (closed)="false"
                             [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="color-blue">
              4.	Release of Information

            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row company ">
            Sugg 1 All information displayed and used in the application are only used by HDC and its affiliates.
            Sugg 2 Occasionally we may be required by law enforcement or judicial authorities to provide PII to the
            appropriate governmental authorities. In such cases, we will disclose PII upon receipt of a court order,
            subpoena, or to cooperate with a law enforcement investigation

          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="box">
      <mat-accordion>
        <mat-expansion-panel (opened)="true" (closed)="false"
                             [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="color-blue">
              5.	Updating and Correcting Information

            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row company ">
            Currently, the application does not support updating or correcting users’ information. All these functions
            are offered through our offices.

          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="box">
      <mat-accordion>
        <mat-expansion-panel (opened)="true" (closed)="false"
                             [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="color-blue">
              6.	User Choices on Collection and Use of Information

            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row company ">
            For operational purposes, users are required to share a minimum amount of information, including identities,
            personal, contact and address information to complete their profiles, but all these information are not
            collected through the application for now.

          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="box">
      <mat-accordion>
        <mat-expansion-panel (opened)="true" (closed)="false"
                             [expanded]="false">
          <mat-expansion-panel-header>
            <mat-panel-title class="color-blue">
              7.	Security of Your Personal Identification Information

            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row company ">
            All information are stored in a highly secured repositories, and always transferred through secured channels (SSL)

          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

  </div>
</div>
