import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dynamic-form-builder',
  template:`
    <form (ngSubmit)="onSubmit.emit(this.form.value)" [formGroup]="form" class="form-horizontal">
      
      <div *ngFor="let item of groupItems| keyvalue">
        <div class="row">
            <div class="col-3">
                <p class="child-detail__header">اسم المجموعه</p>
                <p class="child-detail__Data">{{item.key}}</p>
            </div>
        </div>
        <hr>
        <div *ngFor="let field of item.value">
            <field-builder [field]="field" [form]="form"></field-builder>
        </div>
      </div>
      <hr>
      <div class="form-row"></div>
      <div class="form-group row">
        <div class="col-md-3"></div>
        <!--
        <div class="col-md-9">
          <button type="submit" [disabled]="!form.valid" class="btn btn-primary">Save</button>
          <strong >Saved all values</strong>
        </div>
        -->
      </div>
    </form>
  `,
})
export class DynamicFormBuilderComponent implements OnInit {
  @Output() onSubmit = new EventEmitter();
  @Input() fields: any[] = [];
  public form: FormGroup;

  groupItems:any[]=[];
  constructor() { }
  LoadFields(){
    const groupBy = (array, key) => {
      // Return the end result
      return array.reduce((result, currentValue) => {
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        );
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result;
      }, {}); // empty object is the initial value for result object
    };
    this.groupItems=groupBy(this.fields,'formGroup');
    let fieldsCtrls = {};
    Object.keys(this.groupItems).map(key=>{
      var value=this.groupItems[key];
      

      for (let f of value) {
        if (f.type != 'checkbox') {
          fieldsCtrls[f.name] = new FormControl(f.value || '',f.required==true? Validators.required : null)
        } else {
          let opts = {};
          for (let opt of f.options) {
            opts[opt.key] = new FormControl(opt.value);
          }
          fieldsCtrls[f.name] = new FormGroup(opts)
        }
      }
    })
    this.form = new FormGroup(fieldsCtrls);

  }
  ngOnInit() {
    this.LoadFields();
  }

}
