<div class="container m-0 p-0 container-login ">
    <div class="row">
        <div class="col-lg-6 login-backGround"></div>
        <div class="col-lg-6  form-side">
            <div class="row log-wrapper">
                <div class="col-md-8" class="logo-container">
                    <img src="assets/images/login/logo.png" alt="logo.png">
                </div>
                <div class="col-12">
                    <h1 class="font-H1 login-text">{{'LOGIN.LOGIN' | translate}}</h1>

                </div>
            </div>
            <div class="form-container ">
                <form [formGroup]="userform" (ngSubmit)="onSubmit(userform.value)" class="form-group">
                    <div class="p-fluid forms-grid custom-form-grid">
                        <div>
                            <div class="mb-10 font-H3 ">{{'LOGIN.USER' | translate}} *</div>
                            <input pInputText type="text" formControlName="username" placeholder="{{'LOGIN.MAILORPHONENUMBER' | translate}}" />

                            <p-message severity="error" text="{{'LOGIN.REQUIRED' | translate}}" *ngIf="!userform.controls['username']?.valid&&userform.controls['username']?.dirty"></p-message>


                        </div>
                        <div>
                            <div class="mb-10 font-H3 mt-25 input-danger ">{{'LOGIN.PASSWORD' | translate}} *</div>
                            <input pPassword type="password" [type]="hidePassword ? 'password' : 'text'" formControlName="password" placeholder="{{'LOGIN.PASSWORD' | translate}} " />
                            <mat-icon class="password-visibility" matSuffix (click)="hidePassword = !hidePassword">
                                {{ hidePassword ? "visibility_off" : "visibility" }}</mat-icon>
                            <div  class="custom-p-meesage_message">
                              <p-message severity="error" [text]="userform.controls['password'].errors['required'] ? translate.currentLang == 'en-US' ? 'Password is required' : 'حقل كلمة المرور مطلوب' : userform.controls['password'].errors['minlength'] ? translate.currentLang == 'en-US' ? 'Must be longer than 6 characters' : ' من فضلك أدخل كلمة مرور صحيحة تحتوي على الاقل 6 أرقام' : ''" *ngIf="!userform.controls['password'].valid&&userform.controls['password'].dirty"></p-message>
                            </div>




                        </div>
                        <div class="parent mt-10  direction-forgerPass">
                            <p-checkbox class="remember-me child" label="{{'LOGIN.REMEMBERME' | translate}}"></p-checkbox>
                            <a class="forgetPass child color-blue "
                            [routerLink]="['/login/forget-password']">
                            {{'LOGIN.FORGETPASSWORD' | translate}}</a>

                        </div>
                        <div class="col-12 p-0 ">
                            <button type="submit" label="Submit" [disabled]="!userform.valid||isLogIn" class="login-btn">{{'LOGIN.LOGIN' | translate}}</button>
                        </div>

                        <!-- <div class="col-12 p-0 text-center ">
                            <p>
                                {{'LOGIN.DONTHAVEANACCOUNT' | translate}}  <span class="underline" [routerLink]="['/signup']">{{'LOGIN.SIGNUP' | translate}}</span>
                            </p>
                        </div> -->
                        <div *ngIf="errorMessage" class="alert alert-danger">
                            {{errorMessage}}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</div>
