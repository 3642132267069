import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {UserModel} from './interfaces/auth/user-model';
import {PermissionsService} from './services/api_call/permissions.service';

@Injectable({
  providedIn: 'root'
})
export class SuggestionGuard  {
  constructor(private router: Router, private permissionService: PermissionsService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    // var user = JSON.parse(localStorage.getItem("user")) as UserModel;
    // let url = state.url;
    // if (user) {
    //    if (url.startsWith('/service-suggestion/edit-service-suggestion') &&
    //      this.permissionService.canEditSuggestion()) {
    //     return true;
    //   }
    //   this.router.navigateByUrl('/notAuth/not-auth');
    //   return false;
    // }
    return true;
  }
  
}
