import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Employee } from '../../interfaces/employee/Employee';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EmployeeFilter } from '../../interfaces/employee/employee-filter';
import { BaseResponse } from 'src/app/shared/components/ng-models/base-response-model';
import { EmployeeHierarchyView } from '../../interfaces/employee/EmployeeHierarchyView';
import { API_BASE_URL } from 'src/Nswag/RMSBackEnd/SwaggerGenerated';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  baseUrl: string;
  AddEmployeeURL: string;
  UpdateEmployeeURL: string;
  GetEmployeeDetailsURL: string;
  GetAllEmployeesURL: string;
  GetPermissionsURL: string;
  GetAllEmployeesViewURL: string;
  DeleteEmployeeURL: string;
  GetHierarchyEmployeesURL : string;

  GetJobTitleURL: string;
  GetProcessURL: string;
  GetAreaURL: string;

  GetRMSBranchesURL: string;
  GetRMSShiftURL: string;
  GetAllHierarchyURL: string;
  GetAllShiftsURL: string;
  GetProfessionsStandardsByIdUrl: string;
  exportEmployeeGridToPDFURL: string;
  validateEmployeeMobileNoURL: string;
  exportEmployeeGridToExcelURL: string;
  getAreasPerBranchByBranchIdUrl: string;
  GetRMSBranchesHFAndCenters: string;
  Employee: Employee;

  GetServiceBookingBeneficiariesURL: string;
  updateGroupByGroupidURL: string;
  AddNewGroupURL: string;
  DeleteProcessUrl: string;


  constructor(private http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "https://localhost:44352";
    this.AddEmployeeURL = this.baseUrl + '/Employees/add-employees';
    this.UpdateEmployeeURL = this.baseUrl + '/Employees/update-employees';
    this.GetEmployeeDetailsURL = this.baseUrl + '/Employees/get-employee-details/';
    this.GetAllEmployeesURL = this.baseUrl + '/Employees/get-all-employees/';
    this.GetPermissionsURL = this.baseUrl + '/EmployeePermissions/get-permissions';
    this.GetAllEmployeesViewURL = this.baseUrl + '/Employees/get-all-employees-view';
    this.DeleteEmployeeURL = this.baseUrl + '/Employees/delete-employee/';
    this.GetHierarchyEmployeesURL = this.baseUrl + '/Employees/get-hierarchy-employees/';
    this.GetJobTitleURL = this.baseUrl + '/Employees/get-jobtitles/';
    this.GetProcessURL = this.baseUrl + '/Employees/get-process/';
    this.GetAreaURL = this.baseUrl + '/Employees/get-areas/';
    this.GetRMSBranchesURL = this.baseUrl + '/RMSBranches/get-branches/';
    this.GetRMSBranchesHFAndCenters =this.baseUrl + '/RMSBranches/get-hf-and-center-branches/';
    this.GetRMSShiftURL = this.baseUrl + '/RMSBranches/get-shift/';
    this.GetAllHierarchyURL = this.baseUrl + '/RMSBranches/get-hierarchy/';
    this.GetAllShiftsURL = this.baseUrl + '/RMSBranches/get-all-shifts/';
    this.GetProfessionsStandardsByIdUrl = this.baseUrl + '/Employees/get-professions-standards-by-id/';
    this.exportEmployeeGridToPDFURL = this.baseUrl + '/Employees/employee-grid-export-pdf';
    this.validateEmployeeMobileNoURL= this.baseUrl+'/Employees/validate-employee-no';
    this.exportEmployeeGridToExcelURL = this.baseUrl + '/Employees/employee-grid-export-excel';
    this.getAreasPerBranchByBranchIdUrl = this.baseUrl + '/Employees/get-areas-per-branch-by-branch-id/';
    this.GetServiceBookingBeneficiariesURL = this.baseUrl + '/api/Group/get-service-booking-beneficiaries';
    this.updateGroupByGroupidURL = this.baseUrl + '/api/Group/update-group-by-groupid/';
    this.AddNewGroupURL = this.baseUrl + '/api/Group/save-new-group';
    this.DeleteProcessUrl =  this.baseUrl + '/api/Group/delete-group/';

  }

  GetServiceBookingBeneficiaries(model: any): Observable<any> {
    return this.http.post<any>(this.GetServiceBookingBeneficiariesURL, model);
  }
  AddNewGroup(model: any): Observable<any> {
    return this.http.post<any>(this.AddNewGroupURL, model);
  }
  deleteGroup(id){
    return this.http.delete<BaseResponse>(this.DeleteProcessUrl+ `${id}`);
  }
  updateGroupByGroupid(id,model: any): Observable<any> {
    return this.http.put<any>(this.updateGroupByGroupidURL + id, model);
  }

  AddEmployee(model: Employee): Observable<any> {
    return this.http.post<any>(this.AddEmployeeURL, model);
  }
  UpdateEmployee(model: Employee): Observable<any> {
    return this.http.put<any>(this.UpdateEmployeeURL, model);
  }
  GetEmployeeDetails(id): Observable<Employee> {
    return this.http.get<Employee>(this.GetEmployeeDetailsURL + id).pipe(
      map((res: any) => res.data)
    );
  }

  GetAllEmployees(model): Observable<Employee[]> {
    return this.http.get<Employee[]>(this.GetAllEmployeesURL).pipe(
      map((res: any) => res.data)
    );
  }
  GetHierarchyEmployees(): Observable<EmployeeHierarchyView[]> {
    return this.http.get<EmployeeHierarchyView[]>(this.GetHierarchyEmployeesURL).pipe(
      map((res: any) => res.data)
    );
  }
  getAllEmployeesView(model: EmployeeFilter): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(this.GetAllEmployeesViewURL, model)
  }

  GetPermissions() {
    return this.http.get(this.GetPermissionsURL).pipe(
      map((res: any) => res.data)
    );
  }
  DeleteEmployee(id) {
    return this.http.delete(this.DeleteEmployeeURL + id);
  }


  GetJobTitle(): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.GetJobTitleURL)
  }
  GetProcess(): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.GetProcessURL)
  }
  GetAreas(): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.GetAreaURL)
  }

  GetAllRMSBranches(): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.GetRMSBranchesURL)
  }

  GetAllRMSBranchesHFAndCenters(): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.GetRMSBranchesHFAndCenters)
  }
  
  GetAllRMSBranchesShifts(hierarchyId): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.GetRMSShiftURL + hierarchyId)
  }
  GetAllHierarchy(): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.GetAllHierarchyURL)
  }
  GetAllShifts(): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.GetAllShiftsURL)
  }
  GetProfessionsStandardsById(professionId): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.GetProfessionsStandardsByIdUrl + `${professionId}`)
  }
  ExportEmployeeGridPDF(model: EmployeeFilter): Observable<any> {
    return this.http.post<any>(this.exportEmployeeGridToPDFURL, model);
  }

  ValidateEmployeeMoblieNo(model: any): Observable<BaseResponse> {
    return this.http.post<any>(this.validateEmployeeMobileNoURL, model);
  }

  exportEmployeeGridExcel(model: EmployeeFilter): Observable<any> {
    return this.http.post<any>(this.exportEmployeeGridToExcelURL, model);
  }

  getAreasPerBranchByBranchId(id): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(this.getAreasPerBranchByBranchIdUrl + `${id}`);
  }

}
