import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  items: MenuItem[];
  constructor() { }

  ngOnInit() {
    this.items = [
      {
        label: 'لوحة المراقبة العامة',
        routerLink: '/',
        icon: 'fas fa-columns',
        routerLinkActiveOptions:{ exact: true },
      },
      {
        label: 'الموظفون',
        routerLink: 'employees',
        icon:'fas fa-user-friends',
        routerLinkActiveOptions:{ exact: true }
      },
      {
        label: 'الاشتركات',
        routerLink: 'subscriptions',
        icon:'fas fa-user-friends',
        routerLinkActiveOptions:{ exact: true }
      },
      {
        label: 'الاعضاء والنقاط',
        icon:'fas fa-user-friends',
        items: [
          {
            label: 'الخدمات والنقاط المكافئة',
            routerLink: 'points/reward-points',
            routerLinkActiveOptions:{ exact: true }
          },
          {
            label: 'فئات العضويات',
            routerLink: 'points/memberships-categories',
            routerLinkActiveOptions:{ exact: true }
          },
          {
            label: 'الحوافز',
            routerLink: 'points/incentives',
            routerLinkActiveOptions:{ exact: true }
          },
          {
            label: 'سجل النقاط',
            routerLink: 'points/points-history',
            routerLinkActiveOptions:{ exact: true }
          }
        ]
      },
      {
        label: 'التقارير',
        routerLink: 'reports',
        icon:'fas fa-user-friends',
        routerLinkActiveOptions:{ exact: true }
      },
      {
        label: 'التنبيهات',
        routerLink: 'notifications',
        icon:'fas fa-user-friends',
        routerLinkActiveOptions:{ exact: true }
      },
    ];
  }

}
