import { Component, OnInit, EventEmitter, OnDestroy } from '@angular/core';
import { NavBarService } from 'src/app/core/services/nav-bar.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { Message } from 'primeng/api';
import { MessageService, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { UnauthorizedError } from './../../../../core/errors/unauthorized-error';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/api_call/auth.service';
import { AuthenticationModel } from 'src/app/core/interfaces/auth/authentication-model';
import { Mapper } from 'src/app/shared/components/ng-models/mapper';
import { UserModel } from 'src/app/core/interfaces/auth/user-model';
import { TranslateService } from '@ngx-translate/core';
import { DynamicMenuResponseModel, DynamicMenuServiceProxy } from 'src/Nswag/RMSBackEnd/SwaggerGenerated';
import { SubjectService } from 'src/app/core/services/subject_service/Subject.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService, DialogService, DynamicMenuServiceProxy, SubjectService]
})

export class LoginComponent implements OnInit, OnDestroy {
  msgs: Message[] = [];
  hidePassword = true;
  userform: FormGroup;
  submitted: boolean;
  errorMessage: string;
  valueLang = "nameEn";
  isLogIn: boolean = false;
  constructor(
    public nav: NavBarService,
    private fb: FormBuilder,
    public dialogService: DialogService,
    private router: Router,
    private authService: AuthService,
    public translate: TranslateService,
    private dynamicMenuServiceProxy: DynamicMenuServiceProxy,
  ) {
    this.valueLang = this.translate.currentLang == 'en-US' ? 'nameEn' : 'nameAr';
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigateByUrl('/home');
    }
    this.nav.hide();
    this.userform = this.fb.group({
      'username': new FormControl('', Validators.required),
      'password': new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)]))
    });
  }

  ngOnDestroy(): void {
    this.nav.show();
  }

  onSubmit(value: string) {
    this.isLogIn = true;
    if (this.authService.isLoggedIn()) {
      this.router.navigateByUrl('/home');
      this.isLogIn = false;
      return;
    }

    if (this.userform.valid) {
      var authModel: AuthenticationModel;
      authModel = {
        userName: this.userform.value.username,
        password: this.userform.value.password
      }
      this.authService.UserAuthentication(authModel).subscribe(
        (res) => {
          let response = Mapper.responseMapper(res);
          if (response.isSuccess) {

            localStorage.setItem('user', JSON.stringify(response.data as UserModel))
             this.decodeScope();
            this.authService.currentUserEvent.emit(response.data);

            this.authService.decodeToken();
            if (response.data.mustResetPassword === true) {
              this.router.navigateByUrl('/login/reset-password');
            }
            else {
              this.router.navigateByUrl('/home');
            }
            this.isLogIn = false;
          }
          else this.errorMessage = response.message;
          this.isLogIn = false;
        },
        (error: any) => {
          if (!error.isSuccess) {
            this.isLogIn = false;
            this.errorMessage = this.translate.currentLang == 'ar-SA' ? "خطأ فى الاتصال" : "Internet Connection Error"//error.message;
          }
        }
      );
    }
    else {
      this.isLogIn = false;
    }
  }
  get diagnostic() { return JSON.stringify(this.userform.value); }

  decodeScope() {
    this.authService.decompressScope().subscribe(
      (res) => {
        localStorage.setItem('scope', JSON.stringify(res))
      },
      (error: any) => {
       
      }
    );  }


}
