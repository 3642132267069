import { Component, OnChanges, OnInit, DoCheck, ViewEncapsulation, AfterViewInit, SimpleChanges } from '@angular/core';
import { NavBarService } from 'src/app/core/services/nav-bar.service';
import { TranslateService } from '@ngx-translate/core';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { HttpClient } from '@angular/common/http';
import { DynamicMenuResponseModel, DynamicMenuServiceProxy } from '../../../../Nswag/RMSBackEnd/SwaggerGenerated';
import { SubjectService } from 'src/app/core/services/subject_service/Subject.service';
import { UserModel } from 'src/app/core/interfaces/auth/user-model';
export interface PhotosApi {
  albumId?: number;
  id?: number;
  title?: string;
  url?: string;
  thumbnailUrl?: string;
}
@Component({
  selector: 'app-nav-slider',
  templateUrl: './nav-slider.component.html',
  styleUrls: ['./nav-slider.component.scss'],
  providers: [DynamicMenuServiceProxy]
})


export class NavSliderComponent implements OnInit {
  isArabic = false;
  menu: DynamicMenuResponseModel[] = [];
  CallMenu: boolean = false;
  constructor(public translate: TranslateService, private readonly http: HttpClient
    , public nav: NavBarService, private subjectService: SubjectService
    , private dynamicMenuServiceProxy: DynamicMenuServiceProxy
  ) {
  }

  ngOnInit(): void {
    if (localStorage.getItem("menu")) {
      this.menu = JSON.parse(localStorage.getItem("menu")) as DynamicMenuResponseModel[]
    } else if (this.ChecKLoggedInUser()) {
      this.getMenu()
    }
    this.isArabic = this.translate.currentLang == "ar-SA"
  }

  customOptions: OwlOptions = {
    autoWidth: true,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: [`<i class="fas fa-angle-left"></i>`, '<i class="fas fa-angle-right"></i>'],
    responsive: {
      740: {
        items: 3
      },
      940: {
        items: 5
      }
    },
    nav: true
  }

  activeSlides: SlidesOutputData;

  slidesStore: any[];


  getPassedData(data: SlidesOutputData) {
    this.activeSlides = data;
  }


  getMenu() {
    this.dynamicMenuServiceProxy.getMenu().subscribe(res => {
      if (res.isSuccess) {
        localStorage.setItem("menu", JSON.stringify(res.data.reverse()))
        this.menu = JSON.parse(localStorage.getItem("menu")) as DynamicMenuResponseModel[]
      }
    });
  }
  ChecKLoggedInUser(): boolean {
    return localStorage.getItem('user') == null ? false : true;
  }

}
