<div class="mt-4 addresses">
    <form class="form-group" [formGroup]="addressForm">
        <section>
            <div class="row">
                <div class="col-lg-3 input-danger">
                    <div class="form-group">
                        <label class="col-md-12 control-label p-0" for="country">
                            {{'CHILD.COUNTRY'|translate}}
                            <span class="setAsteriskRed" *ngIf="isRequired">*</span>
                        </label>
                        <ng-select [ngClass]="{'setColorRed' : f.country.errors && (f.country.touched || isSubmit)}"
                            id="country" [searchable]="true" formControlName="country"
                            (change)="LoadCitiesByCountryId($event)" placeholder="{{'CHILD.COUNTRY'|translate}}">
                            <ng-option [value]="item.id" *ngFor="let item of countries">
                                {{translate.currentLang == 'en-US'?item.nameEn : item.nameAr}}
                            </ng-option>
                        </ng-select>
                      <div *ngIf="f.country.errors && (f.country.touched || isSubmit) "
                           class="alert alert-danger mt-2 mb-2">
                        {{ 'JOB_INFO.REQUIRED' | translate }}
                      </div>
                    </div>
                </div>
                <div class="col-lg-3 input-danger">
                    <div class="form-group">
                        <label class="col-md-12 control-label p-0" for="city">
                            {{'CHILD.CITY'|translate}}
                            <span class="setAsteriskRed" *ngIf="isRequired">*</span>
                        </label>
                        <!-- (search)="onSearch($event)" -->
                        <ng-select [ngClass]="{'setColorRed' : f.city.errors && (f.city.touched || isSubmit)}" id="city"
                            [searchable]="true" formControlName="city" (change)="LoadDistractByCityId($event)"
                            placeholder="{{'CHILD.CITY'|translate}}">
                            <ng-option [value]="item.id" *ngFor="let item of cities">
                                {{translate.currentLang == 'en-US'?item.nameEn : item.nameAr}}
                            </ng-option>
                        </ng-select>
                      <div *ngIf="f.city.errors && (f.city.touched || isSubmit) "
                           class="alert alert-danger mt-2 mb-2">
                        {{ 'JOB_INFO.REQUIRED' | translate }}

                      </div>
                    </div>
                </div>
                <div class="col-lg-3 input-danger">
                    <div class="form-group">
                        <label class="col-md-12 control-label p-0" for="distract">
                            {{'CHILD.DISTRICT'|translate}}

                        </label>
                        <!-- (search)="onSearchDistrict($event)" -->
                        <ng-select [ngClass]="{'setColorRed' : f.distract.errors && (f.distract.touched || isSubmit)}"
                            id="distract" [searchable]="true" formControlName="distract"
                            placeholder="{{'CHILD.DISTRICT'|translate}}">
                            <ng-option [value]="item.id" *ngFor="let item of distracts">
                                {{translate.currentLang == 'en-US'?item.nameEn : item.nameAr}}
                            </ng-option>
                        </ng-select>
                      <div *ngIf="f.distract.errors && (f.distract.touched || isSubmit) "
                           class="alert alert-danger mt-2 mb-2">
                        {{ 'JOB_INFO.REQUIRED' | translate }}

                      </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="form-group">
                        <label class="col-md-12 control-label p-0" for="details">
                            {{'CHILD.DETAILS'|translate}}
                        </label>
                        <div class="col-md-12 p-0">
                            <input id="details" type="text" name="details" formControlName="details"
                            class="form-control input-md">
                        </div>
                        <!-- <div *ngIf="f.Details.errors && (f.Details.touched || isSubmit)"
                            class="alert alert-danger mt-2 mb-2">
                            <div *ngIf="f.Details.errors.required">
                                {{'THIRDPARTY_ADD.ISREQUIRED' | translate }}
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <div class="input-group mb-3">
                        <label class="col-md-12 control-label p-0" for="district">
                            {{'CHILD.MAP'|translate}}
                        </label>
                        <input type="text" class="form-control" formControlName="latlng">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" (click)="openMap()">
                                <i class="fas fa-map-marker-alt"></i>
                            </button>
                        </div>
                    </div>
                </div>
              <div class="col-md-6" *ngIf="!showSave">
                <button class="btn btn-link mt-30" type="button" (click)="saveAddress()">
                  {{'CHILD.ADDADDRESS'|translate}}
                </button>
              </div>
                <div class="col-lg-6" *ngIf="showSave">
                    <button class="btn btn-link mt-30" type="button" *ngIf="selectedAddressId==null &&
                    (isMultiAddress || (!isMultiAddress && this.addressList.length < 1)) "
                        (click)="AddAddress()">
                        <i class="fa fa-plus"></i>
                        {{'CHILD.ADDADDRESS'|translate}}
                    </button>
                    <button class="btn btn-link mt-30" type="button" *ngIf="selectedAddressId!=null && showEdit"
                        (click)="EditAddress()">
                        <i class="fa fa-edit"></i>
                        {{'CHILD.EDIT_ADDRESS'|translate}}
                    </button>
                    <button class="btn btn-link mt-30" type="button" *ngIf="selectedAddressId!=null"
                        (click)="CancelEdit()">
                        <i class="fa fa-close"></i>
                        {{'CHILD.CANCEL'|translate}}
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="added-box  childaddress p-2 m-2 col-lg-4 col-md-5 col-sm-5 col-xs-12"
                    *ngFor="let item of addressList;let i = index;" [class]="selectedAddressId == i ? 'address-border' : '' ">
                    <button class="btn p-0 d-inline-block btn-link mr-2 float-right" placement="left" type="button"
                        (click)="OnDeleteAddress(i)" *ngIf="selectedAddressId!=i">
                        <i class="fa fa-times"></i>
                    </button>
                    <button class="btn p-0 d-inline-block btn-link mr-1 float-right" placement="right" type="button"
                        (click)="LoadEditAddress(i)" *ngIf="selectedAddressId!=i ">
                        <i class="fa fa-edit"></i>
                    </button>
                    <div class="form-check-inline parentMainMob">
                        <label class="form-check-label" readonly>
                            <p-radioButton id="mainAddress" name="mainAddress" value={{i}} formControlName="mainAddress"
                                (click)="changeMainAddress(i)">
                            </p-radioButton>
                        </label>
                    </div>
                    <h5 class="card-title ">
                        <button class="btn btn-link" type="button" (click)="ShowMap(item)">
                            <i class="fas fa-map-marker-alt"></i>
                            <!-- &nbsp;{{getCountryName(item.countryId)}} -->
                            &nbsp;{{translate.currentLang == 'en-US'?item.countryNameEn : item.countryNameAr}}
                        </button>
                    </h5>
                    <h6 class="card-subtitle mb-2 mb-2 pl-4">
                        {{translate.currentLang == 'en-US'? item.cityNameEn : item.cityNameAr}},
                        {{translate.currentLang == 'en-US'? item.districtNameEn : item.districtNameAr}}
                        <!-- {{getCityName(item.cityId)}},
                        {{getDistrictName(item.districtId)}} -->
                    </h6>
                    <p class="card-text over-width" title="{{item.details}}">
                        {{item.details}}
                        <span *ngIf="!item.details"> - </span>
                    </p>
                </div>
            </div>
        </section>
    </form>
</div>
