import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  @Input() people: string;

  // Defined below in the constructor
  location1: string;
  location2: string;

  constructor() { 
    this.location1 = 'src/app/sample/sample.component.scss';
    this.location2 = 'src/app/sample/sample.component.html';
  }

  ngOnInit(): void {
  }

}
