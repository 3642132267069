import { Inject, Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { UserModel } from '../interfaces/auth/user-model';
import { AuthService } from "../services/api_call/auth.service";
import * as moment from "moment";
import { EMPTY } from 'rxjs';
import { switchMap } from "rxjs/operators";
import { RefreshTokenTime } from "src/app/app.module";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  currentUser: UserModel;
  isRefreshing: boolean = false
  RefreshTokenTime: number
  constructor(private authService: AuthService,
    @Inject(RefreshTokenTime) RefreshTokenTime?: number
  ) {
    this.RefreshTokenTime = RefreshTokenTime
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.currentUser = JSON.parse(localStorage.getItem("user")) as UserModel;
    if(this.currentUser){
        let decoded = this.authService.decodeToken()
        let expirationDate = new Date(decoded.exp * 1000)
        var duration = moment.duration(moment(expirationDate).diff(moment()));
        var hours = duration.asHours();
        if(hours > 0 && hours < this.RefreshTokenTime && !this.isRefreshing){
          this.isRefreshing = true
          this.authService.refreshToken().subscribe(res => {
            if(res.isSuccess){
              this.isRefreshing = false
            }
          })
        } 
        else if(hours <= 0) {
          this.authService.logout()
          return EMPTY
        }
        
    }

    return next.handle(request);
  }
}
