<app-loader></app-loader>
<main [dir]="'HOME.layoutDirection' | translate ">
    <app-navbar></app-navbar>
    
    <app-nav-sidemenu *ngIf="nav.visible && currentWindowWidth < 600"></app-nav-sidemenu>
    <div class="d-flex" [style]="{overflow: 'hidden'}">
        <div class=" main-content  fade-in ">
            <app-nav-slider *ngIf="nav.visible && currentWindowWidth > 600"></app-nav-slider>
            <div class="container">
                <div class="col-12">
                </div>
            </div>

            <router-outlet></router-outlet>
        </div>
    </div>
</main>