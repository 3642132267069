
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserModel } from './interfaces/auth/user-model';
import { PermissionsService } from './services/api_call/permissions.service';

@Injectable({
    providedIn: 'root'
})
export class ChildtransitionGuard  {
    constructor(private router: Router, private permissionService: PermissionsService) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        var user = JSON.parse(localStorage.getItem("user")) as UserModel;
        let url = state.url;
        if (user) {
            if ((url.startsWith('/childtransition/child-transition-list')
                // || (url.startsWith('/childtransition/view-child-transition'))
            )
                && this.permissionService.ChildtransitionList()) {
                return true;
            }
            else if (url.startsWith('/childtransition/view-child-transition')
             && this.permissionService.ChildtransitionView()
            ) {
                return true;
            }
            else if (url.startsWith('/childtransition/add-child-transition') && this.permissionService.CanAddChildtransition()) {
                return true;
            }
            else if (url.startsWith('/childtransition/edit-child-transition')) {
                return true;
            }
            else
            {
                this.router.navigateByUrl('/notAuth/not-auth');
                return false;
            }
        } else {
            this.router.navigateByUrl('/notAuth/not-auth');
            return false;
        }
    }

}
