<nav class=" justify-content-between d-none d-lg-flex" *ngIf="nav.visible">
  <div class="left-side-nav">
    <div class="menu languages-menu ui-rtl " dir="rtl">
      <p-menu #languagesMenu [popup]="true" [model]="languagesItems"></p-menu>
      <button type="button" class="cursor-pointer" style="padding-top: 13px" icon="pi pi-list" label=" "
        (click)="languagesMenu.toggle($event)">
        {{translate.currentLang == 'ar-SA'?'عربى':'English'}}<span class="flag-icon flag-icon-sa "
          style="margin-right: 0;" *ngIf="translate.currentLang == 'ar-SA' "></span>
        <span class="flag-icon flag-icon-us cursor-pointer" style="margin-right: 0;"
          *ngIf="translate.currentLang == 'en-US'"></span>
      </button>
    </div>
  </div>
  <div class="text-center">
    <div class="logo ">
      <img class="animate__animated animate__backInDown" src="assets/images/navBar/logo.png" alt=""
        [routerLink]="['/home']" style="cursor: pointer;">
    </div>
  </div>

  <div class="right-side-nav d-flex justify-content-between align-items-center">

    <div class="menu notifications-menu cursor-pointer" style="margin-top: 5px;">
      <p-menu #notificationsMenu [popup]="true" [model]="notificationsItems"></p-menu>

      <button type="button" pButton icon="pi pi-list" label=" " (click)="notificationsMenu.toggle($event)">
        <i class="far fa-bell"></i>
        <span class="notification-count">
          0
        </span>
        <!-- <app-notification></app-notification> -->
      </button>
    </div>
    <div class="menu user-menu ">
      <p-menu #userMenu [popup]="true" [model]="userItems"></p-menu>
      <button type="button" class="cursor-pointer" pButton icon="pi pi-list" label=" "
        (click)="userMenu.toggle($event)">
        <img src="assets/images/navBar/user.png" />
        <span *ngIf="translate.currentLang === 'en-US'">{{currentUser?.userFirstNameEn}}
          {{currentUser?.userFamilyNameEn}}</span>
        <span *ngIf="translate.currentLang === 'ar-SA'">{{currentUser?.userFirstNameAr}}
          {{currentUser?.userFamilyNameAr}}</span>
        <i class="cursor-pointer" [class]="userMenu.visible ? 'fa fa-chevron-up' : 'fa fa-chevron-down'"></i>
      </button>
    </div>

  </div>

</nav>