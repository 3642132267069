<!-- s -->
<ejs-schedule #scheduleObj width='100%' height='550px' [locale]='translateService.currentLang' [views]='views'
  [selectedDate]="selectedDate" [readonly]="viewMode" [eventSettings]="eventSettings" [firstDayOfWeek]="6"
  [workDays]='[0,1,2,3,4]' [enableRtl]='translateService.currentLang != "en-US"'
  (selectedDateChange)="selectedDateChange($event)" [currentView]='currentView' (created)="sessionsToolTip()"
  (eventRendered)="setSessionsColors($event)" (popupOpen)="createSessions($event)" (actionBegin)="moveSessions($event)">
  <ng-template #eventSettingsTooltipTemplate let-data>
    <div class="tooltip-wrap">
      <div class="content-area">
        <div class="name">{{data.Subject}}</div>
        <hr>
        <div class="name">{{'SESSION_ASSIGNMENT.CHILD' | translate}}:
          {{translateService.currentLang == 'en-US'?data.beneficairyNameEn : data.beneficairyNameAr}}</div>
        <div class="name">{{'SESSION_ASSIGNMENT.DATE' | translate}}:
          {{data.date?.getFullYear()}}-{{data.date?.getMonth()+1}}-{{data.date?.getDate()}}</div>
        <div class="name">{{'SESSION_ASSIGNMENT.FROM' | translate}}:
          {{data.StartTime?.getHours()}}:{{data.StartTime?.getMinutes()}} {{'SESSION_ASSIGNMENT.TO' | translate}}:
          {{data.EndTime?.getHours()}}:{{data.EndTime?.getMinutes()}}</div>
        <div class="name">{{'SESSION_ASSIGNMENT.SESSIONTYPE' | translate}}:
          {{translateService.currentLang == 'en-US'?data.sessionTypeNameEn : data.sessionTypeNameAr}}</div>
        <ol>
          <li *ngFor="let item of data.processResponsibleList">
            {{'SESSION_ASSIGNMENT.PROCESS' | translate}}:
            {{translateService.currentLang == 'en-US'?item.processNameEn : item.processNameAr}} -
            {{'SESSION_ASSIGNMENT.RESPONSIBLE' | translate}}:
            {{translateService.currentLang == 'en-US'?item.responsibleNameEn : item.responsibleNameAr}}
          </li>
        </ol>
        <div class="name">{{'SESSION_ASSIGNMENT.PLACE' | translate}}:
          {{translateService.currentLang == 'en-US'?data.areaNameEn : data.areaNameAr}}</div>
      </div>
    </div>
  </ng-template>
</ejs-schedule>
<ul class="calender-list my-3 d-flex wrap">
  <li *ngIf="!viewMode">
    <div class="custome-card green mr-1"></div>
    <div>{{ 'SESSION_ASSIGNMENT.RESPONSIBILTY' | translate }}</div>
  </li>
  <li>
    <div class="custome-card gray mr-1"></div>
    <div>{{ 'SESSION_ASSIGNMENT.BENEFICIARY' | translate }}</div>
  </li>
  <li>
    <div class="custome-card blue mr-1"></div>
    <div>{{ 'SESSION_ASSIGNMENT.CURRENTASSIGNMENT' | translate }}</div>
  </li>
  <li *ngIf="!viewMode">
    <div class="custome-card org mr-1"></div>
    <div>{{ 'SESSION_ASSIGNMENT.CURRENTSESSIONTYPE' | translate }}</div>
  </li>
</ul>


<p-dialog [header]="dialogHeader" [(visible)]="displayPopup" [modal]="true" [style]="{width: '50vw'}">
  <form class="form-group" [formGroup]="calendarForm">
    <div class="row">
      <div class="col-md-6" *ngIf="assignment.assignmentTypeId == '3d29e0b6-3495-4e99-82f7-a783286305de'">
        <div class="form-group">
          <label class="col-md-12 control-label p-0" for="sessionType">
            {{
            'SESSION_ASSIGNMENT.SESSIONTYPE' |
            translate
            }}*
          </label>
          <input formControlName="sessionType" placeholder="{{ 'SESSION_ASSIGNMENT.SESSIONTYPE' | translate }}"
            disabled>


        </div>
      </div>
      <div class="col-md-3" *ngIf="assignment.assignmentTypeId != 'a9d3c838-eca0-413a-b8ca-8a3aea0ad5cd'">
        <div class="form-group">
          <label class="col-md-12 control-label p-0" for="repeated">
            {{
            'SESSION_ASSIGNMENT.REPEATED' |
            translate
            }}*
          </label>
          <ng-select formControlName="repeated" placeholder="{{ 'SESSION_ASSIGNMENT.REPEATED' | translate }}"
            [searchable]="true" (change)="changeRepetationType()">
            <ng-option [value]="rt.id" *ngFor="let rt of repetitionTypes">
              {{translateService.currentLang == 'en-US'?rt.statusEn : rt.statusAr}}
            </ng-option>
          </ng-select>

        </div>
      </div>
      <div class="col-md-3"></div>
    </div>
    <div class="row mt-2">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6" *ngIf="isOnce && assignment.assignmentTypeId != 'a9d3c838-eca0-413a-b8ca-8a3aea0ad5cd'">
            <div class="form-group">
              <label class="col-md-12 control-label p-0" for="timeFrom">
                {{
                'SESSION_ASSIGNMENT.TIMEFROM' |
                translate
                }}*
              </label>
              <input formControlName="timeFrom" placeholder="{{ 'SESSION_ASSIGNMENT.TIMEFROM' | translate }}"
                type="time">
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="col-md-12 control-label p-0" for="date">
                {{
                'SESSION_ASSIGNMENT.DATE' |
                translate
                }}*
              </label>
              <!-- <input formControlName="date" placeholder="{{ 'SESSION_ASSIGNMENT.DATE' | translate }}" type="date"> -->

              <mat-form-field>
                <input matInput [matDatepicker]="pickerDateFrom" onkeydown="return false" name="date" formControlName="date"
                  class="form-control input-md">
                <mat-datepicker-toggle matSuffix [for]="pickerDateFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerDateFrom>
                </mat-datepicker>
              </mat-form-field>

            </div>
          </div>
          <div class="col-md-6" *ngIf="!isOnce">
            <div class="form-group">
              <label class="col-md-12 control-label p-0" for="date">
                {{
                'SESSION_ASSIGNMENT.DATETO' |
                translate
                }}*
              </label>
              <!-- <input formControlName="dateTo" placeholder="{{ 'SESSION_ASSIGNMENT.DATETO' | translate }}" type="date"> -->

              <mat-form-field>
                <input matInput [matDatepicker]="pickerTo" onkeydown="return false" name="dateTo"
                  formControlName="dateTo" class="form-control input-md">
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo>
                </mat-datepicker>
              </mat-form-field>

            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6"></div>
    </div>

    <div class="row mt-2" *ngIf="!isOnce || assignment.assignmentTypeId == 'a9d3c838-eca0-413a-b8ca-8a3aea0ad5cd'"
      formGroupName="days">
      <div class="col-lg-2 col-md-3">
        <div class="form-group">
          <label class="col-md-12 control-label p-0" for="sunday">
            {{
            'SESSION_ASSIGNMENT.SUNDAY' |
            translate
            }}*
          </label>
          <input formControlName="sunday" placeholder="{{ 'SESSION_ASSIGNMENT.SUNDAY' | translate }}" type="time">
        </div>
      </div>
      <div class="col-lg-2 col-md-3">
        <div class="form-group">
          <label class="col-md-12 control-label p-0" for="monday">
            {{
            'SESSION_ASSIGNMENT.MONDAY' |
            translate
            }}*
          </label>
          <input formControlName="monday" placeholder="{{ 'SESSION_ASSIGNMENT.MONDAY' | translate }}" type="time">
        </div>
      </div>
      <div class="col-lg-2 col-md-3">
        <div class="form-group">
          <label class="col-md-12 control-label p-0" for="tuesday">
            {{
            'SESSION_ASSIGNMENT.TUESDAY' |
            translate
            }}*
          </label>
          <input formControlName="tuesday" placeholder="{{ 'SESSION_ASSIGNMENT.TUESDAY' | translate }}" type="time">
        </div>
      </div>
      <div class="col-lg-2 col-md-3">
        <div class="form-group">
          <label class="col-md-12 control-label p-0" for="wednsday">
            {{
            'SESSION_ASSIGNMENT.WEDNSDAY' |
            translate
            }}*
          </label>
          <input formControlName="wednesday" placeholder="{{ 'SESSION_ASSIGNMENT.WEDNSDAY' | translate }}" type="time">
        </div>
      </div>
      <div class="col-lg-2 col-md-3">
        <div class="form-group">
          <label class="col-md-12 control-label p-0" for="thursday">
            {{
            'SESSION_ASSIGNMENT.THURSDAY' |
            translate
            }}*
          </label>
          <input formControlName="thursday" placeholder="{{ 'SESSION_ASSIGNMENT.THURSDAY' | translate }}" type="time">
        </div>
      </div>
      <div class="col-lg-2 col-md-3"></div>

    </div>
    <hr>
    <section>
      <div class="col-12 direction-rtl ">
        <ul class="list-buttons text-center-s mt-2">
          <li class="ml-2">

            <button class="button" (click)="saveRepetation()">
              {{ 'SESSION_ASSIGNMENT.SAVE' | translate }}
            </button>
          </li>
          <li class="ml-2">

            <button type="button" pbutton="" class="delete-btn__popup" ng-reflect-icon="pi pi-check"
              (click)="deleteRepetation()" ng-reflect-label="Yes" ng-reflect-ng-class="ui-confirmdialog-acceptbutton">
              {{ 'SESSION_ASSIGNMENT.DELETE' | translate }}
            </button>

          </li>
          <li>
            <button class=" cancelBTN" (click)="cancelRepetation()">
              {{ 'SESSION_ASSIGNMENT.CANCEL' | translate }}
            </button>
          </li>

        </ul>
      </div>

    </section>
  </form>
</p-dialog>
<p-confirmDialog key="confirm"></p-confirmDialog>
<p-confirmDialog key="confirmDelete" *ngIf="showDeleteConfirmation">
  <p-footer>
    <div class="d-flex mb-2">
      <button type="button" class="delete-btn__popup "
        (click)="deleteAll()">{{translateService.currentLang == 'en-US' ? 'Yes' : 'نعم'}}</button><br>
      <!-- <button type="button" class="button"
        (click)="deleteOne()">{{translateService.currentLang == 'en-US' ? 'No, delete the day only' : 'لا, حذف اليوم فقط'}}</button><br> -->
      <button type="button" class="cancelBTN"
        (click)="cancelDelete()">{{translateService.currentLang == 'en-US' ? 'Cancel' : 'إلغاء'}}</button>
    </div>

  </p-footer>
</p-confirmDialog>
