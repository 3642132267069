import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class SubjectService {
    private subject = new Subject<any>();
    private dataSource: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    data: Observable<any> = this.dataSource.asObservable();

    sendSubject(data: any) {
        this.data = data
        this.subject.next(data);
    }
    clearSubject() {
        this.subject.next();
    }

    getSubject(): Observable<any> {
        return this.subject.asObservable();
    }

}
