import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Lookups } from '../../interfaces/Lookups/Lookups';
import { LookupModel } from '../../interfaces/Lookups/LookupModel';
import { map } from 'rxjs/operators';
import { LookupByKeysModel } from '../../interfaces/Lookups/lookup-by-keys-model';
import { BaseResponse } from 'src/app/shared/components/ng-models/base-response-model';
import { LookupByKeyAndTerm } from '../../interfaces/Lookups/Lookup-key-search-term';
import { API_BASE_URL } from 'src/Nswag/RMSBackEnd/SwaggerGenerated';
@Injectable({
  providedIn: 'root'
})
export class LookupService {
  baseUrl: string;
  LoadAllLookupsURL: string;
  LoadLookupURL: string;
  LookupListByKeyUrl: string;
  LoadLookupByIdURL: string;
  LoadLookupsByIdsURL: string;
  LookupByKeyAndSearchTermURL: string;
  constructor(private http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : "https://localhost:44352";
    this.LoadAllLookupsURL = this.baseUrl + '/Lookups/get-all-lookups';
    this.LoadLookupURL = this.baseUrl + '/Lookups/get-lookup';
    this.LookupListByKeyUrl = this.baseUrl + '/lookups/get-all-lookups-by-key';
    this.LoadLookupByIdURL = this.baseUrl + '/Lookups/get-lookup-by-id/';
    this.LoadLookupsByIdsURL = this.baseUrl + '/Lookups/get-lookups-by-ids/';
    this.LookupByKeyAndSearchTermURL = this.baseUrl + '/Lookups/get-lookup-by-key-search-term';
  }

  LoadAllLookups(): Observable<Lookups[]> {
    return this.http.get<Lookups[]>(this.LoadAllLookupsURL);
  }
  LoadLookup(model: LookupModel) {
    return this.http.post(this.LoadLookupURL, model).pipe(
      map((res: any) => res.data)
    );
  }

  getLookupListByKey(model: LookupByKeysModel): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(this.LookupListByKeyUrl, model);
  }
  getLookupById(id): Observable<Lookups> {
    return this.http.get<Observable<Lookups>>(this.LoadLookupByIdURL + id).pipe(
      map((res: any) => res.data)
    );
  }
  getLookupByKeyAndSearchTerm(model: LookupModel): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(this.LookupByKeyAndSearchTermURL, model);
  }

  getLookupsByIds(model: any): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(this.LoadLookupsByIdsURL, model);
  }
}
