import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-poilcy',
  templateUrl: './privacy-poilcy.component.html',
  styleUrls: ['./privacy-poilcy.component.scss']
})
export class PrivacyPoilcyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
