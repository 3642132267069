import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddDiagnosisComponent } from './components/add-diagnosis/add-diagnosis.component';
import { DiagnosisViewComponent } from './components/diagnosis-view/diagnosis-view.component';
import { DiagnosisDetailsComponent } from './components/diagnosis-details/diagnosis-details.component';


const routes: Routes = [
  {
    path: '',
    children:[
      { path:'add-diagnosis', component: AddDiagnosisComponent},
      { path:'edit-diagnosis/:id', component: AddDiagnosisComponent},
      { path:'diagnosis-view', component: DiagnosisViewComponent},
      { path:'diagnosis-details/:id', component: DiagnosisDetailsComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DiagnosisRoutingModule { }
